import {
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import Apps from "@mui/icons-material/Apps";
import AppIcon from "../AppIcon/AppIcon";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";

const translationStrings = ["App Switcher"] as const;

type AppSwitcherProps = {
  apps: { name: string; icon: string; url: string }[];
  translations?: TranslationsType<typeof translationStrings>;
};

export default function AppSwitcher({ apps, translations }: AppSwitcherProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  return (
    <Dropdown>
      <MenuButton
        aria-label={t["App Switcher"]}
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: "soft", size: "lg" } }}
      >
        <Apps />
      </MenuButton>
      <Menu
        variant="soft"
        placement="bottom-end"
        disablePortal
        sx={{
          borderRadius: "lg",
          p: 2,
          display: "flex",
          flexDirection: "row",
          gap: (theme) => theme.spacing(2),
          // widths of app icons, padding, border, scrollbar
          maxWidth: 300 + 40 + 2 + (apps.length > 9 ? 15 : 0),
          maxHeight: 3.5 * 124,
          flexFlow: "wrap",
        }}
      >
        {apps.map((app) => (
          <MenuItem
            component="a"
            key={app.name}
            href={app.url}
            target={app.url.startsWith("http") ? "_blank" : "_self"}
            sx={{
              p: 2,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              borderRadius: "md",
              width: 100,
            }}
          >
            <AppIcon name={app.name} url={app.icon} />
            <Typography level="body-sm" lineHeight={1}>
              {app.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
