import "@fontsource/inter";
import type { Theme } from "@mui/joy";
import {
  CssBaseline,
  CssVarsProvider as JoyCssVarsProvider,
  GlobalStyles,
} from "@mui/joy";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import createTheme from "../JoyUI/theme";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";
import materialTheme from "./muiTheme";

export interface ThemeWrapperProps {
  children: React.ReactNode;
  theme?: Theme;
  defaultMode?: "light" | "dark";
}

export default function ThemeWrapper({
  children,
  theme = createTheme(),
  defaultMode = "light",
}: ThemeWrapperProps) {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider theme={theme} defaultMode={defaultMode}>
        <GlobalStyles // https://mui.com/joy-ui/integrations/icon-libraries/#third-party-icons
          styles={{
            ".svg-inline--fa": {
              color: "var(--Icon-color)",
              margin: "var(--Icon-margin)",
              fontSize: "var(--Icon-fontSize, 20px)",
              width: "1em",
              height: "1em",
            },
            body: {
              transition: "background 0.5s",
            },
            "body *": {
              transition: "color 0.2s, background 0.3s",
            },
          }}
        />
        <CssBaseline enableColorScheme />
        {children}
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
