/* eslint-disable @typescript-eslint/no-loss-of-precision */
import type { GeoJSONSourceInput } from "echarts/types/src/coord/geo/geoTypes.js";

const norwayMap: GeoJSONSourceInput = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.6640625, 71.20457467835712],
            [25.565185546874996, 71.18775391813158],
            [25.48004150390625, 71.15761739285172],
            [25.4718017578125, 71.13009942876042],
            [25.38665771484375, 71.15761739285172],
            [25.28778076171875, 71.15939141681443],
            [25.263061523437496, 71.13631654554956],
            [25.20538330078125, 71.07494728543428],
            [25.2191162109375, 71.02231961373207],
            [25.30426025390625, 70.99729447383568],
            [25.2685546875, 70.97134207656326],
            [25.249328613281246, 70.92831137061333],
            [25.202636718749996, 70.90855766673154],
            [25.103759765625, 70.95163128684517],
            [25.01861572265625, 70.98119010476937],
            [24.900512695312496, 70.98119010476937],
            [24.8455810546875, 70.9471488208593],
            [24.78790283203125, 70.99371686045848],
            [24.672546386718746, 71.01606630611498],
            [24.5159912109375, 71.03303495416577],
            [24.53521728515625, 70.9901385984224],
            [24.49951171875, 70.95073487488914],
            [24.400634765625, 70.93728382036649],
            [24.42535400390625, 70.9121507137758],
            [24.54071044921875, 70.88158905218376],
            [24.4830322265625, 70.86448996613296],
            [24.34844970703125, 70.90226826757711],
            [24.21661376953125, 70.90945598953132],
            [24.054565429687496, 70.83746143540387],
            [24.040832519531246, 70.81039616354454],
            [24.1534423828125, 70.78239007072987],
            [24.235839843749996, 70.75253391771874],
            [24.41986083984375, 70.731698698495],
            [24.4610595703125, 70.69722774831193],
            [24.3402099609375, 70.71447063199373],
            [24.235839843749996, 70.68723824939197],
            [24.17816162109375, 70.62719676071552],
            [24.136962890625, 70.66451634715297],
            [24.01336669921875, 70.7180988243322],
            [23.87603759765625, 70.74528934453896],
            [23.68377685546875, 70.760680927893],
            [23.6041259765625, 70.74166607425491],
            [23.5272216796875, 70.69995129442536],
            [23.433837890625, 70.67451714998779],
            [23.302001953125, 70.66633504508805],
            [23.323974609375, 70.68905489170284],
            [23.5382080078125, 70.77786927721345],
            [23.53546142578125, 70.80497869637462],
            [23.4942626953125, 70.83475656229288],
            [23.4063720703125, 70.85188122123132],
            [23.323974609375, 70.89687576648218],
            [23.280029296875, 70.86989126720412],
            [23.214111328125, 70.8356582274894],
            [23.12347412109375, 70.83926448002447],
            [23.11248779296875, 70.86809099659101],
            [23.027343749999996, 70.86629056290495],
            [22.9559326171875, 70.85188122123132],
            [22.980651855468746, 70.83205132172674],
            [22.994384765624996, 70.80497869637462],
            [22.94219970703125, 70.786005968925],
            [22.862548828125, 70.7688246201659],
            [22.81585693359375, 70.7923322155565],
            [22.65380859375, 70.76520561091944],
            [22.576904296875, 70.72081953767822],
            [22.47802734375, 70.72535323927501],
            [22.379150390625, 70.73441756577733],
            [22.26104736328125, 70.70267447090067],
            [22.15667724609375, 70.68905489170284],
            [22.15667724609375, 70.66087845765566],
            [22.04681396484375, 70.66269748467985],
            [21.967163085937496, 70.68632986659676],
            [21.88201904296875, 70.66815357849815],
            [21.9122314453125, 70.6189953899725],
            [21.967163085937496, 70.57793843484905],
            [22.12646484375, 70.57428488353013],
            [22.16217041015625, 70.55692148976206],
            [22.093505859375, 70.53039070013128],
            [22.049560546875, 70.48548372631235],
            [22.09075927734375, 70.46345217128341],
            [22.25006103515625, 70.47355293280864],
            [22.55218505859375, 70.47814252886826],
            [22.72247314453125, 70.49832443828831],
            [22.840576171875, 70.49740751393682],
            [22.82958984375, 70.47538889564962],
            [22.7691650390625, 70.43771848103033],
            [22.708740234375, 70.41287300801244],
            [22.5274658203125, 70.39905687043009],
            [22.335205078125, 70.36401407562315],
            [22.3077392578125, 70.33445768542106],
            [22.2802734375, 70.30115567367248],
            [22.1759033203125, 70.31226235723338],
            [22.01934814453125, 70.33538196905566],
            [21.9012451171875, 70.34185078636595],
            [21.8408203125, 70.34924121782065],
            [21.7694091796875, 70.39076269446318],
            [21.711730957031246, 70.40826866870151],
            [21.6815185546875, 70.39629251964784],
            [21.63482666015625, 70.36032186154814],
            [21.5771484375, 70.35385888225301],
            [21.4892578125, 70.35570565618842],
            [21.500244140625, 70.3916844360438],
            [21.43157958984375, 70.40918961974232],
            [21.37115478515625, 70.3916844360438],
            [21.357421875, 70.34831755984779],
            [21.302490234374996, 70.31041166101691],
            [21.31072998046875, 70.27428967614655],
            [21.20361328125, 70.25388510965949],
            [21.14593505859375, 70.23531792009278],
            [21.16241455078125, 70.19906368261286],
            [21.242065429687496, 70.16088143050877],
            [21.26953125, 70.1263636276192],
            [21.27777099609375, 70.08991761452954],
            [21.27227783203125, 70.06464820325414],
            [21.2091064453125, 70.06464820325414],
            [21.14593505859375, 70.04122316986808],
            [21.082763671875, 70.05153343573795],
            [20.98114013671875, 70.08991761452954],
            [20.926208496093746, 70.12542991464234],
            [20.87127685546875, 70.1720640322536],
            [20.8245849609375, 70.22138152991586],
            [20.75592041015625, 70.24274680637691],
            [20.66802978515625, 70.24553194755725],
            [20.56915283203125, 70.24181834221223],
            [20.478515625, 70.22045210190214],
            [20.39337158203125, 70.1795157389555],
            [20.335693359374996, 70.11982675274214],
            [20.3302001953125, 70.08898225865033],
            [20.38238525390625, 70.04497294874265],
            [20.45379638671875, 70.02527906800769],
            [20.599365234375, 70.01683312770945],
            [20.577392578124996, 69.99053495947653],
            [20.5718994140625, 69.95479149679258],
            [20.58837890625, 69.91992982913247],
            [20.54443359375, 69.90955434487326],
            [20.43731689453125, 69.89822977320739],
            [20.396118164062496, 69.93501227262766],
            [20.32470703125, 69.9830151028733],
            [20.228576660156246, 69.98207493026078],
            [20.11871337890625, 69.95667425709935],
            [20.0555419921875, 69.92181572881897],
            [20.033569335937496, 69.88217615445525],
            [19.984130859375, 69.86043699069164],
            [19.918212890625, 69.82162871560276],
            [19.830322265625, 69.79698146754167],
            [19.775390625, 69.80267185326719],
            [19.75067138671875, 69.84246157021256],
            [19.7698974609375, 69.87083678982484],
            [19.819335937499996, 69.90389282376121],
            [19.8687744140625, 69.94820050018691],
            [19.95941162109375, 69.97925415833394],
            [20.006103515625, 69.99899155978312],
            [19.9456787109375, 70.02058730174062],
            [19.9951171875, 70.042160677947],
            [20.0390625, 70.0712024849082],
            [20.12969970703125, 70.08149789376439],
            [20.14892578125, 70.10207340526765],
            [20.10498046875, 70.12449615957114],
            [20.0555419921875, 70.13289843995393],
            [20.0775146484375, 70.1440961784468],
            [20.06927490234375, 70.17858442263059],
            [20.04180908203125, 70.18603377735818],
            [19.94293212890625, 70.19534169202011],
            [19.88525390625, 70.21673397056851],
            [19.82757568359375, 70.23438912077503],
            [19.791870117187496, 70.26037966890475],
            [19.764404296875, 70.2687268029918],
            [19.698486328124996, 70.282631162412],
            [19.624328613281246, 70.29930397485911],
            [19.52545166015625, 70.28170449789057],
            [19.43756103515625, 70.25574090732549],
            [19.47601318359375, 70.2185931200944],
            [19.550170898437496, 70.19534169202011],
            [19.60784912109375, 70.16460963678996],
            [19.65179443359375, 70.12356236240393],
            [19.6160888671875, 70.11889274507021],
            [19.53094482421875, 70.1440961784468],
            [19.4512939453125, 70.17392721096753],
            [19.36614990234375, 70.16926894908931],
            [19.2864990234375, 70.15715255172064],
            [19.2919921875, 70.1934804449061],
            [19.28924560546875, 70.23996128821607],
            [19.2315673828125, 70.285410905087],
            [19.1876220703125, 70.29652611323709],
            [19.10797119140625, 70.27614363253433],
            [19.04205322265625, 70.24181834221223],
            [19.02557373046875, 70.20278500177974],
            [18.9404296875, 70.21022562625761],
            [18.8470458984375, 70.22045210190214],
            [18.74542236328125, 70.19068825942593],
            [18.6767578125, 70.14689466657805],
            [18.671264648437496, 70.09085292825168],
            [18.5723876953125, 70.07681879517699],
            [18.489990234374996, 70.05809185371957],
            [18.43780517578125, 70.00180966478055],
            [18.43505859375, 69.94066538820442],
            [18.4130859375, 69.88973232889049],
            [18.3636474609375, 69.83678192776182],
            [18.355407714843746, 69.80836070316398],
            [18.25103759765625, 69.7685064889351],
            [18.21807861328125, 69.79223830597596],
            [18.12469482421875, 69.80077522869068],
            [18.04779052734375, 69.7827487816246],
            [18.0120849609375, 69.73428575731293],
            [18.08624267578125, 69.71905875077417],
            [18.072509765625, 69.69524461137115],
            [17.99560546875, 69.666632290865],
            [17.8802490234375, 69.6408481925142],
            [17.7923583984375, 69.61407605991349],
            [17.74017333984375, 69.58535422894994],
            [17.63580322265625, 69.6092917762408],
            [17.55889892578125, 69.59876256792313],
            [17.47100830078125, 69.60737776178779],
            [17.4078369140625, 69.54315879344806],
            [17.26226806640625, 69.55083689511541],
            [17.281494140624996, 69.49895613463414],
            [17.193603515625, 69.50665011403277],
            [17.1881103515625, 69.46429900770522],
            [17.14141845703125, 69.42862077142708],
            [17.02880859375, 69.42282952635378],
            [16.92169189453125, 69.41027647783476],
            [16.8475341796875, 69.37450846437213],
            [16.86126708984375, 69.32510918638907],
            [16.90521240234375, 69.26295785931451],
            [16.85028076171875, 69.23571052428183],
            [16.80084228515625, 69.19184846069052],
            [16.80908203125, 69.1615808781461],
            [16.776123046875, 69.13224961734186],
            [16.72393798828125, 69.08719839843207],
            [16.76239013671875, 69.0361590661957],
            [16.86676025390625, 69.00665920755262],
            [17.00408935546875, 68.99977020590167],
            [17.12493896484375, 68.99780152328965],
            [17.22930908203125, 69.00075448113162],
            [17.3748779296875, 68.96332104096345],
            [17.3199462890625, 68.93865904495844],
            [17.0782470703125, 68.9248362747287],
            [16.97662353515625, 68.88034727252989],
            [16.74041748046875, 68.76425609143143],
            [16.666259765625, 68.7901066219617],
            [16.72943115234375, 68.81394200526994],
            [16.76788330078125, 68.87242873920185],
            [16.7706298828125, 68.91594563602459],
            [16.80084228515625, 68.95050024546568],
            [16.76239013671875, 68.97613438147445],
            [16.64703369140625, 68.97022145813169],
            [16.62506103515625, 68.9928790457939],
            [16.6058349609375, 69.03222804007422],
            [16.57562255859375, 69.06660066336936],
            [16.512451171875, 69.07641152415715],
            [16.4410400390625, 69.04893004097761],
            [16.29547119140625, 69.01846390461324],
            [16.204833984375, 68.99484816896614],
            [16.21856689453125, 68.96332104096345],
            [16.2158203125, 68.91989747286223],
            [16.138916015625, 68.906062950178],
            [15.998840332031248, 68.89221976234808],
            [16.0125732421875, 68.93174874191486],
            [15.95489501953125, 68.95148672526214],
            [15.924682617187498, 68.97514900448668],
            [15.7928466796875, 68.98795546675048],
            [15.89996337890625, 69.00961097622238],
            [15.92193603515625, 69.04598355257725],
            [16.0784912109375, 69.1175691993612],
            [16.1444091796875, 69.17916072174465],
            [16.15814208984375, 69.2279192880373],
            [16.20208740234375, 69.27462482274849],
            [16.16363525390625, 69.3348042281603],
            [16.07574462890625, 69.32898772490465],
            [15.998840332031248, 69.31152882092375],
            [15.9027099609375, 69.28919970268278],
            [15.803833007812498, 69.2619853290091],
            [15.790100097656248, 69.2064789782693],
            [15.77362060546875, 69.16939589231097],
            [15.699462890625, 69.15962668690726],
            [15.6280517578125, 69.14300899012328],
            [15.53192138671875, 69.09993967425089],
            [15.449523925781252, 69.03517637565149],
            [15.391845703125, 68.98303078594931],
            [15.328674316406252, 68.96726440112525],
            [15.29022216796875, 68.98598572665271],
            [15.227050781249998, 69.00764317447866],
            [15.18310546875, 69.03026226305208],
            [15.097961425781248, 69.0361590661957],
            [15.032043457031248, 69.02534705050161],
            [14.96337890625, 68.99484816896614],
            [14.897460937499998, 68.96430694716823],
            [14.7930908203125, 68.9189095799366],
            [14.757385253906248, 68.87539852115728],
            [14.67498779296875, 68.8536108664365],
            [14.56512451171875, 68.84865613717805],
            [14.52392578125, 68.84370030023011],
            [14.50469970703125, 68.80798555614652],
            [14.441528320312498, 68.79606786485644],
            [14.400329589843748, 68.75032413064737],
            [14.37835693359375, 68.72542394494182],
            [14.348144531249998, 68.69850049292796],
            [14.334411621093748, 68.64755642255037],
            [14.27398681640625, 68.60751904033492],
            [14.27398681640625, 68.5814563824722],
            [14.37286376953125, 68.5814563824722],
            [14.490966796875, 68.5814563824722],
            [14.556884765625002, 68.56640647198128],
            [14.589843749999998, 68.53728140042286],
            [14.650268554687498, 68.50308674440305],
            [14.7271728515625, 68.47891809948206],
            [14.7052001953125, 68.45774930547955],
            [14.58709716796875, 68.43958884179992],
            [14.367370605468748, 68.40120219496715],
            [14.359130859375, 68.36983858230192],
            [14.2822265625, 68.34350018861753],
            [14.1668701171875, 68.34958098793473],
            [14.0185546875, 68.35971204172223],
            [13.90594482421875, 68.35768619207559],
            [13.71917724609375, 68.33538992721655],
            [13.62030029296875, 68.30291996237335],
            [13.4197998046875, 68.23784103977596],
            [13.3978271484375, 68.20829104467289],
            [13.41156005859375, 68.18686894773276],
            [13.30169677734375, 68.17972379832914],
            [13.265991210937498, 68.12248241161676],
            [13.13690185546875, 68.13476043212292],
            [13.03802490234375, 68.11736463499349],
            [13.0462646484375, 68.08150831161802],
            [12.8924560546875, 67.97875365614591],
            [12.7496337890625, 67.84345233813025],
            [12.7880859375, 67.81443073247817],
            [12.8814697265625, 67.82168951608071],
            [12.94189453125, 67.85484381663362],
            [13.0682373046875, 67.88381521322093],
            [13.20281982421875, 67.94680686576572],
            [13.3154296875, 67.99625418825913],
            [13.4527587890625, 68.02093832144247],
            [13.7109375, 68.063046172348],
            [13.88397216796875, 68.10814976816812],
            [13.933410644531248, 68.14805422620769],
            [14.0625, 68.16746979117633],
            [14.14764404296875, 68.18176549672332],
            [14.144897460937498, 68.14191958434716],
            [14.20257568359375, 68.13578330466238],
            [14.27398681640625, 68.14907650731061],
            [14.441528320312498, 68.18584834837847],
            [14.6392822265625, 68.21236917437108],
            [14.595336914062498, 68.18482770360208],
            [14.61181640625, 68.14805422620769],
            [14.6612548828125, 68.13885164925573],
            [14.757385253906248, 68.14703189961982],
            [14.869995117187498, 68.1980925432565],
            [14.96337890625, 68.23580433361244],
            [15.026550292968752, 68.20523197086519],
            [15.133666992187498, 68.24191390808997],
            [15.2490234375, 68.27548740848277],
            [15.306701660156248, 68.32930533395773],
            [15.4248046875, 68.31713126731856],
            [15.441284179687498, 68.27447075435512],
            [15.584106445312502, 68.26836987876567],
            [15.70770263671875, 68.27040368508267],
            [15.704956054687502, 68.30495069140954],
            [15.737915039062498, 68.33944541924481],
            [15.82855224609375, 68.3607248988466],
            [15.943908691406248, 68.35363395114311],
            [16.0015869140625, 68.33741776356939],
            [16.01531982421875, 68.3140867336998],
            [16.116943359375, 68.31104179320587],
            [16.08123779296875, 68.28463525856004],
            [15.98785400390625, 68.28971581339952],
            [15.924682617187498, 68.26328457046196],
            [15.89447021484375, 68.22765569526493],
            [15.787353515625, 68.19503210757499],
            [15.6884765625, 68.19911259767794],
            [15.5181884765625, 68.18482770360208],
            [15.3863525390625, 68.15418723061075],
            [15.279235839843748, 68.09688214240542],
            [15.152893066406248, 68.05072986744273],
            [15.0238037109375, 68.01579796502013],
            [14.95513916015625, 67.98184294617724],
            [14.74639892578125, 67.92514047803861],
            [14.62554931640625, 67.8786443911337],
            [14.548645019531248, 67.85173760149999],
            [14.52392578125, 67.80716969310878],
            [14.52392578125, 67.76459541801283],
            [14.52667236328125, 67.72090236155769],
            [14.5843505859375, 67.70215183099825],
            [14.6942138671875, 67.69277095059344],
            [14.617309570312502, 67.68130034407031],
            [14.666748046875, 67.66043035544442],
            [14.77935791015625, 67.6489439704077],
            [14.88922119140625, 67.64058671662393],
            [15.004577636718748, 67.62177205521934],
            [14.99359130859375, 67.60084926188547],
            [14.908447265625, 67.58409766881769],
            [14.77386474609375, 67.54741212580376],
            [14.6942138671875, 67.51487167706847],
            [14.622802734374998, 67.48333834982645],
            [14.600830078125, 67.45808150845772],
            [14.56512451171875, 67.42963546436422],
            [14.52667236328125, 67.4022108263811],
            [14.474487304687498, 67.37898070264325],
            [14.4635009765625, 67.36101465712885],
            [14.427795410156248, 67.33562781287714],
            [14.35638427734375, 67.32610079536312],
            [14.27398681640625, 67.30915450824497],
            [14.23828125, 67.28265191683958],
            [14.2547607421875, 67.25187218507608],
            [14.31243896484375, 67.24124933109691],
            [14.4195556640625, 67.25187218507608],
            [14.44427490234375, 67.23912419673614],
            [14.348144531249998, 67.22424299461082],
            [14.265747070312498, 67.21041648785257],
            [14.2108154296875, 67.19871092976476],
            [14.14764404296875, 67.18912942176102],
            [14.13116455078125, 67.16356009771135],
            [14.095458984375, 67.17421727789996],
            [14.0185546875, 67.1795441025332],
            [13.9251708984375, 67.16249412070799],
            [13.8372802734375, 67.1347621832915],
            [13.71917724609375, 67.10913518884959],
            [13.699951171875, 67.08241150454835],
            [13.62579345703125, 67.07492358508108],
            [13.568115234375, 67.06422254212268],
            [13.55987548828125, 67.04173496919447],
            [13.584594726562498, 67.0213711007154],
            [13.63677978515625, 67.02351547007088],
            [13.76861572265625, 67.03209105492864],
            [13.842773437499998, 67.04816211731604],
            [13.86749267578125, 67.03959220819824],
            [13.853759765625, 67.0074280854991],
            [13.80157470703125, 66.99991699428902],
            [13.699951171875, 66.9859616682753],
            [13.66424560546875, 66.96340153393594],
            [13.612060546875, 66.96447630005638],
            [13.45550537109375, 66.96555101876504],
            [13.39508056640625, 66.94942525912128],
            [13.32916259765625, 66.91283401424863],
            [13.3758544921875, 66.89128411011376],
            [13.403320312499998, 66.86755725696996],
            [13.33740234375, 66.86216158029742],
            [13.22479248046875, 66.86431999367815],
            [13.1781005859375, 66.84164716011712],
            [13.095703125, 66.83300436862307],
            [13.04901123046875, 66.8016486944319],
            [13.010559082031248, 66.7507457196216],
            [12.98858642578125, 66.73230803802201],
            [12.92266845703125, 66.7149423120737],
            [12.873229980468748, 66.68343573437495],
            [12.843017578124998, 66.64644572965148],
            [12.7496337890625, 66.66059550359473],
            [12.64251708984375, 66.6508003685595],
            [12.54638671875, 66.63882326673667],
            [12.54913330078125, 66.60940033421895],
            [12.513427734375, 66.57666716759219],
            [12.510681152343748, 66.53733030908982],
            [12.58209228515625, 66.52529828653252],
            [12.648010253906248, 66.4990263931796],
            [12.678222656249998, 66.45956654643426],
            [12.7056884765625, 66.4266356170104],
            [12.71942138671875, 66.39696064516934],
            [12.6727294921875, 66.38926138676293],
            [12.59307861328125, 66.40245866621093],
            [12.48321533203125, 66.4310289161498],
            [12.403564453125, 66.42443867788279],
            [12.28546142578125, 66.40575689923939],
            [12.20306396484375, 66.38486074754684],
            [12.24700927734375, 66.33970959528405],
            [12.3046875, 66.32868478255796],
            [12.41180419921875, 66.29999760762087],
            [12.47222900390625, 66.27127767491216],
            [12.56011962890625, 66.27790826076543],
            [12.645263671875, 66.29005980104056],
            [12.70294189453125, 66.2944770886277],
            [12.70294189453125, 66.27238289380156],
            [12.63702392578125, 66.2513754392446],
            [12.54913330078125, 66.23809854666706],
            [12.48870849609375, 66.21595485349513],
            [12.36785888671875, 66.20487571945456],
            [12.2607421875, 66.18713899424186],
            [12.1893310546875, 66.14163179102249],
            [12.139892578125, 66.11161893005014],
            [12.12066650390625, 66.07377439885927],
            [12.12066650390625, 66.0492566991642],
            [12.11517333984375, 65.9923292550444],
            [12.12890625, 65.97332516774209],
            [12.117919921874998, 65.95094930095703],
            [12.01629638671875, 65.92967407413116],
            [11.906433105468748, 65.90613875658492],
            [11.74713134765625, 65.87809230699395],
            [11.76361083984375, 65.84551997116758],
            [11.8377685546875, 65.84776766596988],
            [11.936645507812498, 65.84439605002875],
            [11.953125, 65.82078234733756],
            [11.9476318359375, 65.78363125977107],
            [11.9146728515625, 65.7509390575002],
            [11.82403564453125, 65.7340130302582],
            [11.7333984375, 65.70803822968016],
            [11.65924072265625, 65.70125790052711],
            [11.65374755859375, 65.67751274714273],
            [11.70318603515625, 65.63902200315025],
            [11.6619873046875, 65.61295172126665],
            [11.64276123046875, 65.5766364488888],
            [11.66748046875, 65.55618680626866],
            [11.7333984375, 65.55732331902202],
            [11.766357421875, 65.58458474995126],
            [11.84326171875, 65.5743650596436],
            [11.906433105468748, 65.55164025925087],
            [11.95037841796875, 65.55732331902202],
            [11.98883056640625, 65.5925306227801],
            [12.027282714843748, 65.61862140120708],
            [12.087707519531248, 65.62088892664137],
            [12.095947265625, 65.59707003153882],
            [12.1234130859375, 65.58344942705203],
            [12.1728515625, 65.59707003153882],
            [12.20306396484375, 65.6322235840378],
            [12.2003173828125, 65.6582745198266],
            [12.17010498046875, 65.68656108857486],
            [12.13714599609375, 65.71933482896539],
            [12.139892578125, 65.75770636196388],
            [12.216796875, 65.74868289493749],
            [12.30743408203125, 65.71255746172102],
            [12.2662353515625, 65.68316833109415],
            [12.26348876953125, 65.65034874398613],
            [12.26348876953125, 65.61635367775574],
            [12.22503662109375, 65.58004316100237],
            [12.2003173828125, 65.55391363196408],
            [12.1014404296875, 65.55959619573943],
            [12.057495117187498, 65.54254478403703],
            [12.06024169921875, 65.5152393010566],
            [12.04376220703125, 65.49815884999234],
            [11.9476318359375, 65.44685039711592],
            [11.9696044921875, 65.41715959149825],
            [11.942138671875, 65.39315397725503],
            [11.88720703125, 65.36912638169456],
            [11.8927001953125, 65.34622250665987],
            [11.95587158203125, 65.32444533221509],
            [12.0355224609375, 65.32215194937498],
            [12.01904296875, 65.29805936383784],
            [11.95037841796875, 65.2636031407123],
            [11.906433105468748, 65.20953116273365],
            [11.88995361328125, 65.1668859850877],
            [11.86798095703125, 65.14611484756372],
            [11.810302734375, 65.1253274409527],
            [11.74713134765625, 65.15188623948963],
            [11.66748046875, 65.14265140986313],
            [11.57958984375, 65.1253274409527],
            [11.49169921875, 65.1010548909078],
            [11.41204833984375, 65.06286750655632],
            [11.40380859375, 65.03158251302388],
            [11.480712890625, 65.02926364540207],
            [11.546630859375, 65.02346559455444],
            [11.5081787109375, 65.0025821781929],
            [11.4312744140625, 64.9793592199603],
            [11.3433837890625, 64.96425347425706],
            [11.2445068359375, 64.9549533904596],
            [11.20880126953125, 64.98632822613389],
            [11.18408203125, 65.03042310440534],
            [11.104431152343748, 65.03506043658815],
            [10.98907470703125, 65.04085596832707],
            [10.923156738281248, 65.01766628383098],
            [10.77484130859375, 65.00954513181861],
            [10.6292724609375, 64.97471220666368],
            [10.56884765625, 64.94216049820734],
            [10.53863525390625, 64.90141516539295],
            [10.54412841796875, 64.85710722553537],
            [10.5194091796875, 64.82908559319857],
            [10.52215576171875, 64.77295472761107],
            [10.5413818359375, 64.74718900442583],
            [10.59906005859375, 64.73429692356085],
            [10.6622314453125, 64.75421845752885],
            [10.69244384765625, 64.77646633128046],
            [10.77484130859375, 64.77061340468352],
            [10.72540283203125, 64.74015772248073],
            [10.74737548828125, 64.7213986934753],
            [10.86822509765625, 64.73429692356085],
            [10.91217041015625, 64.75421845752885],
            [11.01104736328125, 64.77880714659877],
            [11.07147216796875, 64.77646633128046],
            [11.05224609375, 64.74367359208166],
            [11.07147216796875, 64.72843485414084],
            [11.151123046874998, 64.71084102073965],
            [11.173095703125, 64.68853907177144],
            [11.12640380859375, 64.67561902154044],
            [11.0357666015625, 64.65799084418659],
            [11.00830078125, 64.62976192217607],
            [10.953369140624998, 64.6168138555028],
            [10.83526611328125, 64.62270010508516],
            [10.777587890625, 64.60857096440976],
            [10.7501220703125, 64.57557440261894],
            [10.68695068359375, 64.57085734168489],
            [10.61553955078125, 64.54017652813491],
            [10.64300537109375, 64.51064316846676],
            [10.6292724609375, 64.48107784308382],
            [10.52215576171875, 64.45977100063784],
            [10.44525146484375, 64.43252143990158],
            [10.40130615234375, 64.38862828481005],
            [10.2557373046875, 64.33871847566155],
            [10.1788330078125, 64.28633477129675],
            [10.18157958984375, 64.2362391950664],
            [10.08819580078125, 64.22668687645498],
            [10.030517578125, 64.21115732127653],
            [9.97833251953125, 64.18246447089267],
            [9.964599609375, 64.1381714022217],
            [9.91790771484375, 64.12738671814198],
            [9.8602294921875, 64.10460529935635],
            [9.819030761718748, 64.05538175552562],
            [9.81353759765625, 64.0205125351989],
            [9.83001708984375, 63.979575849424926],
            [9.8272705078125, 63.95546742180186],
            [9.728393554687498, 63.95184936610536],
            [9.69268798828125, 63.91685071892172],
            [9.654235839843748, 63.89631394389387],
            [9.64874267578125, 63.858825820232695],
            [9.5745849609375, 63.81765226901684],
            [9.5361328125, 63.77399105556201],
            [9.53887939453125, 63.74727590824046],
            [9.5196533203125, 63.721751503619956],
            [9.52789306640625, 63.698638133654356],
            [9.5196533203125, 63.67915959984029],
            [9.48944091796875, 63.65357369429142],
            [9.4757080078125, 63.68159514899444],
            [9.415283203125, 63.69742111760759],
            [9.34661865234375, 63.68524808030715],
            [9.29718017578125, 63.65601144183318],
            [9.29718017578125, 63.629184696973816],
            [9.29718017578125, 63.598668975965815],
            [9.228515625, 63.591340327146554],
            [9.17633056640625, 63.6133206106172],
            [9.20928955078125, 63.66088630862599],
            [9.17633056640625, 63.68524808030715],
            [9.04998779296875, 63.69376975565478],
            [9.003295898437498, 63.67672384138043],
            [8.94561767578125, 63.67063352942902],
            [8.852233886718748, 63.68646561943989],
            [8.89068603515625, 63.705939131709904],
            [8.95111083984375, 63.741200758886265],
            [8.92364501953125, 63.75577892366916],
            [8.9373779296875, 63.77156344995569],
            [8.9263916015625, 63.809167882566385],
            [8.852233886718748, 63.8309797153825],
            [8.791809082031248, 63.85761569299516],
            [8.71490478515625, 63.880599207351985],
            [8.640747070312498, 63.88785325538203],
            [8.4869384765625, 63.883017431520884],
            [8.39080810546875, 63.846722204207325],
            [8.4375, 63.81765226901684],
            [8.51715087890625, 63.780059156214016],
            [8.50341796875, 63.73147780336167],
            [8.459472656249998, 63.715670867659625],
            [8.338623046874998, 63.70350567486577],
            [8.2342529296875, 63.68524808030715],
            [8.228759765625, 63.662104894440205],
            [8.316650390625, 63.64625919492172],
            [8.41827392578125, 63.63162453961018],
            [8.338623046874998, 63.61942323045113],
            [8.2672119140625, 63.563229678512144],
            [8.308410644531248, 63.54610525592423],
            [8.2672119140625, 63.53141898781894],
            [8.2232666015625, 63.497121606067786],
            [8.18206787109375, 63.4750515237761],
            [8.1298828125, 63.49099273689984],
            [8.052978515625, 63.5007982966045],
            [8.00079345703125, 63.53386722440812],
            [7.929382324218749, 63.52162394005688],
            [7.9376220703125, 63.49589593739938],
            [7.915649414062499, 63.47750459671641],
            [7.877197265625, 63.48976680530999],
            [7.789306640625, 63.45787412014771],
            [7.745361328125, 63.40750950529057],
            [7.750854492187499, 63.34473718389291],
            [7.720642089843749, 63.31391630233039],
            [7.77008056640625, 63.24475766626653],
            [7.877197265625, 63.24352118311121],
            [7.959594726562499, 63.25217545380908],
            [7.965087890625, 63.22744208504663],
            [7.7838134765625, 63.175433053918894],
            [7.781066894531249, 63.15063398346516],
            [7.69317626953125, 63.13698544979437],
            [7.569580078124999, 63.12581369122982],
            [7.44049072265625, 63.074865690586634],
            [7.3333740234375, 63.03753005973634],
            [7.23175048828125, 63.02632003456279],
            [7.02850341796875, 62.98268449920913],
            [6.9049072265625, 62.958969308086814],
            [6.792297363281249, 62.902724956979554],
            [6.80328369140625, 62.862663206802495],
            [6.74560546875, 62.84637249167229],
            [6.6961669921875, 62.828818539544706],
            [6.63848876953125, 62.817528320221385],
            [6.602783203124999, 62.83634294535021],
            [6.520385742187499, 62.83258098312488],
            [6.46270751953125, 62.803723277703774],
            [6.3775634765625, 62.771067428841675],
            [6.33636474609375, 62.7484383230469],
            [6.361083984374999, 62.7396334260932],
            [6.391296386718749, 62.73208428086645],
            [6.31988525390625, 62.719498082521035],
            [6.26495361328125, 62.70690652067901],
            [6.210021972656249, 62.70312800599381],
            [6.1578369140625, 62.69178956429159],
            [6.13861083984375, 62.64765428581885],
            [6.1029052734375, 62.63755698311195],
            [6.1358642578125, 62.59966145692096],
            [6.053466796875, 62.58575430245709],
            [6.009521484375, 62.576900905018725],
            [5.9820556640625, 62.54652637334784],
            [6.01226806640625, 62.52499230967743],
            [6.00677490234375, 62.50217455994255],
            [5.943603515625, 62.499638176134994],
            [5.92437744140625, 62.47933933915963],
            [5.9161376953125, 62.45648663758143],
            [6.0150146484375, 62.45267615377909],
            [6.05072021484375, 62.41581641980274],
            [6.0459136962890625, 62.39005121403518],
            [6.001968383789062, 62.42598915962207],
            [5.9710693359375, 62.42916743172063],
            [5.9140777587890625, 62.424717756243474],
            [5.8701324462890625, 62.42058532214496],
            [5.840606689453125, 62.40913867988976],
            [5.8234405517578125, 62.39482422082581],
            [5.79254150390625, 62.39132409018914],
            [5.7767486572265625, 62.38304942863568],
            [5.7534027099609375, 62.37445409249241],
            [5.712890625, 62.37636438015543],
            [5.6806182861328125, 62.37763783765335],
            [5.6490325927734375, 62.38050291927199],
            [5.6586456298828125, 62.388141798421756],
            [5.6854248046875, 62.39896021172853],
            [5.6970977783203125, 62.405322159715254],
            [5.686798095703125, 62.41136475869601],
            [5.6682586669921875, 62.41200075081284],
            [5.640106201171875, 62.40945670128109],
            [5.626373291015625, 62.41168275644325],
            [5.607147216796875, 62.414544584178266],
            [5.58929443359375, 62.41994951240088],
            [5.576934814453125, 62.41677026105953],
            [5.579681396484375, 62.40404987822529],
            [5.60028076171875, 62.39355149335676],
            [5.59478759765625, 62.38686878708873],
            [5.607147216796875, 62.380184590390975],
            [5.612640380859374, 62.37572763112343],
            [5.5913543701171875, 62.369359397043496],
            [5.570068359375, 62.36585629183654],
            [5.563201904296875, 62.37763783765335],
            [5.538482666015625, 62.37540925153661],
            [5.511016845703125, 62.373498903022146],
            [5.4842376708984375, 62.366811724740806],
            [5.5089569091796875, 62.35661887136379],
            [5.4993438720703125, 62.348653294935055],
            [5.473251342773437, 62.33877304385952],
            [5.467071533203125, 62.32952728462555],
            [5.478057861328125, 62.312941554000915],
            [5.5144500732421875, 62.31772683874475],
            [5.5474090576171875, 62.31262250793334],
            [5.5460357666015625, 62.296027446274095],
            [5.52886962890625, 62.283575139850704],
            [5.506896972656249, 62.27335401397472],
            [5.464324951171875, 62.27686791737303],
            [5.40252685546875, 62.27175664960215],
            [5.384674072265625, 62.26185109927039],
            [5.392913818359375, 62.24330938428133],
            [5.3647613525390625, 62.242030224844],
            [5.3400421142578125, 62.243629165662185],
            [5.3228759765625, 62.230835264898744],
            [5.3263092041015625, 62.21739582737158],
            [5.3359222412109375, 62.20298978722933],
            [5.3448486328125, 62.18857687291788],
            [5.3565216064453125, 62.17800303213532],
            [5.3798675537109375, 62.16999008415273],
            [5.412826538085937, 62.15973041231724],
            [5.4107666015625, 62.15203337492483],
            [5.394287109375, 62.14337186777946],
            [5.3771209716796875, 62.14305102362891],
            [5.373687744140625, 62.13599159219542],
            [5.381927490234375, 62.12411520072902],
            [5.362701416015625, 62.12026239824921],
            [5.3414154052734375, 62.12250992588321],
            [5.328369140625, 62.126683463629625],
            [5.325622558593749, 62.13920062865334],
            [5.340728759765624, 62.15139186677874],
            [5.344161987304687, 62.1616543658171],
            [5.3304290771484375, 62.17287499001282],
            [5.2933502197265625, 62.17672110312914],
            [5.274810791015625, 62.18056672710887],
            [5.244598388671875, 62.18825650783807],
            [5.234985351562499, 62.19978751152973],
            [5.22674560546875, 62.21323478839036],
            [5.2123260498046875, 62.214195079060495],
            [5.1985931396484375, 62.218676031677106],
            [5.18280029296875, 62.220916258567655],
            [5.1656341552734375, 62.21611556877038],
            [5.16632080078125, 62.20779256428973],
            [5.1573944091796875, 62.20298978722933],
            [5.1416015625, 62.20875302805395],
            [5.118255615234375, 62.20491098970417],
            [5.085296630859375, 62.196264616146884],
            [5.0811767578125, 62.18377104014499],
            [5.096282958984375, 62.1731955181226],
            [5.06195068359375, 62.17287499001282],
            [5.0777435302734375, 62.16518129619094],
            [5.090789794921875, 62.1491464812029],
            [5.1216888427734375, 62.1443343798343],
            [5.12786865234375, 62.13599159219542],
            [5.102462768554687, 62.131819336484384],
            [5.101776123046875, 62.11094943673246],
            [5.1010894775390625, 62.09970584517769],
            [5.1422882080078125, 62.09874191480283],
            [5.214385986328125, 62.09520724138518],
            [5.240478515625, 62.08363633985347],
            [5.274810791015625, 62.06755832375939],
            [5.2700042724609375, 62.04986267224888],
            [5.2672576904296875, 62.03537674629954],
            [5.241851806640625, 62.02539353815561],
            [5.2095794677734375, 62.02539353815561],
            [5.144348144531249, 62.03795252636908],
            [5.11688232421875, 62.0405280883274],
            [5.087356567382812, 62.02668187811478],
            [5.0612640380859375, 62.022816694603705],
            [5.03448486328125, 62.03537674629954],
            [4.9843597412109375, 62.04310343218768],
            [4.9761199951171875, 62.036342689386366],
            [4.978179931640624, 62.021850322020576],
            [4.9733734130859375, 62.00316108688356],
            [4.9871063232421875, 61.99284487353088],
            [5.0049591064453125, 61.98252516668541],
            [5.0351715087890625, 61.981557515028555],
            [5.03173828125, 61.96832986399625],
            [5.0104522705078125, 61.95961583829658],
            [4.9980926513671875, 61.94347218079782],
            [4.9623870849609375, 61.94056541570731],
            [4.9383544921875, 61.93378188781818],
            [4.93011474609375, 61.91730133291111],
            [4.909515380859374, 61.90533928813463],
            [4.87518310546875, 61.88754922071448],
            [4.84771728515625, 61.878488485297375],
            [4.818878173828124, 61.86489235346611],
            [4.8140716552734375, 61.8516141167923],
            [4.829864501953125, 61.84221871914159],
            [4.873809814453125, 61.83314456864678],
            [4.8834228515625, 61.82147385875747],
            [4.8497772216796875, 61.81596114716962],
            [4.8223114013671875, 61.80979870966504],
            [4.79278564453125, 61.798768102761905],
            [4.78729248046875, 61.783838034396815],
            [4.8044586181640625, 61.7714990281991],
            [4.7845458984375, 61.76207908673352],
            [4.79827880859375, 61.74225531091333],
            [4.81201171875, 61.72437046803857],
            [4.8346710205078125, 61.7289239586037],
            [4.871063232421875, 61.74778125291682],
            [4.920501708984375, 61.75655571098004],
            [4.954833984374999, 61.76305369719729],
            [4.976806640625, 61.76240396031812],
            [4.98504638671875, 61.75590583689141],
            [4.9857330322265625, 61.747456224955386],
            [4.9713134765625, 61.740954945142896],
            [4.9383544921875, 61.738679172923014],
            [4.9390411376953125, 61.72957440233169],
            [4.950714111328125, 61.72209347043361],
            [4.961700439453125, 61.713959962789225],
            [4.964447021484375, 61.698988700965984],
            [4.9596405029296875, 61.68791831421042],
            [4.9541473388671875, 61.681404466679794],
            [4.9349212646484375, 61.68042727099892],
            [4.9170684814453125, 61.688895272796415],
            [4.912261962890625, 61.70419692066125],
            [4.906082153320312, 61.72111756281976],
            [4.86968994140625, 61.72209347043361],
            [4.849090576171874, 61.714936097015396],
            [4.8291778564453125, 61.706475240285314],
            [4.818878173828124, 61.69247718972528],
            [4.8175048828125, 61.67749549838289],
            [4.7989654541015625, 61.67195694613261],
            [4.7838592529296875, 61.66348429774533],
            [4.76531982421875, 61.6527272049295],
            [4.769439697265625, 61.639683283988596],
            [4.7769927978515625, 61.627612757292546],
            [4.76806640625, 61.6174959869049],
            [4.7783660888671875, 61.606069851870544],
            [4.78729248046875, 61.59855895286674],
            [4.766693115234375, 61.59071955121135],
            [4.7357940673828125, 61.58679910659675],
            [4.7193145751953125, 61.58059072172005],
            [4.7186279296875, 61.568170221552506],
            [4.7165679931640625, 61.55901509065302],
            [4.7275543212890625, 61.548548775669474],
            [4.7577667236328125, 61.54560448866883],
            [4.8085784912109375, 61.554109444927185],
            [4.88616943359375, 61.562939048969376],
            [4.9431610107421875, 61.569150968216704],
            [4.991912841796875, 61.5668625110988],
            [5.0145721435546875, 61.56195810590954],
            [5.0372314453125, 61.554436512099656],
            [5.0928497314453125, 61.546585948692055],
            [5.1354217529296875, 61.537751691563805],
            [5.1505279541015625, 61.528587584977615],
            [5.16357421875, 61.51483635572128],
            [5.1917266845703125, 61.50763090253583],
            [5.217132568359375, 61.49681959172747],
            [5.20751953125, 61.466987110233354],
            [5.147781372070312, 61.46206692340771],
            [5.06744384765625, 61.455505465518485],
            [5.022125244140625, 61.444676039249785],
            [4.96856689453125, 61.430559323055725],
            [4.936294555664062, 61.433514513299485],
            [4.8923492431640625, 61.427275450010534],
            [4.8635101318359375, 61.41183661649244],
            [4.866943359375, 61.39540394088529],
            [4.8779296875, 61.38422478245402],
            [4.860076904296875, 61.37304162460724],
            [4.842224121093749, 61.36514522233485],
            [4.8175048828125, 61.35395523963718],
            [4.8195648193359375, 61.34144405225167],
            [4.8195648193359375, 61.326621705145],
            [4.8065185546875, 61.32266789459885],
            [4.7907257080078125, 61.32793953113799],
            [4.7824859619140625, 61.337162761276936],
            [4.7495269775390625, 61.33782145952238],
            [4.709014892578125, 61.33255148565135],
            [4.6767425537109375, 61.31838403686128],
            [4.6437835693359375, 61.30849597641666],
            [4.6067047119140625, 61.30882562867853],
            [4.5641326904296875, 61.303880480853486],
            [4.55657958984375, 61.29233876975402],
            [4.5696258544921875, 61.28277242053215],
            [4.58953857421875, 61.27551324481645],
            [4.6108245849609375, 61.272213064607016],
            [4.6286773681640625, 61.27254309823526],
            [4.648590087890625, 61.27452322717013],
            [4.66644287109375, 61.28145269524238],
            [4.698028564453124, 61.27551324481645],
            [4.714508056640625, 61.266601962243485],
            [4.741973876953125, 61.267262143963144],
            [4.766693115234375, 61.272213064607016],
            [4.7934722900390625, 61.26495144723964],
            [4.81475830078125, 61.24943236671816],
            [4.8511505126953125, 61.24513850184193],
            [4.872436523437499, 61.25306517893708],
            [4.8854827880859375, 61.26693205483771],
            [4.89166259765625, 61.28277242053215],
            [4.9047088623046875, 61.28904035802311],
            [4.9225616455078125, 61.280132914469895],
            [4.9294281005859375, 61.26792231180772],
            [4.9198150634765625, 61.25141395270571],
            [4.936294555664062, 61.24282617782521],
            [4.950714111328125, 61.23423605678437],
            [4.94659423828125, 61.222999280767674],
            [4.934234619140625, 61.213411800014676],
            [4.9053955078125, 61.221346475192874],
            [4.8648834228515625, 61.22531306274158],
            [4.8394775390625, 61.22861817044345],
            [4.813385009765625, 61.23027059410228],
            [4.7893524169921875, 61.22762667458739],
            [4.752960205078125, 61.239192182201045],
            [4.7275543212890625, 61.23423605678437],
            [4.709701538085937, 61.22663514748493],
            [4.713134765624999, 61.21605691399013],
            [4.724807739257812, 61.19985210206241],
            [4.7412872314453125, 61.190588464792924],
            [4.779052734375, 61.18562468142281],
            [4.810638427734375, 61.182977010592346],
            [4.8394775390625, 61.18032911734518],
            [4.8374176025390625, 61.16807971754858],
            [4.8175048828125, 61.16410591723532],
            [4.7934722900390625, 61.16013161627575],
            [4.76806640625, 61.15450049948817],
            [4.7426605224609375, 61.14522352804582],
            [4.720001220703125, 61.138264009981235],
            [4.6918487548828125, 61.13925832076686],
            [4.6753692626953125, 61.13395496811726],
            [4.68017578125, 61.1243403713046],
            [4.6657562255859375, 61.11737625022179],
            [4.651336669921875, 61.107424843333824],
            [4.670562744140625, 61.09348761017874],
            [4.647216796875, 61.07356662133512],
            [4.626617431640625, 61.06094350886341],
            [4.625244140625, 61.02969629338804],
            [4.643096923828125, 60.99508900307938],
            [4.656829833984375, 60.97976989482837],
            [4.714508056640625, 60.96910876750488],
            [4.755706787109375, 60.947775785813185],
            [4.75433349609375, 60.930432202923335],
            [4.739227294921875, 60.91107628964259],
            [4.7076416015625, 60.896384648582455],
            [4.7076416015625, 60.8790130710757],
            [4.733734130859375, 60.87166070999515],
            [4.777679443359375, 60.86296937260006],
            [4.79278564453125, 60.84825556578078],
            [4.772186279296875, 60.834204246024875],
            [4.74609375, 60.822155341181976],
            [4.76806640625, 60.798713918926374],
            [4.7900390625, 60.783970521936816],
            [4.810638427734375, 60.765867076865256],
            [4.81201171875, 60.74708233233932],
            [4.838104248046875, 60.71686951248446],
            [4.871063232421875, 60.6832663608162],
            [4.88616943359375, 60.66376043720968],
            [4.91363525390625, 60.6388564031651],
            [4.90814208984375, 60.62336581233979],
            [4.915008544921875, 60.599104726405244],
            [4.93011474609375, 60.58224601637319],
            [4.912261962890625, 60.575500068060016],
            [4.884796142578125, 60.57954780605175],
            [4.879302978515624, 60.59775635343141],
            [4.8779296875, 60.609215728912524],
            [4.871063232421875, 60.62606036274505],
            [4.844970703125, 60.64760866293291],
            [4.82025146484375, 60.675196377796745],
            [4.796905517578125, 60.69536754125233],
            [4.758453369140624, 60.720227896386945],
            [4.730987548828125, 60.72895805173665],
            [4.69390869140625, 60.72828658556427],
            [4.687042236328125, 60.71619779357714],
            [4.70489501953125, 60.70477642344907],
            [4.687042236328125, 60.69469537287745],
            [4.703521728515625, 60.68461116124036],
            [4.70489501953125, 60.67183328755394],
            [4.730987548828125, 60.65366652740694],
            [4.713134765624999, 60.646935496566684],
            [4.711761474609375, 60.6301017662667],
            [4.750213623046875, 60.62538674624876],
            [4.744720458984375, 60.61056362329555],
            [4.74609375, 60.588316165776824],
            [4.76806640625, 60.576174726269265],
            [4.788665771484374, 60.5620039458159],
            [4.783172607421875, 60.54309990668631],
            [4.8065185546875, 60.53296819772097],
            [4.82574462890625, 60.517426749866274],
            [4.803771972656249, 60.50593479822827],
            [4.82025146484375, 60.48632147192188],
            [4.873809814453125, 60.479555504525436],
            [4.902648925781249, 60.46534237892132],
            [4.864196777343749, 60.457217797743944],
            [4.8504638671875, 60.43621988470891],
            [4.88067626953125, 60.41249624776229],
            [4.9053955078125, 60.3812902796077],
            [4.901275634765625, 60.359564131824236],
            [4.893035888671875, 60.34733680729186],
            [4.872436523437499, 60.33578456834218],
            [4.884796142578125, 60.3167484328433],
            [4.915008544921875, 60.29770119508587],
            [4.921875, 60.28000452943944],
            [4.904022216796875, 60.265022945174344],
            [4.925994873046875, 60.246627077643865],
            [4.95758056640625, 60.228220871779506],
            [4.989166259765625, 60.202298319594654],
            [4.967193603515625, 60.18591562140308],
            [4.972686767578125, 60.16542574699484],
            [5.000152587890625, 60.150391714056326],
            [4.991912841796875, 60.12919572132151],
            [5.02899169921875, 60.1141451165239],
            [5.046844482421875, 60.101825866222],
            [5.07293701171875, 60.08950200748712],
            [5.068817138671874, 60.080598576147175],
            [5.044097900390625, 60.07785857502251],
            [5.01251220703125, 60.07511834621021],
            [4.99603271484375, 60.05113162986575],
            [4.986419677734375, 60.01957970414989],
            [4.997406005859375, 59.99967278003097],
            [5.02899169921875, 59.985249938641964],
            [5.060577392578125, 59.97906679629742],
            [5.0921630859375, 59.972195283534425],
            [5.121002197265625, 59.96669704655032],
            [5.164947509765625, 59.95913548025017],
            [5.18280029296875, 59.94607052743776],
            [5.145721435546875, 59.93437646673723],
            [5.093536376953125, 59.92130175321992],
            [5.041351318359375, 59.90891042881661],
            [5.022125244140625, 59.882046686648515],
            [5.053710937499999, 59.866883195210214],
            [5.03448486328125, 59.85033331585688],
            [5.033111572265625, 59.828254000642204],
            [5.044097900390625, 59.797871028010384],
            [5.063323974609375, 59.772991625706695],
            [5.086669921875, 59.75570334633104],
            [5.101776123046875, 59.73010026399137],
            [5.0811767578125, 59.71763761778773],
            [5.0811767578125, 59.69824204817713],
            [5.079803466796875, 59.685074366793664],
            [5.110015869140625, 59.676755268826966],
            [5.107269287109375, 59.66427274866522],
            [5.086669921875, 59.66011087558618],
            [5.07843017578125, 59.64762215668353],
            [5.09490966796875, 59.63929376060572],
            [5.096282958984375, 59.632351851463916],
            [5.07843017578125, 59.620547310812206],
            [5.086669921875, 59.602485279538506],
            [5.09765625, 59.59067023940794],
            [5.118255615234375, 59.579546408235004],
            [5.13336181640625, 59.56007084997047],
            [5.140228271484375, 59.53849547627571],
            [5.108642578125, 59.530140008063675],
            [5.071563720703125, 59.52317553544798],
            [5.086669921875, 59.512029386502704],
            [5.12786865234375, 59.512726128735956],
            [5.178680419921875, 59.50366735715104],
            [5.20751953125, 59.49181760086988],
            [5.218505859375, 59.45903485454137],
            [5.20751953125, 59.43250639105923],
            [5.200653076171875, 59.405957115062684],
            [5.175933837890625, 59.405957115062684],
            [5.1580810546875, 59.427617299091025],
            [5.12786865234375, 59.44507509904714],
            [5.082550048828125, 59.45973269104296],
            [5.05096435546875, 59.45135770220262],
            [5.05096435546875, 59.438791328713094],
            [5.07843017578125, 59.41993301322722],
            [5.09765625, 59.40316124329745],
            [5.12786865234375, 59.36889308443833],
            [5.162200927734375, 59.354896124925084],
            [5.17181396484375, 59.334590277457245],
            [5.159454345703124, 59.30095391119714],
            [5.144348144531249, 59.28552611855346],
            [5.140228271484375, 59.25394747882771],
            [5.152587890625, 59.23358128290588],
            [5.152587890625, 59.178742850970224],
            [5.151214599609375, 59.152698941837905],
            [5.175933837890625, 59.13156769674783],
            [5.247344970703125, 59.12381630502752],
            [5.296783447265625, 59.132272281744],
            [5.316009521484375, 59.14495233311817],
            [5.33111572265625, 59.16044379726647],
            [5.368194580078125, 59.15903577213258],
            [5.40252685546875, 59.147769484619786],
            [5.453338623046875, 59.151994777135464],
            [5.478057861328125, 59.16959454813507],
            [5.511016845703125, 59.197032114227305],
            [5.522003173828124, 59.21179706646391],
            [5.55633544921875, 59.22936606822124],
            [5.598907470703125, 59.25113907157854],
            [5.5810546875, 59.265880628258095],
            [5.593414306640625, 59.271494782025684],
            [5.62225341796875, 59.25464954448365],
            [5.65521240234375, 59.25324539870941],
            [5.686798095703125, 59.26237131223996],
            [5.7073974609375, 59.272196486185756],
            [5.723876953125, 59.29113703426701],
            [5.733489990234374, 59.31006704674587],
            [5.788421630859375, 59.319878462265024],
            [5.83648681640625, 59.3240824879461],
            [5.887298583984375, 59.332488979498926],
            [5.95458984375, 59.32618430580267],
            [6.016387939453125, 59.317075489595744],
            [6.010894775390625, 59.300252799629696],
            [5.955963134765625, 59.29674702504426],
            [5.931243896484375, 59.28833169203345],
            [5.8941650390625, 59.282720313814714],
            [5.847473144531249, 59.28622753360349],
            [5.8282470703125, 59.2785111730695],
            [5.8062744140625, 59.29113703426701],
            [5.7843017578125, 59.287630320343276],
            [5.787048339843749, 59.27289817588848],
            [5.767822265625, 59.258861634721114],
            [5.763702392578124, 59.2377959767454],
            [5.78155517578125, 59.219528541548286],
            [5.791168212890625, 59.201251323437596],
            [5.785675048828125, 59.194922314172906],
            [5.77056884765625, 59.189295543626685],
            [5.756835937499999, 59.180853650173155],
            [5.745849609375, 59.17381714591928],
            [5.7293701171875, 59.17803922227036],
            [5.706024169921875, 59.180853650173155],
            [5.686798095703125, 59.175224562637936],
            [5.69915771484375, 59.166075318301345],
            [5.71014404296875, 59.16185176444876],
            [5.696411132812499, 59.15903577213258],
            [5.662078857421875, 59.15621954800389],
            [5.667572021484375, 59.145656642731254],
            [5.718383789062499, 59.148473736266325],
            [5.756835937499999, 59.150586404259755],
            [5.75958251953125, 59.1407261710983],
            [5.749969482421875, 59.12804455432675],
            [5.7293701171875, 59.13438594975983],
            [5.701904296875, 59.13015848326803],
            [5.6854248046875, 59.12874921180362],
            [5.653839111328125, 59.13790843988889],
            [5.62225341796875, 59.14988219608611],
            [5.593414306640625, 59.14636093785244],
            [5.56182861328125, 59.1336814082498],
            [5.545349121093749, 59.11676806301211],
            [5.552215576171874, 59.093498575720986],
            [5.567321777343749, 59.07938816835443],
            [5.607147216796875, 59.07233078827691],
            [5.634613037109375, 59.06174199712982],
            [5.620880126953124, 59.05750556628013],
            [5.609893798828125, 59.05044368690802],
            [5.5913543701171875, 59.04161429610876],
            [5.5680084228515625, 59.03631557264101],
            [5.56182861328125, 59.02642243660573],
            [5.557708740234375, 59.017233404146616],
            [5.560455322265624, 59.00556686590824],
            [5.554962158203125, 58.99000532803044],
            [5.55633544921875, 58.97832955834134],
            [5.579681396484375, 58.976914044608634],
            [5.5817413330078125, 58.97089746248487],
            [5.57281494140625, 58.963463763677034],
            [5.567321777343749, 58.953903795211986],
            [5.5570220947265625, 58.9429242663719],
            [5.557708740234375, 58.928751968181885],
            [5.5529022216796875, 58.922372535237926],
            [5.5535888671875, 58.909964707855764],
            [5.5693817138671875, 58.896488305221176],
            [5.587921142578125, 58.88797418509798],
            [5.594100952148437, 58.88123235292319],
            [5.563201904296875, 58.8755540011406],
            [5.548782348632812, 58.866679710325414],
            [5.5364227294921875, 58.85567242859108],
            [5.5515289306640625, 58.84004317964765],
            [5.5391693115234375, 58.830449011918326],
            [5.5323028564453125, 58.82085218755374],
            [5.5405426025390625, 58.80449592237136],
            [5.5364227294921875, 58.790978406215565],
            [5.5226898193359375, 58.77923523837185],
            [5.4952239990234375, 58.76855618425579],
            [5.486297607421875, 58.757873846636755],
            [5.486297607421875, 58.75039425627743],
            [5.502777099609375, 58.74006265219864],
            [5.5096435546875, 58.72545066339352],
            [5.5144500732421875, 58.70655289957706],
            [5.5391693115234375, 58.68907225470483],
            [5.532989501953125, 58.67658070916536],
            [5.541229248046875, 58.65908502437738],
            [5.5776214599609375, 58.63622029871717],
            [5.6435394287109375, 58.56610386751617],
            [5.656585693359375, 58.54998585819344],
            [5.6792449951171875, 58.5360109172837],
            [5.706024169921875, 58.5324267015612],
            [5.7273101806640625, 58.53170981445073],
            [5.747222900390625, 58.53027599626238],
            [5.7630157470703125, 58.52454013724891],
            [5.7698822021484375, 58.51485874792572],
            [5.7781219482421875, 58.50696823304234],
            [5.78155517578125, 58.49477031125367],
            [5.79254150390625, 58.48220919993359],
            [5.8220672607421875, 58.472157076484],
            [5.848846435546875, 58.46174291716436],
            [5.857086181640625, 58.452044202355104],
            [5.850906372070312, 58.44018658510352],
            [5.859146118164062, 58.42688692835143],
            [5.8769989013671875, 58.417897829019935],
            [5.898284912109375, 58.4200554221125],
            [5.911331176757812, 58.42652740842975],
            [5.9278106689453125, 58.42293200731591],
            [5.928497314453125, 58.40998552353421],
            [5.9387969970703125, 58.404230003313565],
            [5.961456298828125, 58.404230003313565],
            [5.9964752197265625, 58.400632325791555],
            [6.0088348388671875, 58.39379572671634],
            [6.027374267578125, 58.38119852056259],
            [6.035614013671875, 58.37039733344612],
            [6.0610198974609375, 58.36859681409852],
            [6.085052490234375, 58.36139381804461],
            [6.1351776123046875, 58.34446099069667],
            [6.188049316406249, 58.329322657409996],
            [6.2340545654296875, 58.31886621327047],
            [6.282806396484375, 58.312374450913],
            [6.2841796875, 58.30515998391905],
            [6.2896728515625, 58.29541811935378],
            [6.300659179687499, 58.28964389694031],
            [6.321258544921875, 58.28964389694031],
            [6.3329315185546875, 58.28170280301917],
            [6.38031005859375, 58.26762103157288],
            [6.4386749267578125, 58.25967499830377],
            [6.4730072021484375, 58.250643252119936],
            [6.486053466796875, 58.240163543416415],
            [6.4949798583984375, 58.227511494332184],
            [6.5093994140625, 58.21449325046911],
            [6.52587890625, 58.207982337966406],
            [6.547164916992187, 58.2011084128722],
            [6.5856170654296875, 58.197490023201475],
            [6.6130828857421875, 58.187356571348914],
            [6.6117095947265625, 58.17468569246626],
            [6.607589721679687, 58.1612858522803],
            [6.598663330078125, 58.14715623101221],
            [6.5897369384765625, 58.13302099917688],
            [6.554031372070312, 58.12359439362968],
            [6.54510498046875, 58.115253393904226],
            [6.554718017578125, 58.10654766042196],
            [6.5705108642578125, 58.096388284059095],
            [6.590423583984375, 58.08114379217578],
            [6.6062164306640625, 58.07424533447061],
            [6.6323089599609375, 58.06480317906766],
            [6.6515350341796875, 58.059717907259895],
            [6.674880981445312, 58.055358526547664],
            [6.6913604736328125, 58.05862811195618],
            [6.71539306640625, 58.05790156328201],
            [6.750411987304687, 58.05572182859322],
            [6.78955078125, 58.04990855250491],
            [6.8218231201171875, 58.048091709749386],
            [6.8520355224609375, 58.04663816903482],
            [6.87744140625, 58.039733043234364],
            [6.9069671630859375, 58.03718871323224],
            [6.9412994384765625, 58.03609823065334],
            [6.9577789306640625, 58.02773675913304],
            [6.9646453857421875, 58.01282667756441],
            [6.969451904296875, 57.999365903024575],
            [6.985931396484375, 57.99026791914394],
            [7.00653076171875, 57.989539980574364],
            [7.0195770263671875, 57.99681870053304],
            [7.024383544921875, 57.98590006580031],
            [7.05322265625, 57.973885722525715],
            [7.0841217041015625, 57.97752685803579],
            [7.102661132812499, 57.98808405905049],
            [7.120513916015625, 58.006278824953284],
            [7.1335601806640625, 58.015372741174964],
            [7.1500396728515625, 58.01464531291485],
            [7.1685791015625, 58.006642625971644],
            [7.174072265624999, 57.996090895108686],
            [7.188491821289062, 57.98626407392255],
            [7.200164794921874, 57.975706336525484],
            [7.2214508056640625, 57.97461397922455],
            [7.222824096679687, 57.98844804497961],
            [7.215957641601562, 58.01064419313469],
            [7.234497070312499, 58.016827553330415],
            [7.263336181640625, 58.013917869866205],
            [7.285308837890626, 58.01064419313469],
            [7.297668457031249, 57.999729774302246],
            [7.3079681396484375, 57.99135979925842],
            [7.329940795898437, 57.98153167980975],
            [7.367706298828125, 57.982259781132555],
            [7.404098510742187, 57.9760704482266],
            [7.437057495117187, 57.980439500081395],
            [7.459716796875001, 57.98808405905049],
            [7.478942871093749, 57.98590006580031],
            [7.4727630615234375, 57.97534222112477],
            [7.4823760986328125, 57.962231602587586],
            [7.4981689453125, 57.954945853453246],
            [7.524261474609375, 57.958224623746375],
            [7.543487548828126, 57.95130242376526],
            [7.578506469726562, 57.95786033073906],
            [7.5839996337890625, 57.968787511258206],
            [7.6128387451171875, 57.96296009608866],
            [7.6403045654296875, 57.97024421704643],
            [7.6499176025390625, 57.98080356369012],
            [7.667083740234374, 57.99026791914394],
            [7.669830322265624, 58.005551211823665],
            [7.683563232421874, 58.00991666874597],
            [7.706222534179688, 58.01137170273387],
            [7.7199554443359375, 58.00118522243253],
            [7.749481201171875, 58.006278824953284],
            [7.75909423828125, 58.014281593239104],
            [7.7845001220703125, 58.00991666874597],
            [7.8119659423828125, 58.00809779306888],
            [7.81951904296875, 58.02082798176068],
            [7.823638916015625, 58.035007714812494],
            [7.8559112548828125, 58.04445774710926],
            [7.886810302734375, 58.04736494678224],
            [7.930755615234375, 58.05208864188002],
            [7.9465484619140625, 58.05899138075174],
            [7.977447509765625, 58.05245197717741],
            [7.998046875, 58.05172530288789],
            [8.039932250976562, 58.06008116497254],
            [8.060531616210938, 58.06952456888353],
            [8.06396484375, 58.07860241023776],
            [8.043365478515623, 58.08767794341272],
            [8.03924560546875, 58.098928399489154],
            [8.024826049804686, 58.10509649813648],
            [8.016586303710938, 58.117429495093674],
            [8.023452758789062, 58.12613257127609],
            [8.035812377929688, 58.11924281125913],
            [8.059158325195312, 58.10037981280052],
            [8.070831298828125, 58.10509649813648],
            [8.077011108398438, 58.09312215554109],
            [8.087997436523438, 58.07533465327125],
            [8.112716674804688, 58.07896547587941],
            [8.14361572265625, 58.08767794341272],
            [8.160781860351562, 58.10255682201683],
            [8.181381225585938, 58.115253393904226],
            [8.196487426757812, 58.104370894845395],
            [8.213653564453125, 58.10400808766267],
            [8.2342529296875, 58.11307715984733],
            [8.249359130859375, 58.11235171896803],
            [8.26171875, 58.12214392521852],
            [8.285064697265623, 58.1326584835481],
            [8.285064697265623, 58.14353234751419],
            [8.31390380859375, 58.15802566835695],
            [8.32489013671875, 58.172513088329225],
            [8.331069946289062, 58.17903050255115],
            [8.36402893066406, 58.181564729932184],
            [8.400421142578125, 58.21376987470013],
            [8.4375, 58.230042265110995],
            [8.46771240234375, 58.24052496423803],
            [8.52264404296875, 58.240163543416415],
            [8.53912353515625, 58.251727183165066],
            [8.537063598632812, 58.26075865321366],
            [8.540840148925781, 58.27375992791262],
            [8.553886413574219, 58.28531261195842],
            [8.564872741699219, 58.29198978839884],
            [8.578605651855469, 58.29343333664242],
            [8.59954833984375, 58.29451595919151],
            [8.606758117675781, 58.300108981521014],
            [8.614654541015625, 58.307324478511134],
            [8.625640869140625, 58.31832527858799],
            [8.622207641601562, 58.323553967232975],
            [8.62701416015625, 58.33004367749288],
            [8.635597229003906, 58.33220664949367],
            [8.645210266113281, 58.33094493191324],
            [8.65345001220703, 58.32986342385105],
            [8.664093017578125, 58.33130542724666],
            [8.678169250488281, 58.33220664949367],
            [8.690872192382812, 58.33436948912657],
            [8.696022033691406, 58.33779371451355],
            [8.69842529296875, 58.34265914823506],
            [8.701171874999998, 58.34644291125727],
            [8.695335388183592, 58.34842472061986],
            [8.686408996582031, 58.349685814137686],
            [8.678855895996094, 58.34878503764829],
            [8.674736022949219, 58.345181701948455],
            [8.669929504394531, 58.34572222573767],
            [8.65997314453125, 58.34464116988815],
            [8.656883239746094, 58.34734374747947],
            [8.661346435546875, 58.35166744154822],
            [8.671646118164062, 58.35328869036406],
            [8.684349060058594, 58.35743154363891],
            [8.67919921875, 58.360853534083475],
            [8.680572509765625, 58.36499549981006],
            [8.690185546875, 58.36355487120244],
            [8.706321716308594, 58.36895692531702],
            [8.715934753417969, 58.37435815266593],
            [8.730010986328125, 58.38083853426453],
            [8.730010986328125, 58.385158127380905],
            [8.734130859375, 58.38911728970975],
            [8.739280700683594, 58.39217633799949],
            [8.752326965332031, 58.396134712445914],
            [8.763656616210938, 58.39991274621832],
            [8.765029907226562, 58.40872658381117],
            [8.77429962158203, 58.414121723074544],
            [8.783226013183594, 58.418437239683655],
            [8.790092468261719, 58.42113416909718],
            [8.800392150878906, 58.4191564410524],
            [8.814811706542969, 58.420954380227535],
            [8.817901611328125, 58.423651116906086],
            [8.820304870605469, 58.42832497133073],
            [8.827857971191404, 58.42958321076002],
            [8.838157653808594, 58.43263874789026],
            [8.854293823242188, 58.43856932766738],
            [8.869400024414062, 58.44324120194486],
            [8.876266479492188, 58.44773280389084],
            [8.887939453125, 58.451864571301456],
            [8.89514923095703, 58.45707349945128],
            [8.900985717773436, 58.463897823924334],
            [8.907852172851562, 58.467489041724754],
            [8.923301696777344, 58.47341374916254],
            [8.931541442871094, 58.48292709873034],
            [8.9263916015625, 58.48687527991821],
            [8.931884765625, 58.491720168608104],
            [8.944587707519531, 58.50051103764058],
            [8.951454162597656, 58.50732693129619],
            [8.952827453613281, 58.51521736554333],
            [8.96484375, 58.51808617454552],
            [8.973770141601562, 58.52543642705419],
            [8.980979919433594, 58.53117213950007],
            [8.994712829589844, 58.532785139620565],
            [9.000892639160156, 58.5401323124464],
            [9.008102416992188, 58.54694051256996],
            [9.017372131347656, 58.55213535459746],
            [9.033508300781248, 58.55929939036268],
            [9.048271179199219, 58.56502956427132],
            [9.052047729492186, 58.57004269746878],
            [9.046554565429688, 58.57344405771315],
            [9.051017761230467, 58.575771113812145],
            [9.059944152832031, 58.57523411459542],
            [9.07196044921875, 58.57845598633676],
            [9.079170227050781, 58.5847198880941],
            [9.086723327636719, 58.58973020236073],
            [9.099082946777344, 58.596707658895944],
            [9.098052978515625, 58.60135852346759],
            [9.0911865234375, 58.606545296673254],
            [9.112815856933594, 58.60779716115019],
            [9.123458862304688, 58.61065839735873],
            [9.150238037109375, 58.62728469708959],
            [9.153671264648438, 58.63300374546053],
            [9.172210693359375, 58.64068724237873],
            [9.174613952636719, 58.64622545900063],
            [9.16259765625, 58.647833163799405],
            [9.172897338867186, 58.650512507264025],
            [9.183883666992188, 58.65426324261114],
            [9.185256958007812, 58.65944216689345],
            [9.193840026855469, 58.66158494522403],
            [9.20654296875, 58.66194206215169],
            [9.21375274658203, 58.667655435877364],
            [9.2230224609375, 58.67890090608363],
            [9.235038757324219, 58.683897728912605],
            [9.233665466308594, 58.689429090236324],
            [9.229202270507812, 58.69442440406271],
            [9.222679138183594, 58.70084589828487],
            [9.226799011230469, 58.703521171568426],
            [9.236068725585938, 58.701380969378974],
            [9.247055053710938, 58.70548290806038],
            [9.258041381835938, 58.710654229207336],
            [9.267997741699219, 58.71885543950382],
            [9.273147583007812, 58.725272430530254],
            [9.291343688964844, 58.72188583269248],
            [9.306449890136719, 58.72580712638165],
            [9.31915283203125, 58.73240103310712],
            [9.331169128417969, 58.73988449417506],
            [9.331512451171875, 58.74611948204826],
            [9.334259033203125, 58.74985993823975],
            [9.353485107421875, 58.75181906423311],
            [9.37408447265625, 58.76250326278713],
            [9.396743774414062, 58.78030296321539],
            [9.431076049804686, 58.79062261099837],
            [9.460601806640625, 58.807341044348384],
            [9.479827880859373, 58.81694160847107],
            [9.501800537109375, 58.82547321241967],
            [9.523773193359375, 58.83080439883584],
            [9.55535888671875, 58.83293664381354],
            [9.583511352539062, 58.84359590101607],
            [9.610977172851562, 58.862419242277355],
            [9.624710083007812, 58.880167732963],
            [9.645309448242188, 58.88407117928903],
            [9.677581787109375, 58.901453907083685],
            [9.698867797851562, 58.92414471817596],
            [9.68170166015625, 58.93619315034488],
            [9.685134887695312, 58.95177899783242],
            [9.713973999023438, 58.95709074585609],
            [9.721527099609373, 58.968065766246845],
            [9.713287353515625, 58.981160411361394],
            [9.723587036132812, 58.99000532803044],
            [9.74006652832031, 58.981868088274744],
            [9.754486083984375, 58.979037293401746],
            [9.768905639648438, 58.98682141966273],
            [9.786758422851562, 58.98646763389795],
            [9.792938232421875, 58.98045271991158],
            [9.808731079101562, 58.96912767959795],
            [9.832077026367188, 58.96240167583843],
            [9.852676391601562, 58.95354967140543],
            [9.856796264648438, 58.94186154586293],
            [9.904861450195312, 58.9429242663719],
            [9.9151611328125, 58.94965406955339],
            [9.905548095703125, 58.960985507824866],
            [9.918594360351562, 58.95956928164366],
            [9.941940307617188, 58.9577989171166],
            [9.9755859375, 58.960985507824866],
            [10.0030517578125, 58.964525818797824],
            [10.034637451171875, 58.970189560234715],
            [10.05867004394531, 58.981868088274744],
            [10.065536499023436, 58.99778697615515],
            [10.084762573242188, 58.999555287310564],
            [10.114288330078123, 58.99884797374721],
            [10.155487060546875, 58.997433303025154],
            [10.17608642578125, 59.00273801852089],
            [10.193939208984375, 59.012991484243074],
            [10.218658447265625, 59.02041450087754],
            [10.248184204101562, 59.030662700601276],
            [10.282516479492188, 59.03596229536753],
            [10.295562744140623, 59.03666884628407],
            [10.315475463867188, 59.045852686812566],
            [10.318908691406248, 59.05538715489372],
            [10.32440185546875, 59.067742713040786],
            [10.336074829101562, 59.07444815466584],
            [10.349807739257812, 59.08115228664865],
            [10.358734130859375, 59.09067695849205],
            [10.369720458984375, 59.09702527087121],
            [10.377960205078125, 59.0945566223435],
            [10.377960205078125, 59.08503302769211],
            [10.385513305664062, 59.07197788118104],
            [10.385513305664062, 59.059270809305346],
            [10.39306640625, 59.05432790020789],
            [10.40130615234375, 59.047265367559696],
            [10.424652099609375, 59.045852686812566],
            [10.437698364257812, 59.045852686812566],
            [10.460357666015625, 59.04338035575868],
            [10.467910766601562, 59.04832484000854],
            [10.467910766601562, 59.06280102319535],
            [10.48164367675781, 59.07197788118104],
            [10.478897094726562, 59.08397468729772],
            [10.461044311523438, 59.08926606283711],
            [10.468597412109375, 59.09420394376247],
            [10.48164367675781, 59.09032424001845],
            [10.502243041992188, 59.09067695849205],
            [10.50567626953125, 59.09773056638505],
            [10.5084228515625, 59.10689808818351],
            [10.492630004882812, 59.11042340547195],
            [10.477523803710938, 59.11430083575908],
            [10.476837158203123, 59.12205438044871],
            [10.489883422851562, 59.127692220152035],
            [10.498123168945312, 59.13544273484683],
            [10.506362915039062, 59.14424800901294],
            [10.504989624023438, 59.151994777135464],
            [10.5084228515625, 59.16079579449492],
            [10.501556396484375, 59.17205779354684],
            [10.499496459960938, 59.180853650173155],
            [10.5084228515625, 59.184723110191065],
            [10.51116943359375, 59.18015006492145],
            [10.520095825195312, 59.17205779354684],
            [10.530395507812498, 59.16748305369258],
            [10.535888671875, 59.1579797152505],
            [10.54412841796875, 59.15481134900598],
            [10.546875, 59.15973979194356],
            [10.546188354492188, 59.16994645120061],
            [10.558547973632812, 59.17557640776512],
            [10.569534301757812, 59.17311341583366],
            [10.575027465820312, 59.16466752496466],
            [10.592193603515625, 59.16466752496466],
            [10.6072998046875, 59.17029835064482],
            [10.603866577148438, 59.17803922227036],
            [10.597000122070312, 59.188943839698496],
            [10.584640502929688, 59.20371228813144],
            [10.573654174804688, 59.21460871457001],
            [10.554428100585938, 59.221285450756326],
            [10.5194091796875, 59.228312183163155],
            [10.518722534179686, 59.23533746863375],
            [10.52490234375, 59.24341475839977],
            [10.514602661132812, 59.24903261427968],
            [10.506362915039062, 59.2571066603914],
            [10.502243041992188, 59.268336934390746],
            [10.51185607910156, 59.27886195467415],
            [10.512542724609375, 59.287630320343276],
            [10.525588989257812, 59.29324088919415],
            [10.534515380859375, 59.30375821297455],
            [10.533828735351562, 59.3167251017617],
            [10.521469116210938, 59.32548371429322],
            [10.50567626953125, 59.32793572139082],
            [10.498123168945312, 59.33739180590685],
            [10.47821044921875, 59.35069591124497],
            [10.470657348632812, 59.36889308443833],
            [10.48164367675781, 59.38008649593156],
            [10.490570068359375, 59.395471406154286],
            [10.500869750976562, 59.4122469829567],
            [10.503616333007812, 59.41993301322722],
            [10.500869750976562, 59.439489583059725],
            [10.489883422851562, 59.45135770220262],
            [10.469970703124998, 59.453800621583184],
            [10.443191528320312, 59.45729020026186],
            [10.42327880859375, 59.45729020026186],
            [10.412979125976562, 59.45345164390584],
            [10.399246215820312, 59.45449856613254],
            [10.385513305664062, 59.450659692819414],
            [10.373153686523438, 59.460081603891666],
            [10.3656005859375, 59.47089611604663],
            [10.355300903320312, 59.483101890457746],
            [10.345687866210938, 59.484496555303814],
            [10.33538818359375, 59.48937742871752],
            [10.325775146484373, 59.49530325516139],
            [10.312042236328125, 59.5012280415196],
            [10.298995971679688, 59.512377759418186],
            [10.290069580078125, 59.52422029805834],
            [10.282516479492188, 59.53222906931375],
            [10.268783569335938, 59.54023593810587],
            [10.254364013671875, 59.54963287868867],
            [10.26397705078125, 59.55380845543803],
            [10.286636352539062, 59.54545678424146],
            [10.298995971679688, 59.534666143805765],
            [10.314788818359375, 59.51620962404412],
            [10.325775146484373, 59.51377121510314],
            [10.331954956054688, 59.51063585886305],
            [10.336761474609375, 59.515512953762936],
            [10.3436279296875, 59.512726128735956],
            [10.36079406738281, 59.512726128735956],
            [10.364227294921875, 59.51899616127268],
            [10.369033813476562, 59.525613264515684],
            [10.386199951171873, 59.52909542888642],
            [10.402679443359373, 59.535362418437586],
            [10.407485961914062, 59.527702606298185],
            [10.425338745117188, 59.51864785670979],
            [10.447311401367188, 59.514119570030246],
            [10.474090576171875, 59.515861290702276],
            [10.496063232421875, 59.517602921437295],
            [10.512542724609375, 59.51795123679212],
            [10.518035888671875, 59.52387204745182],
            [10.535888671875, 59.527702606298185],
            [10.55511474609375, 59.531880901429716],
            [10.584640502929688, 59.54162824284582],
            [10.603866577148438, 59.553112561929254],
            [10.612106323242188, 59.56215805601119],
            [10.623092651367188, 59.57050558629709],
            [10.6292724609375, 59.57572174155738],
            [10.64300537109375, 59.572592145425546],
            [10.642662048339844, 59.561462335041554],
            [10.646781921386719, 59.55050283320214],
            [10.652275085449219, 59.539713808995884],
            [10.657081604003904, 59.53170681613907],
            [10.662574768066406, 59.52300140519892],
            [10.667724609375, 59.51725460248509],
            [10.668411254882812, 59.513422856578394],
            [10.665664672851562, 59.508371253671484],
            [10.6622314453125, 59.50732600000425],
            [10.655708312988281, 59.50784863088567],
            [10.65021514892578, 59.50871966436453],
            [10.650901794433594, 59.514642095675434],
            [10.644035339355469, 59.51795123679212],
            [10.63270568847656, 59.51795123679212],
            [10.628585815429688, 59.514119570030246],
            [10.624809265136719, 59.50993907345626],
            [10.626182556152344, 59.50488694884694],
            [10.630989074707031, 59.50314466150199],
            [10.636825561523438, 59.507151787911404],
            [10.641288757324219, 59.500705308087],
            [10.64849853515625, 59.49408331709822],
            [10.638885498046875, 59.490074638757314],
            [10.631332397460938, 59.48641412542625],
            [10.622062683105469, 59.483973562736],
            [10.622062683105469, 59.47298884774133],
            [10.624122619628906, 59.46740794183739],
            [10.625495910644531, 59.4632216574717],
            [10.617599487304688, 59.46182611411528],
            [10.61553955078125, 59.457988072778164],
            [10.610733032226562, 59.45031068272478],
            [10.608329772949217, 59.44402788504935],
            [10.60455322265625, 59.44210790851173],
            [10.592536926269531, 59.44071149348957],
            [10.581207275390625, 59.4389658936508],
            [10.572624206542969, 59.43634732500923],
            [10.574684143066406, 59.43285558488878],
            [10.58258056640625, 59.43110957970815],
            [10.58258056640625, 59.42133028585328],
            [10.58807373046875, 59.41661425962167],
            [10.598373413085938, 59.41504210490353],
            [10.609359741210936, 59.41661425962167],
            [10.619659423828125, 59.41958368606043],
            [10.624809265136719, 59.41643957936853],
            [10.63270568847656, 59.41661425962167],
            [10.639228820800781, 59.42080631537617],
            [10.649871826171875, 59.425521757748825],
            [10.652275085449219, 59.419059688552366],
            [10.64849853515625, 59.40700550748962],
            [10.643348693847656, 59.39721924965303],
            [10.64300537109375, 59.389702883006215],
            [10.647468566894531, 59.381135688884356],
            [10.64849853515625, 59.37099213046821],
            [10.65673828125, 59.36521944136796],
            [10.653305053710938, 59.355071122551124],
            [10.655021667480469, 59.34982080131775],
            [10.656394958496094, 59.33774198071846],
            [10.659828186035156, 59.33283920485065],
            [10.660514831542969, 59.327235165987226],
            [10.670127868652344, 59.319177740764495],
            [10.67596435546875, 59.31181929289073],
            [10.68042755126953, 59.30638703593351],
            [10.684547424316406, 59.30183028033695],
            [10.687637329101562, 59.29849995749356],
            [10.696907043457031, 59.29849995749356],
            [10.701370239257812, 59.301655008315144],
            [10.705490112304688, 59.30200555145568],
            [10.7061767578125, 59.30551078418925],
            [10.709953308105469, 59.30813947169771],
            [10.713043212890625, 59.30586128759598],
            [10.718536376953123, 59.30235609098392],
            [10.727119445800781, 59.302180821671335],
            [10.737762451171875, 59.30358295088638],
            [10.746002197265625, 59.30410873444171],
            [10.753211975097656, 59.301655008315144],
            [10.7501220703125, 59.29867524577121],
            [10.742225646972656, 59.29078637913368],
            [10.732269287109375, 59.28622753360349],
            [10.724716186523438, 59.27728340899363],
            [10.720596313476562, 59.270091330332775],
            [10.72437286376953, 59.26535425390009],
            [10.72334289550781, 59.262195836949864],
            [10.726432800292969, 59.25868614135535],
            [10.724029541015625, 59.25464954448365],
            [10.72540283203125, 59.24815488531964],
            [10.73089599609375, 59.24446817689226],
            [10.728492736816406, 59.241658988585826],
            [10.726089477539062, 59.22796088090915],
            [10.731925964355469, 59.22392064498393],
            [10.731239318847656, 59.21583873779786],
            [10.733299255371094, 59.20986392405599],
            [10.737762451171875, 59.20968817840924],
            [10.740165710449219, 59.21214853514156],
            [10.749435424804688, 59.214432993347785],
            [10.758018493652344, 59.20810642687878],
            [10.749435424804688, 59.20547001148441],
            [10.754241943359375, 59.19685630253296],
            [10.754241943359375, 59.19333987861877],
            [10.761451721191406, 59.18911969211509],
            [10.766258239746092, 59.18437135920059],
            [10.778617858886719, 59.18173311137205],
            [10.787887573242188, 59.178391038430675],
            [10.792007446289062, 59.17540048565415],
            [10.78857421875, 59.17205779354684],
            [10.795440673828123, 59.16730708993753],
            [10.798873901367188, 59.162203747189565],
            [10.799560546875, 59.15586750368786],
            [10.805397033691406, 59.151290597942804],
            [10.815353393554688, 59.150586404259755],
            [10.828056335449219, 59.1488258566554],
            [10.834579467773438, 59.15023430198427],
            [10.839385986328123, 59.15745167458476],
            [10.838356018066404, 59.16061979633346],
            [10.847625732421875, 59.162731714509796],
            [10.853805541992188, 59.16537142887627],
            [10.860328674316406, 59.163611641934494],
            [10.863761901855469, 59.16589934730319],
            [10.862388610839844, 59.17100213866936],
            [10.862045288085938, 59.17663192142111],
            [10.872688293457031, 59.17540048565415],
            [10.881614685058594, 59.17205779354684],
            [10.880928039550781, 59.16713112527707],
            [10.882644653320312, 59.162555726308526],
            [10.887794494628906, 59.15850774776641],
            [10.884361267089844, 59.153051018755384],
            [10.889854431152344, 59.147769484619786],
            [10.888481140136719, 59.14055006969157],
            [10.887794494628906, 59.13773232398847],
            [10.896377563476562, 59.13773232398847],
            [10.893630981445312, 59.13561886252393],
            [10.886421203613281, 59.13174384435585],
            [10.886077880859373, 59.12839688487726],
            [10.87921142578125, 59.1250495983186],
            [10.874061584472656, 59.12522577945005],
            [10.865478515625, 59.12822072005503],
            [10.856208801269531, 59.129982327506035],
            [10.843162536621092, 59.129453854784],
            [10.841102600097656, 59.12593049491497],
            [10.834922790527344, 59.11870647410298],
            [10.829086303710938, 59.113243398274385],
            [10.822563171386719, 59.10936584835299],
            [10.812263488769531, 59.1012568263837],
            [10.815696716308594, 59.09473296027409],
            [10.828742980957031, 59.08961879219101],
            [10.822906494140625, 59.085385800569334],
            [10.83148956298828, 59.08221071409661],
            [10.836639404296875, 59.077623959373156],
            [10.847625732421875, 59.07021329128137],
            [10.864105224609375, 59.06562493304652],
            [10.890541076660156, 59.06174199712982],
            [10.902557373046875, 59.053621712271465],
            [10.91217041015625, 59.050090554831854],
            [10.919380187988281, 59.04549950755147],
            [10.92864990234375, 59.03613893445807],
            [10.938262939453125, 59.0294260109456],
            [10.949249267578125, 59.02677581190898],
            [10.953712463378906, 59.02977935538575],
            [10.957489013671875, 59.03313594648249],
            [10.971565246582031, 59.028895987477476],
            [10.986328125, 59.025715675105985],
            [11.001777648925781, 59.02059121984881],
            [11.0028076171875, 59.01528925577638],
            [10.998687744140625, 59.00397566785937],
            [11.001434326171875, 58.99566488287941],
            [11.013450622558594, 58.9926583601785],
            [11.028213500976562, 58.98947469707487],
            [11.040916442871094, 58.98487555298827],
            [11.057395935058594, 58.982221921280285],
            [11.090011596679688, 58.99000532803044],
            [11.110610961914062, 59.01016324708539],
            [11.122970581054686, 59.02960268361954],
            [11.140823364257812, 59.045852686812566],
            [11.141510009765625, 59.059976881113364],
            [11.155929565429688, 59.07938816835443],
            [11.221847534179688, 59.08712756348473],
            [11.229486465454102, 59.087766922621135],
            [11.238884925842283, 59.0909855841814],
            [11.247081756591797, 59.092925453506034],
            [11.254119873046875, 59.09460070691114],
            [11.258068084716797, 59.095658719535656],
            [11.267681121826172, 59.097311798923116],
            [11.275405883789062, 59.097906887997105],
            [11.287078857421875, 59.09914111389803],
            [11.295833587646484, 59.10187388460298],
            [11.30544662475586, 59.10601670221206],
            [11.322269439697266, 59.110952171795766],
            [11.332054138183594, 59.113507760704294],
            [11.343040466308594, 59.1130671555216],
            [11.350765228271484, 59.10672181280204],
            [11.364326477050781, 59.098964798631826],
            [11.377544403076172, 59.089089696800116],
            [11.378402709960938, 59.0799174133647],
            [11.385097503662108, 59.07312481597795],
            [11.397628784179688, 59.06209500944696],
            [11.400890350341797, 59.05326861285944],
            [11.413593292236328, 59.03817021876611],
            [11.428184509277344, 59.028895987477476],
            [11.441059112548828, 59.01484738855249],
            [11.454963684082031, 58.99681436630572],
            [11.453247070312498, 58.988236526384746],
            [11.466979980468748, 58.93583884471032],
            [11.458740234375, 58.89187675042151],
            [11.53564453125, 58.880522609923936],
            [11.5521240234375, 58.8947147030972],
            [11.6015625, 58.89755242281771],
            [11.656494140625, 58.9216636365964],
            [11.68670654296875, 58.967003820180736],
            [11.708679199218748, 59.02642243660573],
            [11.77734375, 59.09138238455909],
            [11.766357421875, 59.12099718220653],
            [11.78009033203125, 59.19843857520702],
            [11.82403564453125, 59.2377959767454],
            [11.815795898437498, 59.33318942659219],
            [11.766357421875, 59.428315784042574],
            [11.694946289062498, 59.57328763638666],
            [11.69769287109375, 59.61360152474516],
            [11.76361083984375, 59.63998787256213],
            [11.85699462890625, 59.64554025144323],
            [11.87896728515625, 59.689926220143356],
            [11.93389892578125, 59.69408439227943],
            [11.93115234375, 59.752244616362454],
            [11.90093994140625, 59.81030377363804],
            [11.84326171875, 59.84619455951219],
            [11.97784423828125, 59.90133567536662],
            [12.041015625, 59.89031476736669],
            [12.15911865234375, 59.88893689676585],
            [12.3431396484375, 59.966009702748345],
            [12.389831542968748, 60.01408933585082],
            [12.480468749999998, 60.071692739989686],
            [12.54364013671875, 60.19342537315118],
            [12.49969482421875, 60.31742848597351],
            [12.6068115234375, 60.40164539086417],
            [12.60406494140625, 60.523509074271864],
            [12.5244140625, 60.59775635343141],
            [12.50518798828125, 60.646262316136976],
            [12.392578125, 60.73231517201876],
            [12.345886230468748, 60.847586595361314],
            [12.33489990234375, 60.89037248051405],
            [12.2222900390625, 61.01572481397616],
            [12.447509765625, 61.05030961670573],
            [12.59857177734375, 61.04233185542855],
            [12.68646240234375, 61.05562700886678],
            [12.7056884765625, 61.1485370451378],
            [12.81005859375, 61.213411800014676],
            [12.873229980468748, 61.357246826420266],
            [12.57659912109375, 61.567189443880316],
            [12.47222900390625, 61.567189443880316],
            [12.41729736328125, 61.56195810590954],
            [12.35137939453125, 61.61162021549917],
            [12.29095458984375, 61.63381172413662],
            [12.14263916015625, 61.722418766105136],
            [12.299194335937498, 62.261531511155],
            [12.2882080078125, 62.296027446274095],
            [12.0465087890625, 62.612298676168955],
            [12.13714599609375, 62.7484383230469],
            [12.07122802734375, 62.902724956979554],
            [12.2222900390625, 62.996405554425344],
            [11.97784423828125, 63.26947621713553],
            [12.2003173828125, 63.46278300222105],
            [12.208557128906248, 63.482410111334765],
            [12.15087890625, 63.591340327146554],
            [12.29095458984375, 63.657230237065185],
            [12.3321533203125, 63.726615071572304],
            [12.68096923828125, 63.9735506894763],
            [12.91717529296875, 64.05778492314845],
            [13.2110595703125, 64.09500753713654],
            [13.386840820312498, 64.07700280695103],
            [13.5736083984375, 64.05898642926812],
            [13.80157470703125, 64.03133868163432],
            [13.98834228515625, 64.01329277121489],
            [14.15313720703125, 64.18366059975261],
            [14.1229248046875, 64.45621824711147],
            [14.08721923828125, 64.48226107017604],
            [13.88946533203125, 64.50473266150239],
            [13.656005859375, 64.57911166227728],
            [13.699951171875, 64.64152753972131],
            [13.9691162109375, 64.85127179165902],
            [14.32342529296875, 65.11723905368359],
            [14.37835693359375, 65.24520807726229],
            [14.50469970703125, 65.31412353589782],
            [14.50469970703125, 65.52206835254366],
            [14.53216552734375, 65.69221469637468],
            [14.622802734374998, 65.8129062926053],
            [14.578857421875, 65.89268028960205],
            [14.5184326171875, 66.133854089549],
            [15.032043457031248, 66.15495944163864],
            [15.4852294921875, 66.28343241541462],
            [15.37811279296875, 66.48259237819617],
            [15.61431884765625, 66.60394780608475],
            [15.770874023437498, 66.7171136969819],
            [15.8148193359375, 66.73664755802173],
            [16.0345458984375, 66.91391101065433],
            [16.1993408203125, 66.9859616682753],
            [16.388854980468746, 67.04601992373965],
            [16.4080810546875, 67.20403234340081],
            [16.083984375, 67.434905815823],
            [16.14990234375, 67.52012315937381],
            [16.41082763671875, 67.53272197257299],
            [16.556396484375, 67.6489439704077],
            [16.7431640625, 67.91688129785506],
            [17.1771240234375, 68.0476497642884],
            [17.28424072265625, 68.11736463499349],
            [17.67425537109375, 68.03737978617224],
            [17.90496826171875, 67.96845304770049],
            [18.1494140625, 68.19401187153642],
            [18.09722900390625, 68.40322416624197],
            [18.127441406249996, 68.53728140042286],
            [18.402099609375, 68.58045336848764],
            [18.621826171875, 68.50711233732532],
            [18.984375, 68.51717317792524],
            [19.912719726562496, 68.35768619207559],
            [20.2203369140625, 68.49100565560381],
            [20.01983642578125, 68.53024556319521],
            [19.93743896484375, 68.55636760053231],
            [20.06378173828125, 68.59148406080944],
            [20.20660400390625, 68.66654914712133],
            [20.33294677734375, 68.80103434633895],
            [20.31097412109375, 68.92384860271805],
            [20.05828857421875, 69.04598355257725],
            [20.547377114740073, 69.05991840012504],
            [20.552336400833454, 69.06010504456746],
            [20.717112646265377, 69.11975233707406],
            [21.060791015624996, 69.03714171275197],
            [21.11297607421875, 69.10679728947845],
            [21.0552978515625, 69.12442128850184],
            [20.98388671875, 69.20062795134653],
            [21.0223388671875, 69.23473677252892],
            [21.2750244140625, 69.31152882092375],
            [21.6265869140625, 69.27656870647351],
            [22.1759033203125, 68.95543220313955],
            [22.1868896484375, 68.91594563602459],
            [22.33795166015625, 68.8218814529707],
            [22.3736572265625, 68.71745001058511],
            [22.532958984375, 68.74435062211467],
            [22.796630859375, 68.68752236753184],
            [23.04656982421875, 68.6885206018014],
            [23.17291259765625, 68.62754666785223],
            [23.44482421875, 68.6925130931061],
            [23.675537109375, 68.70448628851169],
            [23.738708496093746, 68.757291200374],
            [23.7744140625, 68.8218814529707],
            [23.87054443359375, 68.83874335538204],
            [23.983154296875, 68.82585011229355],
            [24.07379150390625, 68.78116176003344],
            [24.15618896484375, 68.7871254010426],
            [24.1534423828125, 68.75629603810017],
            [24.21661376953125, 68.74534631807167],
            [24.268798828125, 68.71944376132119],
            [24.6258544921875, 68.6815320256599],
            [24.78515625, 68.63355147029284],
            [24.853820800781246, 68.55937973231138],
            [24.900512695312496, 68.55435928867225],
            [24.92523193359375, 68.60852084639889],
            [24.9664306640625, 68.61553223694504],
            [25.007629394531246, 68.61152599631261],
            [25.0433349609375, 68.62554470960035],
            [25.0653076171875, 68.62254143707757],
            [25.0927734375, 68.63555271371307],
            [25.11749267578125, 68.64255565871365],
            [25.11749267578125, 68.70348876730434],
            [25.13946533203125, 68.76624566022222],
            [25.15869140625, 68.80202750946339],
            [25.23284912109375, 68.8218814529707],
            [25.24383544921875, 68.84469155624497],
            [25.33721923828125, 68.86747821724371],
            [25.48553466796875, 68.90309728240705],
            [25.5706787109375, 68.88529491756016],
            [25.62286376953125, 68.89320884894178],
            [25.675048828125, 68.92187312615616],
            [25.740966796875, 68.99484816896614],
            [25.77117919921875, 69.01059481104339],
            [25.746459960937496, 69.0361590661957],
            [25.732727050781246, 69.09602007168107],
            [25.73822021484375, 69.13811902334064],
            [25.6915283203125, 69.2045288107636],
            [25.7080078125, 69.25225762765034],
            [25.7354736328125, 69.27851241589693],
            [25.73822021484375, 69.31637993029959],
            [25.784912109375, 69.35030725288914],
            [25.8233642578125, 69.36967039323389],
            [25.839843749999996, 69.39771610407463],
            [25.8123779296875, 69.42186416722114],
            [25.83709716796875, 69.46237196894127],
            [25.867309570312496, 69.47585760766289],
            [25.853576660156246, 69.49414599385548],
            [25.8837890625, 69.52587297464314],
            [25.8343505859375, 69.55179646385184],
            [25.916748046875, 69.5690613327378],
            [25.974426269531246, 69.60642069005807],
            [25.9442138671875, 69.65422163086657],
            [25.90301513671875, 69.66854097958829],
            [25.96343994140625, 69.69810372162485],
            [26.03759765625, 69.72381836721924],
            [26.12548828125, 69.74569882455773],
            [26.21426604286259, 69.78036107451466],
            [26.24434404666391, 69.8164487881311],
            [26.33631195861446, 69.83736101875434],
            [26.400988821473387, 69.86688753205699],
            [26.418023880752525, 69.8889182250721],
            [26.407145124577735, 69.90272129565366],
            [26.4519775736903, 69.91761763191847],
            [26.463582241342973, 69.93903938968892],
            [26.590014613808787, 69.9553535727213],
            [26.67919114421389, 69.96503864511868],
            [26.71391997497929, 69.94513237568913],
            [26.778771609060215, 69.95816222051766],
            [26.85404045808724, 69.96019669189917],
            [26.856787040118974, 69.94350174838351],
            [26.88744743760367, 69.93200209598629],
            [26.954956054687496, 69.9397233083344],
            [26.99615478515625, 69.93218514205873],
            [27.03132718422748, 69.90994989848808],
            [27.108764648437496, 69.91898681560552],
            [27.131709613493683, 69.93078606717509],
            [27.303373203863654, 69.9569936312013],
            [27.276306152343746, 69.97643300523859],
            [27.31028224503902, 69.99009493292336],
            [27.364196777343746, 69.99335420717576],
            [27.395914655827738, 70.00706273901986],
            [27.43560791015625, 70.01777173464197],
            [27.52899169921875, 70.02434079930296],
            [27.56744384765625, 70.06277517156296],
            [27.612316669447324, 70.07503700649615],
            [27.6951980713701, 70.07656229656477],
            [27.742333534208026, 70.06451223990899],
            [27.863631138724482, 70.08379203363455],
            [27.95745849609375, 70.09266397775008],
            [27.97659718470203, 70.05345087497273],
            [27.983772809543552, 70.01395728785296],
            [28.16035159712871, 69.92105578588239],
            [28.345524100084305, 69.88106351122642],
            [28.330285713787788, 69.84943628964442],
            [28.403421893475638, 69.81871046546479],
            [28.920339043698476, 69.72916309621792],
            [29.132995605468746, 69.69524461137115],
            [29.17029182020883, 69.63920305610552],
            [29.336287475295393, 69.47849816547419],
            [29.217920077702477, 69.39689149592107],
            [28.831315150337954, 69.22402262214933],
            [28.83029804201722, 69.17159979853069],
            [28.805354806165752, 69.11123582423497],
            [28.854531853010624, 69.07637963778262],
            [28.933940148117752, 69.05092139510936],
            [29.04510498046875, 69.01354605132325],
            [29.2401123046875, 69.11071496163903],
            [29.234619140625004, 69.12442128850184],
            [29.27032470703125, 69.16060380441408],
            [29.319763183593746, 69.2386315176728],
            [29.281311035156254, 69.24933846678229],
            [29.28680419921875, 69.29599795934462],
            [29.39117431640625, 69.32413944303502],
            [29.413146972656246, 69.31832006949072],
            [29.51751708984375, 69.32704854262361],
            [29.53125, 69.31929007383027],
            [29.561462402343746, 69.31832006949072],
            [29.68505859375, 69.35999099463781],
            [29.709777832031246, 69.38708237129833],
            [29.7894287109375, 69.39964894620636],
            [29.858093261718746, 69.42379484216353],
            [29.8992919921875, 69.41993331898047],
            [29.934997558593746, 69.40834458952362],
            [30.028381347656246, 69.41800229742812],
            [30.10528564453125, 69.46526246220968],
            [30.11627197265625, 69.48837239932325],
            [30.135498046875, 69.52202978356681],
            [30.16845703125, 69.536438191016],
            [30.173950195312504, 69.58535422894994],
            [30.135498046875, 69.64180371719554],
            [30.0860595703125, 69.63989262488842],
            [30.091552734374996, 69.65899581985833],
            [30.149230957031246, 69.66758665667558],
            [30.2178955078125, 69.65517655450056],
            [30.390930175781246, 69.60163468631062],
            [30.511779785156246, 69.54123883692125],
            [30.6243896484375, 69.54123883692125],
            [30.808410644531246, 69.52875491492895],
            [30.93475341796875, 69.55947146230761],
            [30.956726074218746, 69.63320244892391],
            [30.929260253906246, 69.67044949672683],
            [30.948486328124996, 69.67998950911426],
            [30.882568359374996, 69.7162024672237],
            [30.8935546875, 69.72857691367057],
            [30.816650390624996, 69.79413569863112],
            [30.731506347656254, 69.81310023846743],
            [30.602416992187496, 69.82825958832647],
            [30.42388916015625, 69.8320477208414],
            [30.37445068359375, 69.85854556489717],
            [30.30853271484375, 69.88689908234294],
            [30.1519775390625, 69.89256519285392],
            [29.937744140625, 69.91710066117601],
            [29.652099609375, 69.9246442599552],
            [29.729003906249996, 69.95855684783905],
            [29.696044921875, 69.98865524935208],
            [29.5587158203125, 70.01307827710367],
            [28.95172119140625, 70.07869056114092],
            [28.8665771484375, 70.11048478105927],
            [28.863830566406246, 70.13289843995393],
            [29.49005126953125, 70.07401082987566],
            [29.75921630859375, 70.04965922203077],
            [29.907531738281246, 70.05340748054604],
            [30.099792480468746, 70.05153343573795],
            [30.30853271484375, 70.0702662855411],
            [30.297546386718746, 70.10207340526765],
            [30.41290283203125, 70.13196502159133],
            [30.40191650390625, 70.17113237987961],
            [30.48431396484375, 70.18137824561084],
            [30.5694580078125, 70.22695721763934],
            [30.772705078125, 70.23531792009278],
            [30.9539794921875, 70.24646024418493],
            [31.08856201171875, 70.27428967614655],
            [31.08306884765625, 70.30022984515816],
            [31.08856201171875, 70.3400027614273],
            [31.179199218749996, 70.37047384596998],
            [31.17645263671875, 70.39537098616145],
            [31.102294921875, 70.4119522233256],
            [31.02813720703125, 70.41931733654927],
            [30.984191894531246, 70.45058939483916],
            [30.874328613281246, 70.45885925640687],
            [30.769958496093746, 70.44323558364887],
            [30.73699951171875, 70.48364867571351],
            [30.657348632812504, 70.51482199333275],
            [30.65460205078125, 70.56240627797291],
            [30.574951171874996, 70.55874991779058],
            [30.4486083984375, 70.56697579857062],
            [30.289306640625, 70.55692148976206],
            [30.3826904296875, 70.60714307134866],
            [30.33050537109375, 70.63903729362929],
            [30.239868164062496, 70.66269748467985],
            [30.23712158203125, 70.68632986659676],
            [30.091552734374996, 70.71991267426513],
            [29.94598388671875, 70.71900576981702],
            [29.8333740234375, 70.66360693648447],
            [29.830627441406246, 70.69904348679395],
            [29.81689453125, 70.73532377285133],
            [29.7235107421875, 70.74257195331576],
            [29.580688476562496, 70.72625985651204],
            [29.377441406249996, 70.7471007337379],
            [29.429626464843754, 70.77425190565538],
            [29.344482421875, 70.8004630161594],
            [29.311523437499996, 70.83746143540387],
            [29.1741943359375, 70.86899115228086],
            [29.014892578124996, 70.90226826757711],
            [28.770446777343746, 70.88878426638139],
            [28.531494140624996, 70.78510205575454],
            [28.49029541015625, 70.71991267426513],
            [28.43536376953125, 70.63175163566882],
            [28.3282470703125, 70.53954317685509],
            [28.05908203125, 70.52947522492511],
            [28.16070556640625, 70.55783572443914],
            [28.267822265625, 70.60714307134866],
            [28.35296630859375, 70.66087845765566],
            [28.333740234375, 70.71991267426513],
            [28.28155517578125, 70.73894819109019],
            [28.1744384765625, 70.73351131769438],
            [28.196411132812496, 70.75887076796162],
            [28.2623291015625, 70.78148599387845],
            [28.3721923828125, 70.8140069908578],
            [28.509521484375, 70.87619093033821],
            [28.5699462890625, 70.94087166216528],
            [28.56170654296875, 70.99192781052929],
            [28.4820556640625, 71.00623567007617],
            [28.32550048828125, 71.02857093736978],
            [28.31451416015625, 71.05623153667605],
            [28.289794921874996, 71.09453515423968],
            [28.212890625, 71.1087686070715],
            [27.96295166015625, 71.11054706255386],
            [27.88604736328125, 71.08296286656015],
            [27.79541015625, 71.09631490072555],
            [27.75146484375, 71.14341940496215],
            [27.6031494140625, 71.14608231274921],
            [27.47955322265625, 71.10610062134981],
            [27.4383544921875, 71.0392828779873],
            [27.3284912109375, 71.05444815480718],
            [27.2076416015625, 71.04909703880152],
            [27.1142578125, 70.9928223557653],
            [27.0703125, 70.92741390203756],
            [27.155456542968746, 70.89058266258947],
            [27.34771728515625, 70.87529110073578],
            [27.366943359375, 70.8554845355292],
            [27.213134765625, 70.82934571366151],
            [27.1527099609375, 70.80678468227529],
            [27.095031738281246, 70.77153844715059],
            [27.07305908203125, 70.71991267426513],
            [26.99615478515625, 70.64814065995955],
            [26.91925048828125, 70.58798229489466],
            [26.90277099609375, 70.53588268255716],
            [26.8011474609375, 70.5285597083426],
            [26.713256835937496, 70.51390581450643],
            [26.67755126953125, 70.44231617038103],
            [26.60064697265625, 70.4900706273772],
            [26.6143798828125, 70.5166542267795],
            [26.65557861328125, 70.59163336937401],
            [26.68853759765625, 70.65814960855752],
            [26.6748046875, 70.70176678647707],
            [26.71600341796875, 70.7471007337379],
            [26.77642822265625, 70.79684973597446],
            [26.792907714843746, 70.85188122123132],
            [26.751708984375, 70.86899115228086],
            [26.751708984375, 70.92741390203756],
            [26.713256835937496, 70.97850476575198],
            [26.630859375, 70.982980127959],
            [26.488037109375, 70.95073487488914],
            [26.35894775390625, 70.90586245415463],
            [26.17767333984375, 70.80949335453967],
            [26.015625, 70.72807296791687],
            [25.94970703125, 70.62446334133946],
            [25.82611083984375, 70.57976496276407],
            [25.65032958984375, 70.51298959427594],
            [25.52398681640625, 70.4367988185464],
            [25.40313720703125, 70.36955114662747],
            [25.3179931640625, 70.35016483409582],
            [25.213623046875, 70.34462251094716],
            [25.2301025390625, 70.36678279862433],
            [25.32073974609375, 70.37693157498282],
            [25.3729248046875, 70.40550556601694],
            [25.3070068359375, 70.42667978910667],
            [25.3070068359375, 70.4542653039699],
            [25.367431640625, 70.48640118942858],
            [25.39215087890625, 70.51482199333275],
            [25.29327392578125, 70.51482199333275],
            [25.263061523437496, 70.53039070013128],
            [25.27130126953125, 70.55874991779058],
            [25.39215087890625, 70.623552119137],
            [25.58990478515625, 70.70448971655144],
            [25.66131591796875, 70.7180988243322],
            [25.74920654296875, 70.731698698495],
            [25.87005615234375, 70.75253391771874],
            [25.89752197265625, 70.7661104246574],
            [25.83709716796875, 70.77153844715059],
            [25.740966796875, 70.7661104246574],
            [25.7904052734375, 70.786005968925],
            [25.8563232421875, 70.80136623397624],
            [25.88104248046875, 70.83205132172674],
            [25.95245361328125, 70.86809099659101],
            [25.92498779296875, 70.9004709300556],
            [25.80963134765625, 70.92561884290107],
            [25.93048095703125, 70.94356211682243],
            [26.07879638671875, 70.95790503334283],
            [26.169433593749996, 70.98476998890614],
            [26.27105712890625, 71.03303495416577],
            [26.22711181640625, 71.07494728543428],
            [26.14471435546875, 71.07940078992384],
            [26.034851074218746, 71.05533986595637],
            [25.9771728515625, 71.07049277109596],
            [26.05682373046875, 71.09275524632022],
            [26.07330322265625, 71.11677038645317],
            [26.02935791015625, 71.14519471708708],
            [25.94696044921875, 71.15761739285172],
            [25.8563232421875, 71.14519471708708],
            [25.8563232421875, 71.17091864969487],
            [25.779418945312496, 71.19129633610844],
            [25.6640625, 71.20457467835712],
          ],
        ],
      },
      properties: {},
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.871429443359375, 70.71379027282231],
            [25.86627960205078, 70.71379027282231],
            [25.842247009277344, 70.71231608207732],
            [25.810661315917965, 70.70619136017554],
            [25.78765869140625, 70.70017823991583],
            [25.771007537841797, 70.68854397766621],
            [25.76963424682617, 70.67701657325169],
            [25.780620574951172, 70.66593721897372],
            [25.797786712646484, 70.66167430222708],
            [25.848426818847656, 70.66667603263421],
            [25.88207244873047, 70.68212806158259],
            [25.891685485839844, 70.69382279579952],
            [25.869369506835938, 70.70176678647707],
            [25.87726593017578, 70.71016130062064],
            [25.871429443359375, 70.71379027282231],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.950637817382812, 71.05924062689283],
            [24.93724822998047, 71.05846053660674],
            [24.939308166503903, 71.05478255122607],
            [24.95922088623047, 71.05322198596022],
            [24.947547912597656, 71.04943152620068],
            [24.92626190185547, 71.04564033598945],
            [24.933471679687496, 71.04218302583881],
            [24.933471679687496, 71.03247700740044],
            [24.917335510253906, 71.02645017490491],
            [24.930381774902344, 71.02131475168424],
            [24.9444580078125, 71.0137208041506],
            [24.957847595214844, 71.00802342305035],
            [24.945831298828125, 71.00433600495582],
            [24.94892120361328, 70.99628833563003],
            [24.974670410156246, 70.99427590530432],
            [25.01483917236328, 70.99997725824855],
            [25.032005310058594, 71.00489474898518],
            [25.040931701660153, 71.00880551401514],
            [25.02960205078125, 71.01561956535295],
            [25.042648315429684, 71.02120309718227],
            [25.04848480224609, 71.02667342381172],
            [25.052604675292965, 71.03348130019585],
            [25.060157775878903, 71.03883666346618],
            [25.04676818847656, 71.04419057019665],
            [25.030288696289062, 71.04363293952781],
            [25.017242431640625, 71.03917132530526],
            [25.00213623046875, 71.03749795921286],
            [24.99801635742187, 71.04285223004554],
            [24.98462677001953, 71.04630942263942],
            [24.978790283203125, 71.05199574066896],
            [24.96265411376953, 71.05690026318491],
            [24.950637817382812, 71.05924062689283],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.724044799804688, 71.1176592714395],
            [24.697265625, 71.1158814611488],
            [24.686279296875, 71.10743465958748],
            [24.691772460937496, 71.09920664449078],
            [24.714431762695312, 71.0900850817248],
            [24.737091064453125, 71.08429847872816],
            [24.717864990234375, 71.07940078992384],
            [24.715118408203125, 71.08474366259543],
            [24.701385498046875, 71.09053013438525],
            [24.674606323242188, 71.09520257808919],
            [24.643707275390625, 71.09409019239473],
            [24.625167846679688, 71.08251764231471],
            [24.605941772460938, 71.07249742752991],
            [24.57916259765625, 71.0664828446038],
            [24.59564208984375, 71.06291777854601],
            [24.598388671874996, 71.05712316696783],
            [24.615554809570312, 71.05400228407099],
            [24.657440185546875, 71.0461979097777],
            [24.686965942382812, 71.0392828779873],
            [24.706192016601562, 71.03124946886855],
            [24.735031127929688, 71.0276780125829],
            [24.77691650390625, 71.02656179967457],
            [24.7906494140625, 71.03348130019585],
            [24.785842895507812, 71.04218302583881],
            [24.814682006835934, 71.04441361803997],
            [24.819488525390625, 71.05266461121374],
            [24.80438232421875, 71.06068928388831],
            [24.781723022460934, 71.06626004691812],
            [24.800949096679684, 71.07472458370293],
            [24.839401245117188, 71.07405646336098],
            [24.84832763671875, 71.07895548491233],
            [24.81674194335937, 71.08363068400052],
            [24.831161499023438, 71.08852731794042],
            [24.850387573242188, 71.0956475147105],
            [24.866867065429688, 71.10254274232307],
            [24.842147827148434, 71.1078793188323],
            [24.825668334960938, 71.10654531084646],
            [24.803695678710938, 71.10587827281985],
            [24.788589477539062, 71.10187556814618],
            [24.775543212890625, 71.10298751250016],
            [24.772109985351562, 71.10721232618369],
            [24.774169921875, 71.11343670865885],
            [24.752883911132812, 71.1158814611488],
            [24.724044799804688, 71.1176592714395],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.171295166015625, 71.10232035345231],
            [24.147262573242188, 71.10165317171077],
            [24.1259765625, 71.09453515423968],
            [24.099197387695312, 71.09609244124275],
            [24.05250549316406, 71.09653735768612],
            [24.02435302734375, 71.08741455383107],
            [24.003753662109375, 71.09142020943207],
            [23.99139404296875, 71.09965149031751],
            [23.95912170410156, 71.0967598121245],
            [23.96392822265625, 71.08162716353847],
            [23.93646240234375, 71.07917813868013],
            [23.926849365234375, 71.06759679514897],
            [23.928909301757812, 71.04887404404262],
            [23.981781005859375, 71.03571287859096],
            [23.928909301757812, 71.03482027758315],
            [23.862991333007812, 71.0234360670172],
            [23.913803100585938, 71.01539619117491],
            [23.859558105468746, 71.00623567007617],
            [23.827972412109375, 70.99572934779196],
            [23.860931396484375, 70.98230888827636],
            [23.915176391601562, 70.97917613473446],
            [23.88153076171875, 70.96305697947798],
            [23.872604370117188, 70.95006253926603],
            [23.93165588378906, 70.94333792623551],
            [23.911056518554688, 70.93885358116378],
            [23.926849365234375, 70.92472125233712],
            [24.000320434570312, 70.9114770670475],
            [24.055252075195312, 70.91170161850003],
            [24.125289916992188, 70.9206815915344],
            [24.189834594726562, 70.93302241360396],
            [24.23377990722656, 70.94759711315815],
            [24.231719970703125, 70.96507259301342],
            [24.204254150390625, 70.97380454387894],
            [24.0985107421875, 70.97872855794832],
            [24.093704223632812, 70.98409881006293],
            [24.21249389648437, 70.99170416788527],
            [24.228286743164062, 70.99662372073226],
            [24.204254150390625, 71.00265967789278],
            [24.144515991210938, 71.00645914806111],
            [24.064865112304688, 71.0098110139634],
            [24.082717895507812, 71.02008651736466],
            [24.068298339843746, 71.03705170423109],
            [24.092330932617188, 71.0435214114979],
            [24.135589599609375, 71.05355640322682],
            [24.158935546875, 71.05890630627417],
            [24.151382446289062, 71.06692843239841],
            [24.168548583984375, 71.07450187944697],
            [24.195327758789062, 71.08385328476666],
            [24.18708801269531, 71.09097517695425],
            [24.154815673828125, 71.08785966704426],
            [24.15069580078125, 71.09342273070885],
            [24.171295166015625, 71.10232035345231],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.694419860839844, 70.92528225120503],
            [23.666954040527344, 70.91843697965116],
            [23.66558074951172, 70.91810026594166],
            [23.67244720458984, 70.91405925521711],
            [23.702316284179688, 70.91349793857097],
            [23.722572326660156, 70.91607986355467],
            [23.714332580566406, 70.92303816042089],
            [23.694419860839844, 70.92528225120503],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.721885681152344, 70.88080191744505],
            [23.694419860839844, 70.87911509509992],
            [23.69373321533203, 70.87540358166484],
            [23.71021270751953, 70.87124136315516],
            [23.727035522460934, 70.87045381833008],
            [23.73149871826172, 70.87495365412772],
            [23.721885681152344, 70.88080191744505],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.188751220703125, 70.31596324851904],
            [20.153045654296875, 70.31272500518486],
            [20.153045654296875, 70.29513704138317],
            [20.17913818359375, 70.27289909905063],
            [20.19012451171875, 70.2320669391578],
            [20.213470458984375, 70.20836572183717],
            [20.269775390625, 70.2320669391578],
            [20.3082275390625, 70.26269865728662],
            [20.260162353515625, 70.27938765363129],
            [20.188751220703125, 70.31596324851904],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.1654052734375, 70.17613951744353],
            [20.153732299804688, 70.16961835516412],
            [20.152015686035156, 70.16053187669242],
            [20.159912109375, 70.15027566806857],
            [20.181541442871094, 70.14945961485543],
            [20.181884765625, 70.15622022693205],
            [20.19390106201172, 70.1639106493335],
            [20.18016815185547, 70.16880306513174],
            [20.1654052734375, 70.17613951744353],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.784706115722656, 69.27243774519626],
            [16.77783966064453, 69.26891365615715],
            [16.776466369628906, 69.26611828171123],
            [16.7816162109375, 69.26125590266729],
            [16.795692443847656, 69.26125590266729],
            [16.80736541748047, 69.2644165730253],
            [16.8035888671875, 69.26769832023527],
            [16.79637908935547, 69.27195169800012],
            [16.784706115722656, 69.27243774519626],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.091140747070312, 67.54085514747183],
            [12.080154418945312, 67.53613299878126],
            [12.057495117187498, 67.53114748714407],
            [12.0245361328125, 67.52616092637027],
            [12.028656005859375, 67.53744480117909],
            [12.003936767578123, 67.53245956560008],
            [11.984710693359375, 67.52117331627686],
            [11.952438354492188, 67.51145758984957],
            [11.951751708984375, 67.50515336831654],
            [11.982650756835938, 67.50725496164158],
            [11.99981689453125, 67.5025261148633],
            [12.00531005859375, 67.49306559350207],
            [11.957244873046875, 67.49017412644746],
            [11.935958862304688, 67.48439013584269],
            [11.913986206054688, 67.47465933783764],
            [11.885147094726562, 67.47281792765632],
            [11.868667602539062, 67.45939763158853],
            [11.854934692382812, 67.44833993319008],
            [11.845321655273438, 67.43042609144851],
            [11.850128173828125, 67.40880616908454],
            [11.863861083984375, 67.40379387501285],
            [11.90162658691406, 67.40168312014993],
            [11.926345825195312, 67.39983605638062],
            [11.94831848144531, 67.40194697472515],
            [11.955184936523436, 67.40827860880174],
            [11.93939208984375, 67.41091629347353],
            [11.908493041992188, 67.41144379538633],
            [11.920166015625, 67.41909126143587],
            [11.913299560546873, 67.42673627381271],
            [11.9146728515625, 67.43516930277653],
            [11.934585571289062, 67.44860327146488],
            [11.935272216796875, 67.43859436783208],
            [11.94831848144531, 67.43701362980661],
            [11.952438354492188, 67.45386942493548],
            [11.964797973632812, 67.46255602993374],
            [11.963424682617188, 67.4736071209205],
            [11.976470947265625, 67.4825494797483],
            [11.989517211914062, 67.47308099498981],
            [12.012176513671875, 67.46545085990093],
            [12.041015625, 67.46913467920297],
            [12.067794799804688, 67.4825494797483],
            [12.095260620117188, 67.48885970682863],
            [12.138519287109375, 67.49963580006221],
            [12.179718017578125, 67.50357705115235],
            [12.198257446289062, 67.5085683629386],
            [12.198944091796875, 67.51723498803166],
            [12.218856811523438, 67.51539687763677],
            [12.245635986328123, 67.51802270601479],
            [12.2332763671875, 67.52169837728502],
            [12.209243774414062, 67.524586004974],
            [12.205123901367188, 67.53298437663985],
            [12.172164916992188, 67.53744480117909],
            [12.13714599609375, 67.53245956560008],
            [12.119293212890625, 67.53665772845729],
            [12.091140747070312, 67.54085514747183],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.759933471679688, 67.70058861095265],
            [12.725601196289062, 67.70058861095265],
            [12.684402465820312, 67.69641951569903],
            [12.643890380859375, 67.69016448589902],
            [12.619857788085936, 67.67738863092336],
            [12.600631713867188, 67.66356203375796],
            [12.56561279296875, 67.65625413631477],
            [12.553253173828125, 67.64476571402739],
            [12.569046020507812, 67.63170388621468],
            [12.59033203125, 67.62673849352824],
            [12.616424560546875, 67.63170388621468],
            [12.61299133300781, 67.6437210341517],
            [12.610931396484375, 67.65233825815592],
            [12.628097534179688, 67.64972731099115],
            [12.658309936523438, 67.64607149874318],
            [12.682342529296875, 67.6463326470028],
            [12.680282592773436, 67.6351006577884],
            [12.699508666992188, 67.63457810939943],
            [12.731094360351562, 67.64528803659738],
            [12.73590087890625, 67.65651517171011],
            [12.713241577148438, 67.67373711172282],
            [12.742080688476562, 67.66851967253845],
            [12.752380371093748, 67.67947495888545],
            [12.73384094238281, 67.68234335771861],
            [12.737274169921875, 67.68833978866046],
            [12.782592773437498, 67.69329220885487],
            [12.777099609375, 67.69954640647754],
            [12.759933471679688, 67.70058861095265],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.668609619140625, 67.74249898753594],
            [12.646636962890625, 67.73937781299146],
            [12.6507568359375, 67.73495543790264],
            [12.66998291015625, 67.73729679916877],
            [12.668609619140625, 67.74249898753594],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.794952392578125, 67.79186225877564],
            [12.781219482421875, 67.78433459163759],
            [12.765426635742188, 67.77862233176488],
            [12.748947143554688, 67.77057087156025],
            [12.740020751953125, 67.75731889478399],
            [12.729034423828125, 67.7497801128267],
            [12.732467651367188, 67.73911769636123],
            [12.731781005859375, 67.7313128558207],
            [12.728347778320312, 67.72792995172128],
            [12.742080688476562, 67.7253273858978],
            [12.755813598632812, 67.73209345673678],
            [12.772979736328125, 67.7365163713808],
            [12.775039672851562, 67.75108007583425],
            [12.775039672851562, 67.75913823747705],
            [12.77435302734375, 67.76745343383867],
            [12.783966064453125, 67.77784327920833],
            [12.825851440429688, 67.77784327920833],
            [12.836151123046873, 67.78251720563682],
            [12.812805175781248, 67.78719019877417],
            [12.794952392578125, 67.79186225877564],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.6722412109375, 67.62072635600519],
            [14.651641845703123, 67.6113129769797],
            [14.636535644531248, 67.59823260840342],
            [14.648895263671875, 67.58671588827491],
            [14.62554931640625, 67.57519355269959],
            [14.578857421875, 67.56523792391518],
            [14.569244384765625, 67.54741212580376],
            [14.534912109374998, 67.54269128441497],
            [14.552764892578123, 67.5274732809326],
            [14.5733642578125, 67.51172022939176],
            [14.618682861328123, 67.51539687763677],
            [14.706573486328123, 67.55003440881843],
            [14.7271728515625, 67.57257405668763],
            [14.761505126953127, 67.59509224150611],
            [14.766998291015623, 67.6113129769797],
            [14.6722412109375, 67.62072635600519],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.38385009765625, 67.45386942493548],
            [14.35089111328125, 67.44544301979771],
            [14.293212890625, 67.42752699714492],
            [14.21356201171875, 67.4064320558728],
            [14.1668701171875, 67.38056529323012],
            [14.1778564453125, 67.36788562321442],
            [14.228668212890625, 67.36207185745398],
            [14.313812255859375, 67.36524317779612],
            [14.386596679687498, 67.37581120592827],
            [14.427795410156248, 67.39693323856477],
            [14.451141357421873, 67.41961858243559],
            [14.423675537109373, 67.43701362980661],
            [14.38385009765625, 67.45386942493548],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.07073974609375, 67.47071328415],
            [14.024047851562498, 67.46387190543228],
            [14.017181396484375, 67.43701362980661],
            [13.986968994140625, 67.44544301979771],
            [13.941650390625, 67.44017500089629],
            [13.923797607421873, 67.44965659541857],
            [13.888092041015625, 67.44912993927076],
            [13.8592529296875, 67.42963546436422],
            [13.808441162109375, 67.40168312014993],
            [13.82080078125, 67.38901466691702],
            [13.86749267578125, 67.37845248240826],
            [13.929290771484375, 67.37845248240826],
            [14.008941650390625, 67.39323823219159],
            [14.072113037109375, 67.41118004588911],
            [14.116058349609375, 67.44702319809653],
            [14.12017822265625, 67.4675559694062],
            [14.07073974609375, 67.47071328415],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.20806884765625, 67.33456944266271],
            [14.13116455078125, 67.33298179955719],
            [14.096832275390625, 67.31868827035339],
            [14.073486328125, 67.33139405110586],
            [13.99932861328125, 67.32768889500885],
            [14.00482177734375, 67.29802698852417],
            [13.932037353515625, 67.2911359341183],
            [13.89495849609375, 67.2635519016556],
            [13.919677734375, 67.25027905633492],
            [13.910064697265623, 67.21307604845414],
            [13.932037353515625, 67.20882061043957],
            [14.00482177734375, 67.21892604751504],
            [14.01580810546875, 67.24231182782398],
            [14.073486328125, 67.26249034393543],
            [14.07073974609375, 67.28530349464647],
            [14.099578857421875, 67.29961695057077],
            [14.170989990234375, 67.29378657407291],
            [14.219055175781248, 67.31709957353006],
            [14.20806884765625, 67.33456944266271],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.787841796875, 67.20083963631744],
            [13.720550537109375, 67.2003074773173],
            [13.66973876953125, 67.18220707357813],
            [13.69171142578125, 67.16995497083367],
            [13.662872314453125, 67.15503096293045],
            [13.68072509765625, 67.13636296865674],
            [13.69720458984375, 67.13049290376796],
            [13.723297119140625, 67.1331612918625],
            [13.74114990234375, 67.14383189813256],
            [13.800201416015625, 67.15023199979028],
            [13.827667236328125, 67.16835641147249],
            [13.835906982421875, 67.19072660439629],
            [13.787841796875, 67.20083963631744],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.638397216796873, 66.8011077279093],
            [12.59307861328125, 66.79623849287657],
            [12.5738525390625, 66.76700280165471],
            [12.586212158203125, 66.7529139511982],
            [12.545013427734375, 66.73122303857558],
            [12.5299072265625, 66.74478209787686],
            [12.50518798828125, 66.7848731894641],
            [12.47222900390625, 66.78974467757949],
            [12.429656982421875, 66.77025292928913],
            [12.437896728515625, 66.74695085474931],
            [12.448883056640625, 66.71819931772863],
            [12.48321533203125, 66.70679791540431],
            [12.54638671875, 66.7116848761489],
            [12.602691650390625, 66.70299627645545],
            [12.635650634765625, 66.71765651333071],
            [12.663116455078125, 66.74803516154893],
            [12.663116455078125, 66.77404420204743],
            [12.63427734375, 66.77837636948965],
            [12.638397216796873, 66.8011077279093],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.709808349609375, 66.84056697782336],
            [12.691955566406248, 66.81787214936938],
            [12.707061767578125, 66.79515630953492],
            [12.729034423828125, 66.78000073548141],
            [12.761993408203125, 66.76862791916014],
            [12.794952392578125, 66.77837636948965],
            [12.79083251953125, 66.79515630953492],
            [12.77984619140625, 66.81138405412948],
            [12.755126953125, 66.83084319457534],
            [12.709808349609375, 66.84056697782336],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.547760009765623, 66.67419342238554],
            [12.51617431640625, 66.66766735507802],
            [12.536773681640625, 66.6551542409239],
            [12.555999755859375, 66.65461004879496],
            [12.57659912109375, 66.6589632505238],
            [12.586212158203125, 66.67256206714144],
            [12.547760009765623, 66.67419342238554],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.281341552734375, 66.66657950961509],
            [12.259368896484375, 66.65733088968656],
            [12.234649658203125, 66.64426812270932],
            [12.208557128906248, 66.64535695013917],
            [12.178344726562498, 66.64209032409008],
            [12.138519287109375, 66.63555577803261],
            [12.1453857421875, 66.62084673965843],
            [12.14263916015625, 66.60340248729861],
            [12.10693359375, 66.58157989692565],
            [12.108306884765625, 66.59849407838178],
            [12.064361572265623, 66.60231181373835],
            [12.0135498046875, 66.58649165389609],
            [12.0355224609375, 66.56956928604149],
            [12.03826904296875, 66.54880999362992],
            [11.987457275390625, 66.53897058856793],
            [12.0025634765625, 66.51654406872363],
            [11.986083984375, 66.49519275886493],
            [11.949005126953125, 66.48697584176404],
            [11.927032470703125, 66.47491946221632],
            [11.938018798828125, 66.45792103082198],
            [11.979217529296875, 66.44585060139235],
            [11.95037841796875, 66.43047979598182],
            [11.973724365234373, 66.42498793076327],
            [12.012176513671875, 66.44145990752467],
            [12.061614990234375, 66.46779249754776],
            [12.122039794921875, 66.47930427470719],
            [12.174224853515625, 66.51271313006828],
            [12.18109130859375, 66.53733030908982],
            [12.196197509765625, 66.54498402106493],
            [12.218170166015625, 66.52639234726222],
            [12.273101806640625, 66.53514310147867],
            [12.297821044921875, 66.55591380905055],
            [12.293701171875, 66.56902321109457],
            [12.2607421875, 66.56520035019051],
            [12.204437255859375, 66.55427464726601],
            [12.160491943359375, 66.55973809938138],
            [12.179718017578125, 66.5712074388279],
            [12.2442626953125, 66.57066139990793],
            [12.270355224609375, 66.58594595113465],
            [12.29644775390625, 66.6072194668998],
            [12.311553955078125, 66.6328325412419],
            [12.300567626953125, 66.66277167339632],
            [12.281341552734375, 66.66657950961509],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.28546142578125, 66.26934342514457],
            [12.25799560546875, 66.26657995350102],
            [12.242889404296875, 66.25939350823785],
            [12.218856811523438, 66.26243417754857],
            [12.198257446289062, 66.25718188190115],
            [12.178344726562498, 66.25856417110671],
            [12.154312133789062, 66.25469357026255],
            [12.12615966796875, 66.25718188190115],
            [12.116546630859375, 66.25801126452266],
            [12.110366821289062, 66.25358757512083],
            [12.119293212890625, 66.24916310923315],
            [12.10968017578125, 66.2441846568294],
            [12.1234130859375, 66.24086514243906],
            [12.135772705078125, 66.23948188248453],
            [12.128219604492188, 66.23173422469745],
            [12.124786376953123, 66.22536829707121],
            [12.115859985351562, 66.21733940357531],
            [12.126846313476562, 66.21096984456545],
            [12.147445678710938, 66.21096984456545],
            [12.154998779296873, 66.20404458848022],
            [12.179031372070312, 66.20570682308808],
            [12.19757080078125, 66.20321343016431],
            [12.22023010253906, 66.20321343016431],
            [12.238769531249998, 66.20819996995084],
            [12.23602294921875, 66.21844698897935],
            [12.259368896484375, 66.22038514653003],
            [12.294387817382812, 66.22592191974414],
            [12.297821044921875, 66.21927764614843],
            [12.333526611328125, 66.22093887850254],
            [12.353439331054688, 66.22868985096306],
            [12.355499267578125, 66.23837521989975],
            [12.334213256835936, 66.24114178532672],
            [12.303314208984375, 66.23837521989975],
            [12.289581298828125, 66.24307820057989],
            [12.319107055664062, 66.24695058507275],
            [12.328033447265625, 66.25552303477181],
            [12.321853637695312, 66.25856417110671],
            [12.311553955078125, 66.26243417754857],
            [12.304000854492186, 66.26796172721971],
            [12.28546142578125, 66.26934342514457],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.1014404296875, 66.20903096422826],
            [12.081527709960938, 66.20736894833549],
            [12.080841064453123, 66.20016561573868],
            [12.088394165039062, 66.19711743367556],
            [12.106246948242186, 66.20210517654384],
            [12.1014404296875, 66.20903096422826],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.01904296875, 66.17216394850118],
            [11.997070312499998, 66.16883495645689],
            [11.942138671875, 66.15912289464049],
            [11.940078735351562, 66.14801883190701],
            [11.940765380859375, 66.13774323863349],
            [11.920852661132812, 66.12607400099888],
            [11.935958862304688, 66.11606752151663],
            [11.967544555664062, 66.11995939928984],
            [11.966171264648438, 66.11134086718609],
            [11.999130249023438, 66.10995050716151],
            [12.000503540039062, 66.10355386984709],
            [12.0135498046875, 66.09437326974829],
            [12.052001953125, 66.09437326974829],
            [12.074661254882812, 66.1043883052676],
            [12.073974609375, 66.11801353503687],
            [12.056121826171875, 66.13135360715437],
            [12.014236450195312, 66.15856780712544],
            [12.01904296875, 66.17216394850118],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.061614990234375, 66.08880765401777],
            [12.033462524414062, 66.08212730520364],
            [12.01904296875, 66.07878647207757],
            [11.98883056640625, 66.0643044526452],
            [11.965484619140625, 66.04897795312104],
            [11.96205139160156, 66.05399491482679],
            [11.963424682617188, 66.06319010943609],
            [11.949005126953125, 66.07349592136846],
            [11.929092407226562, 66.07377439885927],
            [11.894760131835936, 66.06931839292388],
            [11.872787475585938, 66.05789631239824],
            [11.84120178222656, 66.05873224833331],
            [11.808929443359373, 66.0531588232267],
            [11.759490966796875, 66.05009291897652],
            [11.7608642578125, 66.04005646764742],
            [11.746444702148438, 66.03587344492607],
            [11.751937866210938, 66.0261103860055],
            [11.765670776367188, 66.02136697852058],
            [11.79931640625, 66.01997169062089],
            [11.808929443359373, 66.02862124427458],
            [11.842575073242188, 66.035315656644],
            [11.885147094726562, 66.04200830977042],
            [11.915359497070312, 66.04005646764742],
            [11.933212280273438, 66.03336330144677],
            [11.933212280273438, 66.02359928027111],
            [11.97235107421875, 66.02192507229115],
            [12.014923095703125, 66.02945814204206],
            [12.05268859863281, 66.03726786218338],
            [12.06573486328125, 66.04869920402491],
            [12.069854736328125, 66.0598467868524],
            [12.054061889648438, 66.06820426940564],
            [12.075347900390625, 66.08045694353868],
            [12.061614990234375, 66.08880765401777],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.699752807617188, 66.0386622030894],
            [11.682586669921875, 66.03475785614496],
            [11.680526733398438, 66.02276219003066],
            [11.6619873046875, 66.017459979889],
            [11.65924072265625, 66.00350152329793],
            [11.6839599609375, 66.00685224981795],
            [11.700439453125, 66.01494802162442],
            [11.718292236328123, 66.03001605861294],
            [11.714859008789062, 66.03726786218338],
            [11.699752807617188, 66.0386622030894],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.07122802734375, 66.01885540529838],
            [12.057495117187498, 66.01773907108269],
            [12.056121826171875, 66.01048170679036],
            [12.0465087890625, 66.00852744799819],
            [12.011489868164062, 66.00768986266412],
            [11.997070312499998, 66.00433924619972],
            [11.992950439453125, 65.99484344198352],
            [11.999130249023438, 65.9923292550444],
            [12.015609741210936, 65.99568144924922],
            [12.034149169921873, 65.99344670204438],
            [12.065048217773438, 65.99484344198352],
            [12.095260620117188, 66.0007089148968],
            [12.089080810546875, 66.0093650058209],
            [12.07122802734375, 66.01885540529838],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.90093994140625, 65.96605515996286],
            [11.88789367675781, 65.96549584289609],
            [11.878280639648438, 65.95878308301693],
            [11.879653930664062, 65.95766411826439],
            [11.898193359375, 65.9543069300765],
            [11.910552978515625, 65.96046143829835],
            [11.90093994140625, 65.96605515996286],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.047882080078125, 65.96381781823217],
            [12.029685974121094, 65.96311860876486],
            [12.011146545410156, 65.95934254702348],
            [11.997070312499998, 65.94689157741335],
            [12.003936767578123, 65.94409277220296],
            [12.01904296875, 65.94381287483587],
            [12.031059265136719, 65.94731137177814],
            [12.031402587890625, 65.95262817063613],
            [12.046852111816406, 65.95486649206015],
            [12.051658630371092, 65.96046143829835],
            [12.047882080078125, 65.96381781823217],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.63177490234375, 65.81600074449591],
            [11.613922119140625, 65.81178094510764],
            [11.591949462890625, 65.80193205517642],
            [11.602249145507812, 65.79292405772546],
            [11.613235473632812, 65.78306795216272],
            [11.606369018554688, 65.77574383263962],
            [11.581649780273438, 65.78588436707284],
            [11.558303833007812, 65.78475783804785],
            [11.548004150390625, 65.7703902751633],
            [11.589202880859375, 65.76475375045462],
            [11.624221801757812, 65.77095385987933],
            [11.643447875976562, 65.75855215024134],
            [11.622848510742186, 65.7450162101188],
            [11.587829589843748, 65.74022052866235],
            [11.558303833007812, 65.74642653514779],
            [11.53289794921875, 65.73852772252303],
            [11.55487060546875, 65.73147316902121],
            [11.618728637695312, 65.73683480540873],
            [11.642074584960938, 65.7320376042119],
            [11.679153442382812, 65.73570613234362],
            [11.694946289062498, 65.72780403964956],
            [11.739578247070312, 65.72215820760393],
            [11.75262451171875, 65.72582813875098],
            [11.772537231445312, 65.73542395637014],
            [11.77734375, 65.75545079099967],
            [11.792449951171873, 65.7785610479888],
            [11.75056457519531, 65.78701084684776],
            [11.7059326171875, 65.78475783804785],
            [11.67640686035156, 65.77010847818592],
            [11.6400146484375, 65.77884275258972],
            [11.62353515625, 65.79123470739974],
            [11.64276123046875, 65.80643487243297],
            [11.63177490234375, 65.81600074449591],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.587829589843748, 65.35767693905089],
            [11.587829589843748, 65.33418998055545],
            [11.605682373046875, 65.31354998411923],
            [11.597442626953125, 65.2819853943789],
            [11.568603515625, 65.27853969723813],
            [11.528778076171875, 65.26992348490855],
            [11.513671874999998, 65.26015504227259],
            [11.487579345703125, 65.2394569918166],
            [11.527404785156248, 65.23830662451157],
            [11.578216552734375, 65.24003215669116],
            [11.646881103515625, 65.23370465451454],
            [11.715545654296875, 65.2394569918166],
            [11.725158691406248, 65.2595803154126],
            [11.682586669921875, 65.26992348490855],
            [11.663360595703125, 65.28657895709065],
            [11.657867431640625, 65.31182925383723],
            [11.635894775390625, 65.33705536697542],
            [11.587829589843748, 65.35767693905089],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.33514404296875, 65.24233269100807],
            [11.332397460937498, 65.23888181442346],
            [11.33514404296875, 65.22795106514162],
            [11.380462646484375, 65.21586445101364],
            [11.355743408203125, 65.18936970312997],
            [11.385955810546875, 65.17899497280054],
            [11.443634033203125, 65.18475921343575],
            [11.5081787109375, 65.1910984298952],
            [11.5850830078125, 65.20492418265141],
            [11.546630859375, 65.21068278245275],
            [11.498565673828125, 65.22046952675817],
            [11.429901123046873, 65.22392280748355],
            [11.40106201171875, 65.23715620713054],
            [11.33514404296875, 65.24233269100807],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.523773193359375, 63.85640551368109],
            [9.47021484375, 63.84309010368525],
            [9.44000244140625, 63.84490621255253],
            [9.37957763671875, 63.8309797153825],
            [9.3438720703125, 63.80128723588716],
            [9.350738525390625, 63.76792165006215],
            [9.3768310546875, 63.7648864579279],
            [9.427642822265625, 63.77823886301477],
            [9.5086669921875, 63.80492473122039],
            [9.55535888671875, 63.838852060469634],
            [9.548492431640625, 63.854590147062645],
            [9.523773193359375, 63.85640551368109],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.444122314453125, 64.18485667699767],
            [9.371337890625, 64.17947392291651],
            [9.331512451171875, 64.16691009798286],
            [9.23675537109375, 64.14895190024562],
            [9.1680908203125, 64.13397785589844],
            [9.11041259765625, 64.10820360663902],
            [9.0911865234375, 64.06018788359559],
            [9.093933105468748, 64.03915495409196],
            [9.0692138671875, 64.01269103995374],
            [9.023895263671875, 64.00005168835195],
            [8.96759033203125, 63.98921340638874],
            [8.955230712890625, 63.95426145517737],
            [8.974456787109375, 63.94280218147517],
            [9.01702880859375, 63.93737246791484],
            [9.0911865234375, 63.95184936610536],
            [9.18182373046875, 63.98078072569385],
            [9.217529296875, 64.00607114037994],
            [9.232635498046875, 64.02652757938436],
            [9.25048828125, 64.04937292998538],
            [9.28619384765625, 64.0679960744063],
            [9.326019287109375, 64.09200755738213],
            [9.364471435546875, 64.12139341773941],
            [9.397430419921875, 64.0986070859802],
            [9.424896240234375, 64.11419975101958],
            [9.36309814453125, 64.14236431546863],
            [9.393310546875, 64.15613657395566],
            [9.46746826171875, 64.17708125224304],
            [9.444122314453125, 64.18485667699767],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.776702880859375, 63.967524231698135],
            [8.74786376953125, 63.94400864160247],
            [8.749237060546875, 63.933752074286225],
            [8.804168701171875, 63.913831547524325],
            [8.881072998046875, 63.896918181239144],
            [8.952484130859375, 63.912019888695205],
            [8.94012451171875, 63.93918248934688],
            [8.9044189453125, 63.961496475877624],
            [8.776702880859375, 63.967524231698135],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.5753173828125, 62.883013899956964],
            [6.557807922363281, 62.87941441992292],
            [6.561241149902344, 62.874875315725646],
            [6.573600769042969, 62.869552713280626],
            [6.562271118164062, 62.86908302555275],
            [6.544761657714843, 62.86908302555275],
            [6.528282165527344, 62.865012083803244],
            [6.5224456787109375, 62.85921784733075],
            [6.5224456787109375, 62.85122932299727],
            [6.52313232421875, 62.84856599877379],
            [6.534461975097656, 62.84872267158725],
            [6.553001403808594, 62.85044601740287],
            [6.562614440917969, 62.85561544843121],
            [6.56982421875, 62.86297640125806],
            [6.5856170654296875, 62.8617236033943],
            [6.594886779785156, 62.86704762523241],
            [6.594200134277344, 62.87377956470325],
            [6.5869903564453125, 62.88254442760091],
            [6.5753173828125, 62.883013899956964],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.939483642578125, 62.55649643539607],
            [5.933475494384766, 62.55586351488041],
            [5.933818817138672, 62.55293608236757],
            [5.92935562133789, 62.55135356650229],
            [5.933647155761719, 62.547871735374606],
            [5.936737060546875, 62.54549752606263],
            [5.945148468017578, 62.547476046972974],
            [5.949954986572266, 62.551195310287376],
            [5.949611663818359, 62.55333169818533],
            [5.943603515625, 62.553806430224355],
            [5.943431854248047, 62.55617997682112],
            [5.939483642578125, 62.55649643539607],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.763359069824219, 62.434490281126614],
            [5.756492614746094, 62.433219238996564],
            [5.753574371337891, 62.42932633646283],
            [5.7492828369140625, 62.42614808124586],
            [5.735378265380859, 62.42193637300198],
            [5.724735260009766, 62.415736931659346],
            [5.7245635986328125, 62.414544584178266],
            [5.728340148925781, 62.41502152886995],
            [5.739498138427734, 62.41788303744341],
            [5.745849609375, 62.420187942637845],
            [5.756492614746094, 62.42408203429495],
            [5.764904022216797, 62.42797561924979],
            [5.765247344970703, 62.42972359462565],
            [5.766792297363281, 62.43226592195261],
            [5.763359069824219, 62.434490281126614],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.26468276977539, 62.33255638126728],
            [5.261936187744141, 62.33072301715461],
            [5.262622833251953, 62.33048387445612],
            [5.262279510498046, 62.32825178413707],
            [5.263824462890625, 62.32705595324885],
            [5.2679443359375, 62.32649788254627],
            [5.271034240722655, 62.32721540011721],
            [5.269660949707031, 62.33016501456356],
            [5.267086029052734, 62.33239696274268],
            [5.26468276977539, 62.33255638126728],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.8635101318359375, 61.907036890189424],
            [4.860935211181641, 61.90671354468149],
            [4.856300354003906, 61.90493508328119],
            [4.848918914794922, 61.90364159185404],
            [4.8470306396484375, 61.9003267703918],
            [4.847888946533203, 61.8995182228626],
            [4.850292205810547, 61.89943736693439],
            [4.850292205810547, 61.90162040199139],
            [4.853382110595703, 61.901377850233324],
            [4.860591888427734, 61.900892740947725],
            [4.8635101318359375, 61.901943801343926],
            [4.8635101318359375, 61.903803281273824],
            [4.864711761474609, 61.90558180848272],
            [4.8635101318359375, 61.907036890189424],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.772615432739258, 61.677576940269965],
            [4.770598411560058, 61.67749549838289],
            [4.769911766052246, 61.67668106769855],
            [4.768624305725098, 61.676151676235165],
            [4.766349792480469, 61.675540828654135],
            [4.765877723693848, 61.67515395227001],
            [4.767637252807617, 61.674644897012165],
            [4.770855903625488, 61.67507250399262],
            [4.7742462158203125, 61.675744445857],
            [4.774632453918457, 61.676436734304694],
            [4.774074554443359, 61.677414056281044],
            [4.772615432739258, 61.677576940269965],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.691505432128906, 61.576587287999764],
            [4.685325622558593, 61.57642387156223],
            [4.6808624267578125, 61.57429937950649],
            [4.67193603515625, 61.57274677403946],
            [4.667816162109374, 61.568742327541585],
            [4.665069580078125, 61.56743464120539],
            [4.667987823486328, 61.566126899742976],
            [4.678287506103516, 61.56776156795746],
            [4.686698913574219, 61.56898751259265],
            [4.689960479736328, 61.57062203007457],
            [4.697856903076172, 61.57086720026821],
            [4.699058532714844, 61.57282849205443],
            [4.69390869140625, 61.57372737600888],
            [4.691505432128906, 61.576587287999764],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.6836090087890625, 61.59921214681657],
            [4.679489135742187, 61.598722252645295],
            [4.678287506103516, 61.5951294586821],
            [4.674167633056641, 61.59610935289829],
            [4.669361114501953, 61.59586438224941],
            [4.663352966308594, 61.595292776536795],
            [4.663352966308594, 61.59145457937066],
            [4.664897918701171, 61.590882892308855],
            [4.6685028076171875, 61.59129124128624],
            [4.675540924072266, 61.593496232790514],
            [4.6753692626953125, 61.59120957192119],
            [4.682922363281249, 61.59227125687967],
            [4.6836090087890625, 61.59921214681657],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.733390808105469, 61.60394739110965],
            [4.729270935058594, 61.603212659266994],
            [4.730815887451172, 61.59978368022568],
            [4.725494384765625, 61.59994697354883],
            [4.723949432373047, 61.598967200699256],
            [4.725666046142578, 61.598232350727415],
            [4.730987548828125, 61.59749748332555],
            [4.736824035644531, 61.599865326994845],
            [4.736309051513672, 61.602477909996296],
            [4.733390808105469, 61.60394739110965],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.116539001464844, 61.51467261396376],
            [5.084266662597656, 61.513362648857736],
            [5.075340270996094, 61.50926865215101],
            [5.061607360839844, 61.5040275497712],
            [5.047874450683593, 61.506156854136876],
            [5.01422882080078, 61.50648442648552],
            [4.9925994873046875, 61.49911321408364],
            [4.986419677734375, 61.48272648718127],
            [4.998435974121094, 61.48174300916309],
            [5.022125244140625, 61.48239866462726],
            [5.033454895019531, 61.485840629278194],
            [5.044097900390625, 61.49714726241663],
            [5.047874450683593, 61.48846282310266],
            [5.046844482421875, 61.4810873398905],
            [5.041351318359375, 61.47452988765718],
            [5.054740905761719, 61.4710866723897],
            [5.085639953613281, 61.47043077870178],
            [5.112419128417969, 61.47354615071025],
            [5.1251220703125, 61.47993988543638],
            [5.128211975097656, 61.48846282310266],
            [5.1580810546875, 61.48289039716377],
            [5.1738739013671875, 61.48567673482927],
            [5.168724060058593, 61.494034251536036],
            [5.15705108642578, 61.50025996185378],
            [5.12683868408203, 61.50599306666875],
            [5.116539001464844, 61.51467261396376],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.936981201171875, 61.560159629753315],
            [4.9088287353515625, 61.55672588580897],
            [4.902305603027344, 61.5511656852822],
            [4.9156951904296875, 61.54805808055853],
            [4.9156951904296875, 61.54167833832986],
            [4.91535186767578, 61.53251539024588],
            [4.9115753173828125, 61.52400451787948],
            [4.897499084472656, 61.520730484672335],
            [4.902992248535156, 61.51663745813753],
            [4.917411804199219, 61.51287139771335],
            [4.9308013916015625, 61.50517411638972],
            [4.921188354492187, 61.50353615113874],
            [4.907112121582031, 61.50058759630978],
            [4.906425476074219, 61.49272341693308],
            [4.9005889892578125, 61.49583655895436],
            [4.890289306640625, 61.494034251536036],
            [4.892692565917969, 61.48764341126273],
            [4.906768798828125, 61.49108479602625],
            [4.922218322753906, 61.48944608883512],
            [4.934577941894531, 61.48354602846374],
            [4.9486541748046875, 61.49010158206589],
            [4.959297180175781, 61.48338212193324],
            [4.973030090332031, 61.48518504630479],
            [4.9692535400390625, 61.49370654806208],
            [4.974403381347656, 61.50238952413859],
            [4.998779296875, 61.50894110912796],
            [4.995689392089844, 61.519911922480134],
            [4.9898529052734375, 61.52416821048652],
            [4.972686767578125, 61.52236754438744],
            [4.962730407714844, 61.5284239156487],
            [4.933891296386719, 61.5423327311926],
            [4.941444396972656, 61.54560448866883],
            [4.936637878417969, 61.55263760000101],
            [4.9417877197265625, 61.5562353199444],
            [4.948997497558594, 61.552146969516244],
            [4.962730407714844, 61.55476357582573],
            [4.962730407714844, 61.549530142625066],
            [4.979209899902344, 61.54609522255839],
            [4.993629455566406, 61.54969370076838],
            [4.9939727783203125, 61.55378237430826],
            [4.981956481933594, 61.55803402353954],
            [4.964447021484375, 61.558688071727936],
            [4.936981201171875, 61.560159629753315],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.8374176025390625, 61.535460918346544],
            [4.8374176025390625, 61.53464274411132],
            [4.8250579833984375, 61.53267903802302],
            [4.832267761230469, 61.52956958284684],
            [4.843254089355469, 61.52105790351337],
            [4.865570068359375, 61.514017638309895],
            [4.883079528808594, 61.51630999273216],
            [4.8834228515625, 61.52154902530798],
            [4.874153137207031, 61.520730484672335],
            [4.8635101318359375, 61.52826024545771],
            [4.8374176025390625, 61.535460918346544],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.7900390625, 61.401320700080774],
            [4.771156311035156, 61.39622578000728],
            [4.760856628417968, 61.388992853783115],
            [4.763603210449219, 61.38586902714527],
            [4.771156311035156, 61.38701994696781],
            [4.7845458984375, 61.390308055806365],
            [4.7955322265625, 61.39852681454603],
            [4.7900390625, 61.401320700080774],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.697685241699219, 61.403785685696775],
            [4.68841552734375, 61.40082767961389],
            [4.669532775878906, 61.39639014523758],
            [4.675712585449219, 61.39326705799628],
            [4.696998596191406, 61.39671887310411],
            [4.709701538085937, 61.40279971479368],
            [4.697685241699219, 61.403785685696775],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.682922363281249, 61.364487098826224],
            [4.6685028076171875, 61.36399349710903],
            [4.6671295166015625, 61.357246826420266],
            [4.645500183105469, 61.35395523963718],
            [4.6520233154296875, 61.35000487850423],
            [4.66644287109375, 61.346712530035774],
            [4.6767425537109375, 61.348523364541656],
            [4.699745178222656, 61.35658853675885],
            [4.700431823730468, 61.36284172948262],
            [4.682922363281249, 61.364487098826224],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.6904754638671875, 61.19505520138293],
            [4.681205749511719, 61.190588464792924],
            [4.681549072265625, 61.18396991321753],
            [4.676055908203125, 61.180163616632015],
            [4.665069580078125, 61.17635686041473],
            [4.664382934570312, 61.17221856055096],
            [4.6822357177734375, 61.17288072502678],
            [4.692192077636718, 61.17006642997656],
            [4.703178405761719, 61.1715563821708],
            [4.706268310546875, 61.16874196892992],
            [4.713478088378906, 61.16576172820577],
            [4.713478088378906, 61.16245001934712],
            [4.706268310546875, 61.15830989434055],
            [4.71038818359375, 61.153341027164906],
            [4.723091125488281, 61.152181512241825],
            [4.7357940673828125, 61.15632244135829],
            [4.730644226074218, 61.16029722214517],
            [4.7193145751953125, 61.16228442477772],
            [4.724807739257812, 61.16708631440347],
            [4.7193145751953125, 61.17288072502678],
            [4.710044860839844, 61.174370544260675],
            [4.704551696777344, 61.180163616632015],
            [4.694595336914062, 61.1851282600834],
            [4.7000885009765625, 61.18976122185868],
            [4.693565368652344, 61.19455892858748],
            [4.6904754638671875, 61.19505520138293],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.648590087890625, 61.160959636930436],
            [4.653053283691406, 61.15234715983904],
            [4.665069580078125, 61.153341027164906],
            [4.668846130371094, 61.15797866086986],
            [4.648590087890625, 61.160959636930436],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.652709960937499, 61.17635686041473],
            [4.642753601074219, 61.17586029310715],
            [4.646186828613281, 61.1715563821708],
            [4.6547698974609375, 61.17321180205064],
            [4.652709960937499, 61.17635686041473],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.6417236328125, 60.84608136071791],
            [4.638633728027344, 60.8454123447872],
            [4.63348388671875, 60.84072884115212],
            [4.654083251953125, 60.841732506843435],
            [4.655799865722656, 60.84491057364912],
            [4.6417236328125, 60.84608136071791],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.631767272949218, 60.83169443181176],
            [4.630393981933594, 60.83135977503338],
            [4.633140563964844, 60.82717626981158],
            [4.6424102783203125, 60.82416380720262],
            [4.652366638183594, 60.82014674906317],
            [4.665069580078125, 60.81897501209224],
            [4.672966003417969, 60.82315958995422],
            [4.664039611816406, 60.82834770643355],
            [4.648590087890625, 60.831025114752975],
            [4.631767272949218, 60.83169443181176],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.671592712402344, 60.80407347192682],
            [4.6602630615234375, 60.801896261714916],
            [4.656486511230469, 60.79720638300482],
            [4.658203125, 60.791678144102285],
            [4.664382934570312, 60.7856462497142],
            [4.667816162109374, 60.7745848267072],
            [4.681205749511719, 60.774249572468],
            [4.68017578125, 60.7672084234438],
            [4.68841552734375, 60.76335190079159],
            [4.6987152099609375, 60.7583209568022],
            [4.698371887207031, 60.74993429634783],
            [4.722404479980468, 60.74909550971602],
            [4.740257263183594, 60.75177954975895],
            [4.7515869140625, 60.75999802581595],
            [4.746437072753906, 60.77072919125695],
            [4.741630554199219, 60.77709912173941],
            [4.7344207763671875, 60.784138098658296],
            [4.720344543457031, 60.787489449048884],
            [4.710731506347656, 60.791510606815564],
            [4.7055816650390625, 60.79737389050066],
            [4.699058532714844, 60.80038887560766],
            [4.683265686035156, 60.803571051941596],
            [4.671592712402344, 60.80407347192682],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.8896026611328125, 59.32443279994899],
            [4.8614501953125, 59.32057916932128],
            [4.8484039306640625, 59.30936612300601],
            [4.8497772216796875, 59.30410873444171],
            [4.860076904296875, 59.29253961867027],
            [4.849090576171874, 59.29043572038728],
            [4.8387908935546875, 59.282720313814714],
            [4.8504638671875, 59.27886195467415],
            [4.86968994140625, 59.28868237245869],
            [4.9019622802734375, 59.29113703426701],
            [4.91912841796875, 59.30130446156232],
            [4.915008544921875, 59.31322102479745],
            [4.90814208984375, 59.32198054010197],
            [4.8896026611328125, 59.32443279994899],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.022125244140625, 59.38288427166942],
            [5.016632080078125, 59.38218484937568],
            [5.008392333984374, 59.37518983291977],
            [5.01800537109375, 59.35979571744856],
            [5.0365447998046875, 59.35944577000877],
            [5.0434112548828125, 59.36854323080663],
            [5.022125244140625, 59.38288427166942],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.060234069824219, 59.23876177073111],
            [5.0530242919921875, 59.23770817593589],
            [5.052509307861328, 59.23489843067892],
            [5.054912567138672, 59.23112247097296],
            [5.063323974609375, 59.23103465298309],
            [5.064868927001953, 59.22725826554902],
            [5.072250366210937, 59.22576515986473],
            [5.0763702392578125, 59.22919042297236],
            [5.076885223388672, 59.23410814811867],
            [5.072078704833984, 59.23481062240978],
            [5.064697265625, 59.23410814811867],
            [5.060234069824219, 59.23876177073111],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.4986572265625, 59.09173509215263],
            [5.4856109619140625, 59.09120602940234],
            [5.4705047607421875, 59.085385800569334],
            [5.464668273925781, 59.0748010363665],
            [5.4457855224609375, 59.05291550981815],
            [5.450248718261719, 59.06491897735471],
            [5.447502136230469, 59.07850607519985],
            [5.4265594482421875, 59.08132869349038],
            [5.4004669189453125, 59.08027023883725],
            [5.384330749511719, 59.075683224746584],
            [5.374031066894531, 59.05821167437694],
            [5.377464294433594, 59.047265367559696],
            [5.3867340087890625, 59.04355695673248],
            [5.3936004638671875, 59.03490244176445],
            [5.392913818359375, 59.02677581190898],
            [5.4032135009765625, 59.03048603337356],
            [5.415229797363281, 59.041437685152516],
            [5.419349670410156, 59.02765923428139],
            [5.420036315917969, 59.020944655067716],
            [5.4114532470703125, 59.017586873865376],
            [5.392570495605469, 59.01599623153126],
            [5.3757476806640625, 59.022535068614175],
            [5.3668212890625, 59.0243021085329],
            [5.3647613525390625, 59.017056667925345],
            [5.360298156738281, 59.0089258202323],
            [5.369911193847656, 59.00574366115084],
            [5.387420654296875, 59.00344525216323],
            [5.409049987792969, 59.00503647473179],
            [5.424842834472656, 59.007865133229565],
            [5.42999267578125, 59.023418599921236],
            [5.42999267578125, 59.03596229536753],
            [5.42724609375, 59.049207708762474],
            [5.4402923583984375, 59.048501415574115],
            [5.4389190673828125, 59.03790527544584],
            [5.450248718261719, 59.03596229536753],
            [5.47119140625, 59.03967152567632],
            [5.473251342773437, 59.04956084991233],
            [5.4801177978515625, 59.059447328618155],
            [5.49591064453125, 59.07021329128137],
            [5.502777099609375, 59.083621899911925],
            [5.4986572265625, 59.09173509215263],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.5281829833984375, 59.0389650364748],
            [5.518913269042969, 59.03843516004435],
            [5.516166687011718, 59.03490244176445],
            [5.5268096923828125, 59.03207600574613],
            [5.534019470214844, 59.034195854543306],
            [5.5281829833984375, 59.0389650364748],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.492820739746094, 58.98593694843747],
            [5.485267639160156, 58.9843448429994],
            [5.488014221191406, 58.978860360999484],
            [5.515480041503906, 58.98080656745355],
            [5.52337646484375, 58.98540625480093],
            [5.492820739746094, 58.98593694843747],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.486640930175781, 58.96417180405949],
            [5.4656982421875, 58.96240167583843],
            [5.439949035644531, 58.9577989171166],
            [5.4238128662109375, 58.94912281703001],
            [5.425529479980468, 58.938850326671975],
            [5.4334259033203125, 58.9333586034262],
            [5.4437255859375, 58.93672460197714],
            [5.459861755371094, 58.94186154586293],
            [5.470161437988281, 58.949476986288005],
            [5.470848083496093, 58.94451828576638],
            [5.481834411621094, 58.93389009870509],
            [5.494194030761718, 58.938673187948304],
            [5.4876708984375, 58.945758027716465],
            [5.476684570312499, 58.95337260813864],
            [5.4938507080078125, 58.96045442982353],
            [5.486640930175781, 58.96417180405949],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.507926940917969, 58.927157220444734],
            [5.499000549316406, 58.925030775538985],
            [5.500373840332031, 58.92006856144516],
            [5.487327575683594, 58.91953685335618],
            [5.491447448730469, 58.91581466751834],
            [5.496940612792969, 58.90961013300658],
            [5.508613586425781, 58.907482607493236],
            [5.522346496582031, 58.9094328442174],
            [5.525093078613281, 58.9152828939384],
            [5.5268096923828125, 58.922372535237926],
            [5.519256591796875, 58.925207984283155],
            [5.507926940917969, 58.927157220444734],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.4526519775390625, 58.89524679328905],
            [5.433769226074218, 58.892054129288816],
            [5.4265594482421875, 58.88495826474443],
            [5.424156188964844, 58.8764413050495],
            [5.436859130859375, 58.87466667447413],
            [5.444755554199219, 58.88247436812904],
            [5.4553985595703125, 58.88815158398663],
            [5.465354919433594, 58.89400523676829],
            [5.4526519775390625, 58.89524679328905],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.957317352294922, 58.99584172898123],
            [10.950965881347656, 58.9938079439721],
            [10.951480865478516, 58.989916890105576],
            [10.944442749023438, 58.98558315358823],
            [10.940494537353516, 58.979568085157105],
            [10.938777923583983, 58.979568085157105],
            [10.945472717285156, 58.976737101303385],
            [10.95388412475586, 58.973286525239814],
            [10.961780548095703, 58.974259799624896],
            [10.97311019897461, 58.97027804881111],
            [10.977401733398438, 58.972047772632024],
            [10.974311828613281, 58.974171321271164],
            [10.965900421142578, 58.978152622304826],
            [10.97208023071289, 58.97939115548053],
            [10.979290008544922, 58.984964003858174],
            [10.971565246582031, 58.98646763389795],
            [10.968647003173828, 58.985760051467075],
            [10.962123870849608, 58.98912093856253],
            [10.967273712158203, 58.99195090492284],
            [10.957317352294922, 58.99584172898123],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.925045013427733, 58.990624387145786],
            [10.919380187988281, 58.99018220319887],
            [10.918521881103516, 58.988590293981076],
            [10.92264175415039, 58.98629073965288],
            [10.927276611328125, 58.98540625480093],
            [10.932254791259766, 58.98673297356221],
            [10.929508209228516, 58.988324968624475],
            [10.925045013427733, 58.990624387145786],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.869770050048828, 58.960985507824866],
            [10.868911743164062, 58.95903818182904],
            [10.870628356933594, 58.956294035804625],
            [10.872516632080076, 58.95496614481606],
            [10.875263214111328, 58.95576288554467],
            [10.877666473388672, 58.959303732758876],
            [10.874061584472656, 58.96080848273328],
            [10.869770050048828, 58.960985507824866],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.879039764404297, 59.058299936868416],
            [10.874576568603516, 59.05732903698783],
            [10.873546600341797, 59.054945802744015],
            [10.868568420410154, 59.05326861285944],
            [10.869083404541016, 59.050090554831854],
            [10.863418579101562, 59.05044368690802],
            [10.858440399169922, 59.0496491346327],
            [10.8599853515625, 59.04611756887638],
            [10.864620208740233, 59.04408675420905],
            [10.871315002441406, 59.04329205493147],
            [10.8709716796875, 59.041437685152516],
            [10.872688293457031, 59.03834684651182],
            [10.878524780273438, 59.03711043323273],
            [10.88521957397461, 59.03631557264101],
            [10.893115997314453, 59.03808190455292],
            [10.893115997314453, 59.04090784683868],
            [10.8984375, 59.04382185649166],
            [10.896034240722656, 59.047000494343294],
            [10.89792251586914, 59.05000227124572],
            [10.895862579345701, 59.052385848451316],
            [10.891399383544922, 59.05185617891852],
            [10.890884399414062, 59.05441617267923],
            [10.88693618774414, 59.05432790020789],
            [10.883846282958984, 59.05574023253077],
            [10.879039764404297, 59.058299936868416],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.737419128417969, 59.11993995134245],
            [10.732955932617188, 59.11870647410298],
            [10.736732482910156, 59.11447707216786],
            [10.749778747558594, 59.11430083575908],
            [10.749778747558594, 59.11042340547195],
            [10.766944885253906, 59.10407757330949],
            [10.769691467285156, 59.098788482459085],
            [10.785140991210938, 59.08838422358502],
            [10.78582763671875, 59.09367491909131],
            [10.787887573242188, 59.10108052199572],
            [10.778961181640623, 59.104430150358816],
            [10.777931213378904, 59.1095421101387],
            [10.768318176269531, 59.115534471588894],
            [10.737419128417969, 59.11993995134245],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.531768798828125, 59.077623959373156],
            [10.528335571289062, 59.07691825038758],
            [10.522842407226562, 59.07727110669419],
            [10.513916015625, 59.07197788118104],
            [10.507049560546873, 59.059623847023744],
            [10.507049560546873, 59.04797168615519],
            [10.513916015625, 59.03808190455292],
            [10.52490234375, 59.030309365238175],
            [10.512542724609375, 59.02606905767139],
            [10.522842407226562, 59.02182822722752],
            [10.53314208984375, 59.02960268361954],
            [10.518035888671875, 59.03878841190553],
            [10.526275634765625, 59.044439947989396],
            [10.540695190429688, 59.05609330653872],
            [10.535888671875, 59.06421300714903],
            [10.531768798828125, 59.077623959373156],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.721797943115234, 59.18718526575666],
            [10.7171630859375, 59.178742850970224],
            [10.707721710205076, 59.177335579087384],
            [10.7061767578125, 59.17170591220889],
            [10.709095001220703, 59.16827487938643],
            [10.717506408691404, 59.16686717658884],
            [10.71939468383789, 59.17144199882903],
            [10.730724334716797, 59.16660322586346],
            [10.739479064941406, 59.16906668674672],
            [10.73415756225586, 59.173025448554526],
            [10.725574493408203, 59.17117808341222],
            [10.717334747314453, 59.17584028923427],
            [10.72866439819336, 59.181029544223215],
            [10.729179382324217, 59.18709733468387],
            [10.721797943115234, 59.18718526575666],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.705833435058592, 59.257808660969225],
            [10.694847106933594, 59.25096353844205],
            [10.682830810546875, 59.2430636116697],
            [10.68042755126953, 59.23112247097296],
            [10.683174133300781, 59.21829865128597],
            [10.702743530273438, 59.20933668440181],
            [10.715103149414062, 59.21267573137346],
            [10.702743530273438, 59.22251523338666],
            [10.710296630859375, 59.231473740671774],
            [10.707893371582031, 59.24288803694853],
            [10.709266662597656, 59.25464954448365],
            [10.707550048828125, 59.25412299659754],
            [10.71063995361328, 59.25464954448365],
            [10.705833435058592, 59.257808660969225],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.651588439941406, 59.33739180590685],
            [10.646438598632812, 59.33634125981278],
            [10.645751953125, 59.33213875053702],
            [10.63751220703125, 59.3247831083411],
            [10.639915466308594, 59.319352922493785],
            [10.654335021972656, 59.31760106457567],
            [10.660858154296875, 59.3209295174329],
            [10.655364990234375, 59.32565886352457],
            [10.658454895019531, 59.3317885179649],
            [10.651588439941406, 59.33739180590685],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.668067932128906, 59.30936612300601],
            [10.661201477050781, 59.30726326510286],
            [10.655021667480469, 59.30235609098392],
            [10.656394958496094, 59.296045826776194],
            [10.666351318359375, 59.292013656293385],
            [10.6732177734375, 59.286402885107606],
            [10.670814514160156, 59.27763420324702],
            [10.675621032714842, 59.27465233689575],
            [10.684890747070312, 59.280791188902306],
            [10.6842041015625, 59.2913123604788],
            [10.677680969238281, 59.29446807783706],
            [10.677680969238281, 59.297974087241805],
            [10.6787109375, 59.30445925229674],
            [10.668067932128906, 59.30936612300601],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.523529052734373, 59.39407306645033],
            [10.51185607910156, 59.39337387496283],
            [10.510482788085938, 59.38585665547066],
            [10.511512756347656, 59.37868752149773],
            [10.521125793457031, 59.36924293446236],
            [10.537605285644531, 59.36364490114323],
            [10.543441772460938, 59.37449025191804],
            [10.538291931152344, 59.3877798237848],
            [10.525588989257812, 59.38743016493304],
            [10.523529052734373, 59.39407306645033],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.363540649414062, 59.50471272416051],
            [10.359764099121094, 59.50471272416051],
            [10.360107421875, 59.50192500683503],
            [10.3875732421875, 59.48501953977238],
            [10.400962829589844, 59.47978933061082],
            [10.403022766113281, 59.483101890457746],
            [10.390663146972656, 59.49495470592773],
            [10.363540649414062, 59.50471272416051],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.499839782714844, 59.49112042682249],
            [10.492286682128906, 59.48257887629155],
            [10.500869750976562, 59.48048673863045],
            [10.506019592285154, 59.48937742871752],
            [10.499839782714844, 59.49112042682249],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.564384460449219, 59.51795123679212],
            [10.553741455078123, 59.517777079564375],
            [10.552024841308594, 59.512726128735956],
            [10.561294555664062, 59.5111584384738],
            [10.564384460449219, 59.51795123679212],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.633049011230469, 59.40062728520459],
            [10.62875747680664, 59.40036514079251],
            [10.633907318115234, 59.39354867418694],
            [10.635795593261719, 59.392849471881505],
            [10.637683868408203, 59.39389826993069],
            [10.637855529785156, 59.397131859618995],
            [10.637683868408203, 59.399840845884015],
            [10.633049011230469, 59.40062728520459],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.179004669189453, 58.985671602641226],
            [10.169734954833984, 58.981337331952524],
            [10.156688690185547, 58.97841802601889],
            [10.14261245727539, 58.977533339021846],
            [10.126476287841797, 58.97319804438728],
            [10.1239013671875, 58.97125140815771],
            [10.130767822265625, 58.96824275407722],
            [10.146389007568358, 58.9639062906201],
            [10.158920288085938, 58.963994795327096],
            [10.155315399169922, 58.96992409314247],
            [10.161495208740234, 58.97319804438728],
            [10.18106460571289, 58.977002515920546],
            [10.182952880859375, 58.98319494330756],
            [10.179004669189453, 58.985671602641226],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.22500991821289, 58.989916890105576],
            [10.214881896972656, 58.985760051467075],
            [10.214366912841797, 58.98054118213777],
            [10.2117919921875, 58.979037293401746],
            [10.216598510742186, 58.97549847272547],
            [10.228271484374998, 58.97558694767181],
            [10.242862701416014, 58.97974501392508],
            [10.247669219970703, 58.984964003858174],
            [10.233592987060547, 58.9886787353124],
            [10.22500991821289, 58.989916890105576],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.192394256591797, 58.975321522151276],
            [10.192394256591797, 58.972313223367664],
            [10.194625854492188, 58.97098594924385],
            [10.199432373046875, 58.97249018938895],
            [10.197200775146484, 58.97470218798619],
            [10.192394256591797, 58.975321522151276],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.759708404541016, 58.37426813898458],
            [8.7506103515625, 58.373638036785266],
            [8.734474182128906, 58.37129755867206],
            [8.72915267944336, 58.36814666990805],
            [8.73189926147461, 58.36544568419205],
            [8.737220764160154, 58.364005073958424],
            [8.746490478515625, 58.366436069621365],
            [8.7506103515625, 58.369227006319484],
            [8.751811981201172, 58.372287779896276],
            [8.761596679687498, 58.370757426292286],
            [8.759708404541016, 58.37426813898458],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.747177124023438, 58.3847981814925],
            [8.742542266845703, 58.38470819444641],
            [8.736705780029297, 58.38101852787278],
            [8.736705780029297, 58.37777850240098],
            [8.73687744140625, 58.37660842006927],
            [8.742027282714844, 58.37678843526156],
            [8.747177124023438, 58.38110852433249],
            [8.747177124023438, 58.3847981814925],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.784942626953125, 58.40135189067493],
            [8.778934478759766, 58.40000269446833],
            [8.777046203613281, 58.39595479597593],
            [8.7725830078125, 58.395235120914045],
            [8.768463134765623, 58.39145658580641],
            [8.767948150634766, 58.39037692996884],
            [8.771724700927734, 58.38983708965327],
            [8.774471282958984, 58.39244624128409],
            [8.778247833251953, 58.394785316539995],
            [8.782196044921875, 58.39208636977883],
            [8.787689208984375, 58.3919064326488],
            [8.792839050292967, 58.39532508110022],
            [8.795242309570312, 58.39874339808061],
            [8.791809082031248, 58.40054237914826],
            [8.784942626953125, 58.40135189067493],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.79232406616211, 58.41439145835171],
            [8.789405822753906, 58.41430154682212],
            [8.787002563476562, 58.41277301570938],
            [8.790950775146484, 58.409805677756275],
            [8.794384002685547, 58.40953590736815],
            [8.796443939208984, 58.41079481817519],
            [8.79232406616211, 58.41439145835171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.575462341308594, 61.051140518044775],
            [4.568939208984375, 61.03302192941602],
            [4.58301544189453, 61.020881671448265],
            [4.581642150878906, 61.01322925952127],
            [4.577522277832031, 61.00457648487655],
            [4.561042785644531, 61.002246488752505],
            [4.5641326904296875, 60.99242534083332],
            [4.5751190185546875, 60.984599541262334],
            [4.568939208984375, 60.97260734398251],
            [4.5792388916015625, 60.97177438449758],
            [4.588165283203125, 60.979936446577916],
            [4.619407653808594, 60.98493259327503],
            [4.619407653808594, 60.997918899505706],
            [4.6218109130859375, 61.00940236159538],
            [4.607734680175781, 61.01905191471263],
            [4.591941833496094, 61.041999405135705],
            [4.575462341308594, 61.051140518044775],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.506797790527344, 61.07605743067115],
            [4.501991271972656, 61.07373268138711],
            [4.509544372558594, 61.07057739147019],
            [4.5215606689453125, 61.06509640378413],
            [4.516410827636719, 61.05928219844618],
            [4.521903991699219, 61.05280225537275],
            [4.5188140869140625, 61.04681959319699],
            [4.505424499511719, 61.04382783860709],
            [4.493751525878906, 61.034684616675605],
            [4.496498107910156, 61.01705569612605],
            [4.513664245605469, 61.017554762544336],
            [4.543876647949219, 61.022378666668395],
            [4.550743103027344, 61.030195161021204],
            [4.5503997802734375, 61.04233185542855],
            [4.54010009765625, 61.05579316299559],
            [4.5311737060546875, 61.066425215174654],
            [4.514007568359375, 61.07539323400765],
            [4.506797790527344, 61.07605743067115],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.603569030761718, 63.235637358707926],
            [7.594642639160156, 63.23053491414099],
            [7.578506469726562, 63.22249287013764],
            [7.56580352783203, 63.21615045024818],
            [7.5524139404296875, 63.21707869614697],
            [7.55859375, 63.22357558329676],
            [7.550354003906251, 63.22697813213988],
            [7.532844543457031, 63.22450359087786],
            [7.534217834472656, 63.216614576921636],
            [7.56855010986328, 63.212127707070536],
            [7.564430236816406, 63.204854405270574],
            [7.577476501464843, 63.1989725264735],
            [7.59601593017578, 63.2022231862173],
            [7.607688903808593, 63.215222174556445],
            [7.611808776855469, 63.223730253295486],
            [7.619361877441405, 63.234555097257655],
            [7.603569030761718, 63.235637358707926],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.655410766601562, 63.20423531642905],
            [7.644081115722656, 63.19541186114509],
            [7.627944946289062, 63.18736004656513],
            [7.628631591796875, 63.186740583460974],
            [7.625198364257812, 63.184417478799],
            [7.646827697753907, 63.182713750273386],
            [7.659530639648438, 63.18689545047931],
            [7.660560607910156, 63.19324428518544],
            [7.666740417480469, 63.202687536376374],
            [7.655410766601562, 63.20423531642905],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.409347534179688, 63.82083325532171],
            [8.393898010253906, 63.81674334980776],
            [8.376388549804688, 63.817197813077925],
            [8.372955322265625, 63.812046800042324],
            [8.360252380371094, 63.808258689626015],
            [8.361625671386719, 63.80719792745637],
            [8.354415893554688, 63.80492473122039],
            [8.355445861816406, 63.80128723588716],
            [8.377418518066406, 63.8011356633953],
            [8.37982177734375, 63.79279792124878],
            [8.388748168945312, 63.78567098777923],
            [8.407630920410156, 63.78794573666353],
            [8.439559936523438, 63.79461727527119],
            [8.460845947265625, 63.80189351770543],
            [8.453292846679688, 63.80553093480961],
            [8.442649841308594, 63.80340916520719],
            [8.433380126953125, 63.80492473122039],
            [8.420333862304688, 63.80977399489808],
            [8.409004211425781, 63.808561757198554],
            [8.3935546875, 63.811592253678356],
            [8.411407470703125, 63.817197813077925],
            [8.409347534179688, 63.82083325532171],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.355789184570312, 63.78476103686385],
            [8.342399597167969, 63.78278937585743],
            [8.327980041503904, 63.776873566056885],
            [8.303260803222656, 63.783244386781],
            [8.302230834960938, 63.78248603116502],
            [8.29742431640625, 63.78172765516542],
            [8.298797607421875, 63.77672186231762],
            [8.295707702636719, 63.77019783004993],
            [8.284378051757812, 63.76473468975625],
            [8.273735046386719, 63.75289425883379],
            [8.308410644531248, 63.75668980926358],
            [8.322486877441406, 63.76336873950307],
            [8.332099914550781, 63.77141171767299],
            [8.359909057617188, 63.771108250660795],
            [8.370552062988281, 63.776873566056885],
            [8.355789184570312, 63.78476103686385],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.447113037109375, 63.77141171767299],
            [8.431320190429688, 63.76868039711576],
            [8.413810729980469, 63.76427938034691],
            [8.392181396484375, 63.760333057921436],
            [8.366775512695312, 63.754412540202146],
            [8.360939025878906, 63.751224055081465],
            [8.366432189941406, 63.748035210092524],
            [8.377418518066406, 63.751224055081465],
            [8.377418518066406, 63.74742777024308],
            [8.366775512695312, 63.74271966865355],
            [8.364715576171875, 63.739074148081876],
            [8.378105163574219, 63.73709929478992],
            [8.381195068359375, 63.74363097533544],
            [8.398361206054688, 63.74590911349124],
            [8.426856994628906, 63.74849078141057],
            [8.45123291015625, 63.753805237446485],
            [8.452606201171875, 63.7648864579279],
            [8.447113037109375, 63.77141171767299],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.46771240234375, 63.79704289805787],
            [8.427200317382812, 63.785974298228794],
            [8.427886962890625, 63.78278937585743],
            [8.435096740722656, 63.77823886301477],
            [8.446426391601562, 63.77930071498412],
            [8.462562561035156, 63.78203100801132],
            [8.460845947265625, 63.77657015776287],
            [8.470802307128906, 63.770501306849305],
            [8.485221862792969, 63.77141171767299],
            [8.482818603515625, 63.76701112668092],
            [8.501014709472656, 63.76640409477982],
            [8.510971069335938, 63.76943912377823],
            [8.506507873535156, 63.77520478007342],
            [8.49517822265625, 63.77672186231762],
            [8.483848571777342, 63.781879331996045],
            [8.487281799316406, 63.78794573666353],
            [8.478355407714844, 63.79249468416687],
            [8.46771240234375, 63.79704289805787],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.379135131835938, 63.84339279663646],
            [8.365402221679688, 63.841425234250764],
            [8.35784912109375, 63.83476477146556],
            [8.343086242675781, 63.83082830255387],
            [8.348922729492188, 63.8276484450477],
            [8.359909057617188, 63.829919808481975],
            [8.363685607910156, 63.83415919668994],
            [8.372955322265625, 63.83794382531543],
            [8.376045227050781, 63.832342394197944],
            [8.382225036621094, 63.82961697061026],
            [8.390121459960938, 63.82416533190511],
            [8.400077819824217, 63.82355952911957],
            [8.419647216796875, 63.824013882430386],
            [8.414840698242188, 63.829465550452944],
            [8.397674560546875, 63.8373383189301],
            [8.379135131835938, 63.84339279663646],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.430976867675781, 63.87258735279697],
            [8.416557312011719, 63.8722849739307],
            [8.409004211425781, 63.86790011474471],
            [8.3935546875, 63.86608549014481],
            [8.395957946777344, 63.86094341759417],
            [8.396987915039062, 63.85791822968667],
            [8.4100341796875, 63.85822076312339],
            [8.425483703613281, 63.86154841612088],
            [8.440589904785156, 63.87001702872668],
            [8.430976867675781, 63.87258735279697],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.560409545898438, 63.92092608484933],
            [8.535690307617188, 63.914586370839885],
            [8.536033630371094, 63.90733922850797],
            [8.525390625, 63.90703722361651],
            [8.513717651367188, 63.8993350005692],
            [8.517837524414062, 63.892083918309986],
            [8.504791259765625, 63.88619103484046],
            [8.520240783691406, 63.88347082537768],
            [8.547706604003906, 63.885284327624305],
            [8.556632995605469, 63.88785325538203],
            [8.574142456054688, 63.89042194817356],
            [8.586502075195312, 63.889968666518584],
            [8.58856201171875, 63.886946601827844],
            [8.601951599121094, 63.88649326407461],
            [8.625984191894531, 63.8887598796671],
            [8.620147705078125, 63.89117740133902],
            [8.608818054199219, 63.89752240557911],
            [8.600578308105469, 63.89737135071336],
            [8.586845397949219, 63.90205367360573],
            [8.581695556640625, 63.90643320408231],
            [8.570022583007812, 63.905225126007714],
            [8.555259704589844, 63.90295983943447],
            [8.54461669921875, 63.90477208332113],
            [8.553543090820312, 63.911264996318806],
            [8.575172424316406, 63.913982513812336],
            [8.570709228515625, 63.91956769533998],
            [8.560409545898438, 63.92092608484933],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.798332214355469, 63.902657787409076],
            [8.784942626953125, 63.90099644316008],
            [8.7835693359375, 63.895558628923204],
            [8.77429962158203, 63.8867954900565],
            [8.766403198242188, 63.888608777652124],
            [8.749580383300781, 63.885284327624305],
            [8.74305725097656, 63.882412894994516],
            [8.744430541992188, 63.87666914906894],
            [8.761940002441406, 63.87561096252311],
            [8.7835693359375, 63.88029691469371],
            [8.801422119140625, 63.890724131878656],
            [8.808975219726562, 63.899637088355156],
            [8.798332214355469, 63.902657787409076],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.679542541503906, 63.922737168511894],
            [8.676109313964844, 63.91956769533998],
            [8.666839599609375, 63.91903941498334],
            [8.667869567871094, 63.914963774881],
            [8.669757843017578, 63.91345412824975],
            [8.675765991210938, 63.91217086473297],
            [8.6846923828125, 63.91519021486836],
            [8.688468933105469, 63.91851112467546],
            [8.698081970214844, 63.91549213200796],
            [8.694133758544922, 63.91330315911809],
            [8.69344711303711, 63.90907569355626],
            [8.70340347290039, 63.909830644841236],
            [8.715076446533203, 63.91519021486836],
            [8.709068298339844, 63.91737904050583],
            [8.697395324707031, 63.918058296491104],
            [8.685894012451172, 63.92190710301944],
            [8.679542541503906, 63.922737168511894],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.501113891601562, 63.73846651560872],
            [9.500083923339844, 63.7337569210794],
            [9.502830505371094, 63.729654376921616],
            [9.496994018554688, 63.726463097731134],
            [9.476737976074219, 63.724183392123315],
            [9.456825256347656, 63.7181032788583],
            [9.442062377929688, 63.71156569959988],
            [9.4427490234375, 63.70228886800586],
            [9.451332092285156, 63.69772537652243],
            [9.467124938964844, 63.70213676347147],
            [9.482231140136719, 63.706547463239744],
            [9.473304748535156, 63.708372379397765],
            [9.454765319824219, 63.70578704678493],
            [9.46197509765625, 63.71080541794296],
            [9.480514526367188, 63.715670867659625],
            [9.498023986816406, 63.717647217685624],
            [9.51587677001953, 63.719775440269004],
            [9.521369934082031, 63.726007171308154],
            [9.517936706542969, 63.73162975025795],
            [9.507293701171875, 63.73816269447433],
            [9.501113891601562, 63.73846651560872],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.612693786621094, 63.88936427959683],
            [9.599647521972656, 63.88649326407461],
            [9.586257934570312, 63.87984346960731],
            [9.575614929199219, 63.87561096252311],
            [9.590377807617188, 63.871075425930414],
            [9.583854675292969, 63.86336333359534],
            [9.587631225585938, 63.86169966371853],
            [9.601707458496092, 63.86245588950273],
            [9.618873596191406, 63.86608549014481],
            [9.621620178222656, 63.873948017433854],
            [9.616470336914062, 63.882110621852426],
            [9.612693786621094, 63.88936427959683],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.597930908203125, 63.98228674804167],
            [9.583511352539062, 63.97776843769979],
            [9.581794738769531, 63.97098960351148],
            [9.55535888671875, 63.96737355362341],
            [9.535446166992188, 63.960441485138176],
            [9.533042907714844, 63.95802992842827],
            [9.540939331054688, 63.953356946123165],
            [9.548835754394531, 63.948230842914384],
            [9.54437255859375, 63.94144485172751],
            [9.559135437011719, 63.94129403325188],
            [9.57630157470703, 63.94792927821151],
            [9.576988220214844, 63.95456295170284],
            [9.569091796875, 63.96014005190979],
            [9.592781066894531, 63.962551426858],
            [9.603767395019531, 63.97083894409526],
            [9.60479736328125, 63.980479511492604],
            [9.597930908203125, 63.98228674804167],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.065292358398438, 64.69440978626835],
            [11.059455871582031, 64.68692440226665],
            [11.064262390136719, 64.6838415842222],
            [11.086578369140625, 64.68663081565633],
            [11.090354919433594, 64.69074073877866],
            [11.084518432617188, 64.69411628075422],
            [11.065292358398438, 64.69440978626835],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.042289733886719, 64.72257151400665],
            [11.0247802734375, 64.71846641972319],
            [11.004180908203125, 64.71230761047208],
            [10.991134643554688, 64.70614739960972],
            [10.971565246582031, 64.70394698457838],
            [10.973968505859375, 64.69954561804485],
            [10.975341796875, 64.69969234178754],
            [10.977745056152342, 64.69690445475311],
            [10.999374389648438, 64.69426303390877],
            [11.013793945312498, 64.6967577159076],
            [11.003150939941406, 64.70380028388504],
            [11.015853881835938, 64.7046804761238],
            [11.030616760253906, 64.71348082504926],
            [11.041259765625, 64.71846641972319],
            [11.042289733886719, 64.72257151400665],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.924530029296875, 64.69264870548434],
            [10.912857055664062, 64.69044719351366],
            [10.92041015625, 64.68721798569639],
            [10.931739807128906, 64.68853907177144],
            [10.931396484375, 64.6916213554924],
            [10.924530029296875, 64.69264870548434],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.959892272949219, 65.22061342245753],
            [10.941352844238281, 65.21615229173536],
            [10.943069458007812, 65.21010697885794],
            [10.955429077148438, 65.20837949289569],
            [10.96160888671875, 65.1994523521195],
            [10.966415405273438, 65.1971480850814],
            [10.977745056152342, 65.19455554494378],
            [10.992851257324219, 65.19282704383123],
            [11.004524230957031, 65.1991643297068],
            [11.009330749511719, 65.20866741505392],
            [10.996627807617188, 65.21356161249167],
            [10.974655151367188, 65.21903052670694],
            [10.959892272949219, 65.22061342245753],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.848411560058594, 65.1898739267586],
            [11.837940216064453, 65.18670436163166],
            [11.832962036132812, 65.18072437666274],
            [11.83279037475586, 65.18043615052363],
            [11.84377670288086, 65.18072437666274],
            [11.853561401367188, 65.185047392551],
            [11.855278015136719, 65.18972986384416],
            [11.848411560058594, 65.1898739267586],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.682586669921875, 65.3608260331206],
            [11.68121337890625, 65.35810638318864],
            [11.689109802246092, 65.35381162604264],
            [11.699752807617188, 65.34908658264033],
            [11.701812744140623, 65.34264197297829],
            [11.709365844726562, 65.33619578381615],
            [11.721382141113281, 65.3330437386068],
            [11.722755432128906, 65.33934745143243],
            [11.709022521972656, 65.3522367059295],
            [11.688079833984373, 65.36053976743356],
            [11.682586669921875, 65.3608260331206],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.699066162109375, 65.39043767332485],
            [11.684303283691404, 65.38686316081842],
            [11.685676574707031, 65.38285912881744],
            [11.695632934570312, 65.38343117078206],
            [11.703872680664062, 65.38014175925758],
            [11.7059326171875, 65.37456311947554],
            [11.718292236328123, 65.37470617632617],
            [11.725845336914062, 65.37756714970112],
            [11.736488342285156, 65.37585060307859],
            [11.737518310546875, 65.37999873201606],
            [11.719322204589842, 65.38428921035775],
            [11.712455749511717, 65.38872196807765],
            [11.699066162109375, 65.39043767332485],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.821975708007812, 65.38500422191429],
            [11.814765930175781, 65.3811429281331],
            [11.81098937988281, 65.38400320028198],
            [11.800003051757812, 65.38285912881744],
            [11.793136596679688, 65.36955563875019],
            [11.79725646972656, 65.36254356175955],
            [11.803092956542967, 65.35896525041406],
            [11.813735961914062, 65.35094806474827],
            [11.826438903808594, 65.34106638372148],
            [11.833992004394531, 65.33848794316411],
            [11.839141845703123, 65.34235550922445],
            [11.828842163085938, 65.349945744605],
            [11.820259094238281, 65.35810638318864],
            [11.808586120605469, 65.36497653517768],
            [11.804122924804686, 65.37155874559963],
            [11.812362670898438, 65.37456311947554],
            [11.809272766113281, 65.37785322989617],
            [11.822319030761719, 65.38014175925758],
            [11.827125549316406, 65.38357417932562],
            [11.821975708007812, 65.38500422191429],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.844635009765625, 65.42715544700475],
            [11.830902099609375, 65.42230079340574],
            [11.836051940917969, 65.4147314511151],
            [11.844635009765625, 65.41201738097462],
            [11.8597412109375, 65.41658828458448],
            [11.874847412109375, 65.42230079340574],
            [11.866264343261719, 65.42501379893943],
            [11.844635009765625, 65.42715544700475],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.720008850097656, 65.45227104372789],
            [11.701812744140623, 65.45041673833357],
            [11.689109802246092, 65.44356893398616],
            [11.678810119628906, 65.43814648491062],
            [11.681556701660156, 65.43357934092094],
            [11.692886352539062, 65.43043896738136],
            [11.693229675292969, 65.42558492220722],
            [11.710052490234375, 65.4231575623414],
            [11.713142395019531, 65.41887343752815],
            [11.721382141113281, 65.41715959149825],
            [11.739578247070312, 65.41915906763799],
            [11.761207580566404, 65.42144399645731],
            [11.76704406738281, 65.42572770107904],
            [11.777000427246094, 65.42158679789407],
            [11.788330078125, 65.41973031851923],
            [11.798973083496094, 65.42272918137513],
            [11.801376342773438, 65.42672713139636],
            [11.789703369140625, 65.428725877662],
            [11.779060363769531, 65.42672713139636],
            [11.764984130859375, 65.428725877662],
            [11.743698120117186, 65.42472823263714],
            [11.722068786621094, 65.42444266322255],
            [11.742324829101562, 65.42929692001586],
            [11.757774353027344, 65.43457847172827],
            [11.749191284179688, 65.44299820275516],
            [11.74163818359375, 65.44228477121935],
            [11.73786163330078, 65.43743292116385],
            [11.732711791992188, 65.43472120158937],
            [11.716232299804688, 65.4328656527226],
            [11.704902648925781, 65.4328656527226],
            [11.708679199218748, 65.43657661900106],
            [11.721382141113281, 65.43857461382554],
            [11.728248596191406, 65.44257014616663],
            [11.735458374023438, 65.44941821177562],
            [11.720008850097656, 65.45227104372789],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.67245864868164, 65.49993858515444],
            [11.66799545288086, 65.49965383567988],
            [11.659069061279297, 65.49780288840358],
            [11.657524108886719, 65.49588061197652],
            [11.662158966064453, 65.49467021718861],
            [11.656150817871094, 65.49224925931944],
            [11.649112701416014, 65.48911592190257],
            [11.648597717285156, 65.4877627736453],
            [11.6510009765625, 65.48498503921648],
            [11.656665802001953, 65.4871217842188],
            [11.66250228881836, 65.48854618381026],
            [11.67245864868164, 65.48526994864646],
            [11.677093505859375, 65.486480779066],
            [11.675376892089844, 65.48911592190257],
            [11.678810119628906, 65.49224925931944],
            [11.6729736328125, 65.49602300767539],
            [11.67245864868164, 65.49993858515444],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.566543579101562, 65.64383647240834],
            [11.559677124023438, 65.63973006937613],
            [11.563796997070312, 65.63392335580876],
            [11.560707092285156, 65.63080702251222],
            [11.551780700683594, 65.62613182086301],
            [11.55658721923828, 65.62159748773279],
            [11.548347473144531, 65.61777102811871],
            [11.548690795898438, 65.61351874495912],
            [11.541481018066406, 65.60912398756417],
            [11.533584594726562, 65.60189228490376],
            [11.543540954589844, 65.59905577363175],
            [11.5521240234375, 65.60572108408635],
            [11.554183959960938, 65.61195940001966],
            [11.564140319824219, 65.61521974176937],
            [11.562767028808594, 65.62046378070515],
            [11.568946838378906, 65.62740695937242],
            [11.582679748535156, 65.62981538343816],
            [11.592292785644531, 65.62769031497928],
            [11.595039367675781, 65.62258944077666],
            [11.589202880859375, 65.61847967429279],
            [11.579933166503904, 65.61989690862838],
            [11.58233642578125, 65.61436925728913],
            [11.590919494628906, 65.61238468519598],
            [11.607398986816406, 65.61621193846491],
            [11.60980224609375, 65.6103999614671],
            [11.595726013183594, 65.60331042445306],
            [11.606025695800781, 65.60061589314101],
            [11.618041992187498, 65.60331042445306],
            [11.638984680175781, 65.60742259153663],
            [11.647567749023438, 65.61408575627358],
            [11.635551452636717, 65.6201803462137],
            [11.642074584960938, 65.62627350601285],
            [11.640357971191404, 65.63364006824517],
            [11.625251770019531, 65.63519811157475],
            [11.59950256347656, 65.63973006937613],
            [11.590576171875, 65.64270373649954],
            [11.57684326171875, 65.64072132962629],
            [11.566543579101562, 65.64383647240834],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.626625061035156, 65.65926507144401],
            [11.60980224609375, 65.65643482353138],
            [11.608772277832031, 65.64949940999591],
            [11.610145568847654, 65.64964096759286],
            [11.614952087402344, 65.64638494734551],
            [11.627311706542969, 65.64695124110337],
            [11.637611389160156, 65.6509149511912],
            [11.652374267578125, 65.65388733617867],
            [11.6400146484375, 65.65869904658368],
            [11.626625061035156, 65.65926507144401],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.397972106933594, 65.6490747325684],
            [11.392478942871094, 65.64397806091881],
            [11.3818359375, 65.6414293494726],
            [11.3818359375, 65.63675606135781],
            [11.394538879394531, 65.6376058127215],
            [11.395225524902344, 65.6322235840378],
            [11.385269165039062, 65.62669855682252],
            [11.370849609375, 65.62216432268411],
            [11.376342773437498, 65.61706236260646],
            [11.377716064453125, 65.61011641712686],
            [11.381149291992186, 65.60784795098915],
            [11.39007568359375, 65.60898220881745],
            [11.408615112304688, 65.64086293514148],
            [11.410675048828125, 65.6472343833453],
            [11.397972106933594, 65.6490747325684],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.296348571777342, 65.60614647139512],
            [11.285705566406248, 65.59990675951875],
            [11.273689270019531, 65.60331042445306],
            [11.25823974609375, 65.59877210547775],
            [11.261672973632812, 65.59480042624011],
            [11.2774658203125, 65.59238875348396],
            [11.281585693359375, 65.58827420713806],
            [11.285705566406248, 65.58713904528884],
            [11.303901672363281, 65.58898365813148],
            [11.316947937011719, 65.59721187529071],
            [11.312828063964844, 65.60175046669242],
            [11.302185058593748, 65.60047406796441],
            [11.306991577148438, 65.60487028857585],
            [11.296348571777342, 65.60614647139512],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.311111450195312, 65.65537340089033],
            [11.299610137939453, 65.65339196234456],
            [11.301326751708984, 65.65126882441584],
            [11.305274963378906, 65.65119805015729],
            [11.311283111572266, 65.65360426657533],
            [11.311111450195312, 65.65537340089033],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.237052917480469, 66.34997210010792],
            [12.231559753417969, 66.34990323822313],
            [12.225379943847656, 66.34907688086473],
            [12.227096557617186, 66.3452201866738],
            [12.2332763671875, 66.34618441578907],
            [12.237052917480469, 66.34997210010792],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.481498718261719, 66.60612896127468],
            [12.469825744628906, 66.60476576177214],
            [12.4639892578125, 66.60231181373835],
            [12.470512390136719, 66.59985762277884],
            [12.482872009277344, 66.59931221401816],
            [12.482528686523438, 66.59085684391366],
            [12.488365173339844, 66.58690092309047],
            [12.499351501464844, 66.58962921185635],
            [12.503471374511719, 66.59440299525849],
            [12.51617431640625, 66.59767591575026],
            [12.527847290039062, 66.60312982340707],
            [12.523727416992188, 66.60517472949472],
            [12.507247924804688, 66.6057200092957],
            [12.508277893066406, 66.59958491989818],
            [12.499008178710936, 66.59644862118556],
            [12.48870849609375, 66.60013032266016],
            [12.481498718261719, 66.60612896127468],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.864990234375, 66.76815393764649],
            [12.853660583496092, 66.7680185126791],
            [12.851600646972656, 66.76551301627588],
            [12.845592498779297, 66.76381996881355],
            [12.845420837402344, 66.75989164991148],
            [12.846107482910156, 66.75968844394134],
            [12.8485107421875, 66.7591465531494],
            [12.857952117919922, 66.76016258859444],
            [12.865333557128904, 66.76273635728342],
            [12.86773681640625, 66.76585161178537],
            [12.864990234375, 66.76815393764649],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.038368225097656, 66.83016778861929],
            [13.027381896972656, 66.83003270519562],
            [13.012962341308594, 66.8274659787543],
            [13.012275695800781, 66.82327758515343],
            [13.0078125, 66.81976418737288],
            [13.0023193359375, 66.82206146594187],
            [12.9913330078125, 66.82327758515343],
            [12.97554016113281, 66.81949390516257],
            [12.963867187499998, 66.81030253813984],
            [12.971076965332031, 66.80773374666155],
            [12.978286743164062, 66.81043773024473],
            [12.985153198242188, 66.81476348445267],
            [12.992706298828125, 66.81408763561609],
            [13.003349304199219, 66.81773699821444],
            [13.008499145507812, 66.81557447848718],
            [13.018798828125, 66.81489865198638],
            [13.03596496582031, 66.81787214936938],
            [13.043861389160156, 66.82597985644195],
            [13.038368225097656, 66.83016778861929],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.189430236816406, 67.0267316692269],
            [13.159217834472656, 67.02418554667867],
            [13.127288818359375, 67.02003077374839],
            [13.107376098632812, 67.01252357618392],
            [13.097763061523438, 67.0030021875185],
            [13.098106384277344, 66.99978284657415],
            [13.112525939941406, 67.00112429042665],
            [13.127975463867188, 67.00890320580346],
            [13.139991760253906, 67.01319395597245],
            [13.149948120117186, 67.01909250087598],
            [13.17329406738281, 67.02003077374839],
            [13.198356628417969, 67.02244330905276],
            [13.199386596679688, 67.02539163799581],
            [13.189430236816406, 67.0267316692269],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.115959167480469, 66.94310642813923],
            [13.097763061523438, 66.94055154230287],
            [13.084373474121094, 66.93517196039413],
            [13.077163696289062, 66.92898397442322],
            [13.06617736816406, 66.9245438003194],
            [13.076820373535154, 66.92373640914948],
            [13.095359802246092, 66.9252166058947],
            [13.0902099609375, 66.91848771556339],
            [13.059310913085936, 66.91418025233503],
            [13.059310913085936, 66.91364176600538],
            [13.052101135253906, 66.91189160343377],
            [13.08197021484375, 66.9096027400606],
            [13.096733093261719, 66.91310326780254],
            [13.103256225585936, 66.91700711077979],
            [13.110809326171875, 66.92091032974336],
            [13.121109008789062, 66.92037199181038],
            [13.14239501953125, 66.92144865580603],
            [13.141021728515625, 66.92535116478439],
            [13.130035400390625, 66.92911851254426],
            [13.110809326171875, 66.92790764275367],
            [13.110809326171875, 66.93100196836308],
            [13.115272521972656, 66.9357099719722],
            [13.115959167480469, 66.94310642813923],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.286590576171875, 66.97589276237916],
            [13.276634216308592, 66.97535563707437],
            [13.273200988769531, 66.9706552852171],
            [13.267364501953125, 66.96555101876504],
            [13.262214660644531, 66.95856449962767],
            [13.245735168457031, 66.9538609067472],
            [13.239212036132812, 66.94982853316601],
            [13.214492797851562, 66.94176178474505],
            [13.21723937988281, 66.938399851901],
            [13.230972290039062, 66.93974468064502],
            [13.240585327148436, 66.94216518554758],
            [13.23474884033203, 66.94619882661256],
            [13.24951171875, 66.94673659501333],
            [13.264274597167969, 66.95063506124384],
            [13.269081115722656, 66.9550704887591],
            [13.26873779296875, 66.9596394791222],
            [13.281097412109375, 66.9648793251281],
            [13.278350830078125, 66.96823760812153],
            [13.286933898925781, 66.97307272234055],
            [13.286590576171875, 66.97589276237916],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.103012084960936, 67.2582436436966],
            [14.0899658203125, 67.25744730379157],
            [14.077606201171875, 67.25359795533386],
            [14.076919555664062, 67.25253595753938],
            [14.087905883789062, 67.25213769626221],
            [14.101638793945312, 67.25532360161846],
            [14.103012084960936, 67.2582436436966],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.169273376464842, 67.27867400049284],
            [14.158973693847656, 67.27854139192125],
            [14.144554138183594, 67.27191002877248],
            [14.155197143554688, 67.27071618877764],
            [14.167900085449219, 67.27456279394099],
            [14.169273376464842, 67.27867400049284],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.17133331298828, 67.28755710532494],
            [14.162750244140625, 67.28384516312653],
            [14.16412353515625, 67.2834474209596],
            [14.175109863281248, 67.2839777423832],
            [14.179229736328125, 67.28715942471031],
            [14.17133331298828, 67.28755710532494],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.164810180664062, 67.40036380348332],
            [14.158630371093748, 67.39983605638062],
            [14.151077270507812, 67.39508580691808],
            [14.153823852539062, 67.39073058047745],
            [14.158287048339844, 67.38954265346264],
            [14.163780212402344, 67.39139051437618],
            [14.1668701171875, 67.39534973448451],
            [14.164810180664062, 67.40036380348332],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.272956848144531, 67.36167541281341],
            [14.25750732421875, 67.36088250379939],
            [14.256820678710938, 67.35784277549632],
            [14.261283874511719, 67.35625667679982],
            [14.259910583496092, 67.35374513858764],
            [14.270210266113281, 67.3541417147972],
            [14.274330139160156, 67.35586013567885],
            [14.26849365234375, 67.35704973930535],
            [14.274673461914062, 67.35890011615176],
            [14.272956848144531, 67.36167541281341],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.407196044921875, 67.34277058737676],
            [14.395179748535158, 67.34025763213296],
            [14.395179748535158, 67.33827353359649],
            [14.396209716796875, 67.33681842340854],
            [14.405479431152344, 67.33867036646926],
            [14.41028594970703, 67.3414480122646],
            [14.407196044921875, 67.34277058737676],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.153823852539062, 67.3520265656499],
            [14.144897460937498, 67.35057229204548],
            [14.140434265136719, 67.34753125227064],
            [14.149017333984375, 67.34779570586657],
            [14.15657043457031, 67.3504400809675],
            [14.153823852539062, 67.3520265656499],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.638938903808594, 67.6693023621619],
            [14.621772766113281, 67.66917191569891],
            [14.600486755371094, 67.66669329552232],
            [14.590530395507812, 67.65886436006734],
            [14.578857421875, 67.65703723382055],
            [14.571304321289062, 67.65129391401769],
            [14.57782745361328, 67.6468549348386],
            [14.57405090332031, 67.64528803659738],
            [14.578170776367186, 67.6369294859395],
            [14.577484130859375, 67.63627634931561],
            [14.581947326660154, 67.63523129307586],
            [14.595680236816404, 67.63784384681112],
            [14.598426818847656, 67.64306808570983],
            [14.594650268554688, 67.64972731099115],
            [14.604263305664062, 67.65834233846313],
            [14.612159729003906, 67.6614742945017],
            [14.629325866699219, 67.66238770320722],
            [14.644775390625, 67.66238770320722],
            [14.647865295410154, 67.66564958788125],
            [14.64820861816406, 67.66878057197177],
            [14.638938903808594, 67.6693023621619],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.943115234375001, 71.16825912245295],
            [-8.10516357421875, 71.1673725329256],
            [-8.27545166015625, 71.14075613474874],
            [-8.44024658203125, 71.08919494612884],
            [-8.525390625, 71.01785316791339],
            [-8.65447998046875, 71.00176557853851],
            [-8.7890625, 70.96507259301342],
            [-9.0142822265625, 70.91484507182207],
            [-9.1131591796875, 70.882488596545],
            [-9.10491943359375, 70.85188122123132],
            [-9.10491943359375, 70.82212896182514],
            [-9.00054931640625, 70.81220165893917],
            [-8.88519287109375, 70.8383629781251],
            [-8.7890625, 70.85908719717143],
            [-8.69842529296875, 70.9067608990448],
            [-8.519897460937498, 70.94983842231416],
            [-8.3441162109375, 70.96238506262917],
            [-8.00079345703125, 70.99729447383568],
            [-7.93487548828125, 71.02321278142121],
            [-7.959594726562499, 71.08385328476666],
            [-7.929382324218749, 71.10610062134981],
            [-7.899169921874999, 71.14608231274921],
            [-7.943115234375001, 71.16825912245295],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.10797119140625, 74.51878916336756],
            [18.9788818359375, 74.51512311925],
            [18.842926025390625, 74.50705484086316],
            [18.75640869140625, 74.49714725779786],
            [18.730316162109375, 74.47290269579455],
            [18.7701416015625, 74.45009381481869],
            [18.771514892578125, 74.43388704529787],
            [18.826446533203125, 74.41766380246501],
            [18.881378173828125, 74.3877551590238],
            [18.95416259765625, 74.37296481805079],
            [19.004974365234375, 74.35149455304104],
            [19.03106689453125, 74.33444596334549],
            [19.105224609375, 74.33370430966939],
            [19.136810302734375, 74.34853088092993],
            [19.19586181640625, 74.35445767798909],
            [19.19036865234375, 74.37444446675568],
            [19.210968017578125, 74.39810027491488],
            [19.274139404296875, 74.42540876970409],
            [19.290618896484375, 74.4541429350525],
            [19.27001953125, 74.47179979195317],
            [19.208221435546875, 74.48943708722256],
            [19.17800903320312, 74.50962246508725],
            [19.147796630859375, 74.51328977961651],
            [19.10797119140625, 74.51878916336756],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.508880615234375, 76.71927689675404],
            [25.44158935546875, 76.71454393513275],
            [25.32623291015625, 76.6801012961638],
            [25.224609375, 76.62716124412889],
            [25.094146728515625, 76.57784045373862],
            [24.989776611328125, 76.54848177668305],
            [24.91973876953125, 76.50656876964398],
            [24.906005859375, 76.48123106049253],
            [24.87579345703125, 76.44876926442144],
            [24.92523193359375, 76.43685818612143],
            [24.987030029296875, 76.4407223637397],
            [25.0048828125, 76.45552503939786],
            [24.9664306640625, 76.46902667571086],
            [25.01312255859375, 76.48668741879519],
            [25.0433349609375, 76.50720962535887],
            [25.16693115234375, 76.54528682051004],
            [25.18341064453125, 76.57146348794821],
            [25.249328613281246, 76.6014093894983],
            [25.313873291015625, 76.61317861680209],
            [25.37567138671875, 76.63827354838354],
            [25.41412353515625, 76.66205510944668],
            [25.522613525390625, 76.70886219472291],
            [25.508880615234375, 76.71927689675404],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8570556640625, 78.27262019424866],
            [22.4066162109375, 78.24803150063904],
            [22.3736572265625, 78.22675491013108],
            [22.08251953125, 78.21049196592207],
            [21.6925048828125, 78.2228313979996],
            [21.29974365234375, 78.19195891630292],
            [20.9014892578125, 78.13901024442676],
            [20.7696533203125, 78.12149718415195],
            [20.7257080078125, 78.08072329021213],
            [20.8355712890625, 78.0546011205412],
            [20.994873046875, 78.02215406784093],
            [21.15142822265625, 77.97475124639585],
            [21.288757324218746, 77.95298711132111],
            [21.500244140625, 77.9271635997809],
            [21.44805908203125, 77.90358803878992],
            [21.434326171875, 77.86207725533464],
            [21.316223144531246, 77.82853599839416],
            [21.181640624999996, 77.75826785702777],
            [21.09100341796875, 77.66532438103464],
            [21.06353759765625, 77.60887156405327],
            [20.87677001953125, 77.60120738507888],
            [20.81085205078125, 77.56991279737393],
            [20.73394775390625, 77.5533484348924],
            [20.8026123046875, 77.51184242284711],
            [20.73944091796875, 77.49995856364218],
            [20.7916259765625, 77.44096938520258],
            [20.93719482421875, 77.43081241060227],
            [21.195373535156246, 77.44455227128961],
            [21.51947021484375, 77.4868734645547],
            [21.76666259765625, 77.4821119055364],
            [22.07977294921875, 77.51421786012256],
            [22.16766357421875, 77.54683506440153],
            [22.401123046875, 77.57818684022912],
            [22.47528076171875, 77.60710332138389],
            [22.5274658203125, 77.59353853991924],
            [22.59063720703125, 77.55157239371562],
            [22.60711669921875, 77.51956096828667],
            [22.67303466796875, 77.49282290700539],
            [22.67578125, 77.44514932118426],
            [22.57965087890625, 77.43141010336095],
            [22.5494384765625, 77.40328829279751],
            [22.3956298828125, 77.41466413107416],
            [22.30499267578125, 77.38710522367228],
            [22.3516845703125, 77.37330351478303],
            [22.483520507812496, 77.36609672627682],
            [22.631835937499996, 77.37570487878854],
            [22.5933837890625, 77.34445207365383],
            [22.46978759765625, 77.31432953169521],
            [22.497253417968746, 77.26114248475822],
            [22.60162353515625, 77.23325294283963],
            [22.7471923828125, 77.22778927415304],
            [22.813110351562496, 77.25569055403594],
            [22.939453125, 77.2678028407533],
            [23.054809570312496, 77.24599256630367],
            [23.1976318359375, 77.24599256630367],
            [23.3074951171875, 77.27506477773778],
            [23.329467773437496, 77.30286448413767],
            [23.236083984374996, 77.31010675198132],
            [23.082275390625, 77.29441003338334],
            [23.104248046875, 77.32337369166238],
            [23.280029296875, 77.34746045532523],
            [23.323974609375, 77.37750560694711],
            [23.4832763671875, 77.42303985942378],
            [23.741455078125, 77.51243632385815],
            [23.9447021484375, 77.58822660455711],
            [24.08203125, 77.62595177758529],
            [24.0765380859375, 77.6706038330624],
            [24.235839843749996, 77.70340380409111],
            [24.2578125, 77.7559381344971],
            [24.175415039062496, 77.78735258247023],
            [24.2413330078125, 77.84300461628665],
            [24.3621826171875, 77.88342668202303],
            [24.400634765625, 77.93405519690263],
            [24.2578125, 77.95356034861001],
            [23.895263671875, 77.9592912436569],
            [23.62060546875, 77.92946122957989],
            [23.3074951171875, 78.0232940297461],
            [23.18115234375, 78.11301397527026],
            [23.2305908203125, 78.15593413416484],
            [23.1646728515625, 78.22114949821272],
            [23.0218505859375, 78.24131667606136],
            [22.8570556640625, 78.27262019424866],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.268005371093746, 80.07273895540946],
            [16.1334228515625, 80.06089434884821],
            [16.072998046875, 80.03763832275985],
            [15.9906005859375, 80.02955732601929],
            [15.94940185546875, 79.99239655019555],
            [15.86151123046875, 79.955577736471],
            [15.825805664062502, 79.92679484236052],
            [15.9356689453125, 79.89937549112445],
            [16.00433349609375, 79.87477978700144],
            [15.8148193359375, 79.88491459954226],
            [15.62530517578125, 79.8791245079624],
            [15.542907714843748, 79.83074602557404],
            [15.556640624999998, 79.77677792010641],
            [15.6005859375, 79.71174015885131],
            [15.6390380859375, 79.65024140460008],
            [15.73516845703125, 79.61960325985493],
            [15.798339843749998, 79.52714961026538],
            [15.831298828124998, 79.46656836024047],
            [15.872497558593752, 79.34182497953516],
            [15.88897705078125, 79.2919280142848],
            [16.00433349609375, 79.25409779418953],
            [16.072998046875, 79.19092540893566],
            [16.07025146484375, 79.14550385485202],
            [16.05377197265625, 79.12945820425342],
            [16.10321044921875, 79.11546386806278],
            [16.19384765625, 79.0946987961068],
            [16.2322998046875, 79.0577443677265],
            [16.268005371093746, 79.02903269499741],
            [16.226806640625, 79.01909683345978],
            [16.2432861328125, 78.99657757942212],
            [16.3092041015625, 78.9766388818902],
            [16.37237548828125, 78.94719048214627],
            [16.424560546875, 78.90921407082556],
            [16.24603271484375, 78.95508605600533],
            [16.17462158203125, 78.98818668348777],
            [16.12518310546875, 79.02066624438999],
            [16.0784912109375, 79.05565875115661],
            [15.998840332031248, 79.11650109565686],
            [15.86700439453125, 79.16462448506998],
            [15.784606933593748, 79.17443025079882],
            [15.721435546875, 79.15997658611457],
            [15.65277099609375, 79.12427719064002],
            [15.630798339843752, 79.147572556149],
            [15.67474365234375, 79.16565708406968],
            [15.729675292968748, 79.19401548509859],
            [15.776367187499998, 79.19195553132559],
            [15.80657958984375, 79.20225142138912],
            [15.790100097656248, 79.22024591357477],
            [15.776367187499998, 79.24282552289343],
            [15.6060791015625, 79.28273820068716],
            [15.622558593749998, 79.30161992399789],
            [15.55389404296875, 79.32250451406392],
            [15.520935058593748, 79.34182497953516],
            [15.474243164062498, 79.36263199072164],
            [15.463256835937502, 79.38289281749564],
            [15.422058105468748, 79.41472628840798],
            [15.427551269531248, 79.44394986169752],
            [15.43304443359375, 79.47209003442102],
            [15.37811279296875, 79.48312479864786],
            [15.37811279296875, 79.51116171238216],
            [15.273742675781248, 79.54161782632184],
            [15.29022216796875, 79.58688988734546],
            [15.31219482421875, 79.61019620638754],
            [15.16937255859375, 79.6339451553715],
            [15.100708007812502, 79.67193032546842],
            [15.0018310546875, 79.71517338062],
            [14.845275878906252, 79.77726538193505],
            [14.603576660156252, 79.80549885608586],
            [14.47723388671875, 79.81133062503643],
            [14.400329589843748, 79.78652278865157],
            [14.271240234375, 79.72399646188809],
            [14.150390625, 79.6832498172213],
            [14.1558837890625, 79.66306302431639],
            [14.0625, 79.6388860909488],
            [14.073486328125, 79.61712853649301],
            [13.963623046874998, 79.60871011161976],
            [13.7713623046875, 79.54062065318479],
            [13.82080078125, 79.50566030045525],
            [13.79058837890625, 79.48863789155779],
            [13.919677734375, 79.45601903010431],
            [13.92242431640625, 79.43740669368403],
            [13.92791748046875, 79.41169859899651],
            [13.9251708984375, 79.39149211297713],
            [14.01031494140625, 79.37428663142524],
            [14.0625, 79.33267749130214],
            [13.90869140625, 79.29805022952435],
            [13.9361572265625, 79.34791899708688],
            [13.916931152343748, 79.37833746002218],
            [13.826293945312498, 79.40665054736962],
            [13.7933349609375, 79.43085951596649],
            [13.70819091796875, 79.44898034085385],
            [13.5955810546875, 79.48262346663667],
            [13.4417724609375, 79.49364731446384],
            [13.359375, 79.48462865291765],
            [13.348388671875, 79.46003905367583],
            [13.252258300781248, 79.46757251393042],
            [13.2989501953125, 79.48913894010359],
            [13.359375, 79.50766114392374],
            [13.502197265625, 79.55706199369487],
            [13.48846435546875, 79.57298070324592],
            [13.45001220703125, 79.59234918793305],
            [13.554382324218748, 79.60524173956631],
            [13.57086181640625, 79.61762352784186],
            [13.52142333984375, 79.62356160386716],
            [13.43902587890625, 79.62554021600239],
            [13.36761474609375, 79.61613848377318],
            [13.38958740234375, 79.60078072167332],
            [13.31817626953125, 79.60078072167332],
            [13.20831298828125, 79.60474616438934],
            [13.0023193359375, 79.60127648381584],
            [12.81005859375, 79.58242108045],
            [12.752380371093748, 79.58639344688147],
            [12.5848388671875, 79.56950053732534],
            [12.56011962890625, 79.55158438854946],
            [12.540893554687498, 79.52864723963516],
            [12.43377685546875, 79.52315489638991],
            [12.44476318359375, 79.54710059837473],
            [12.45849609375, 79.57745351500424],
            [12.535400390625, 79.57894403055806],
            [12.64251708984375, 79.58490398504688],
            [12.733154296875, 79.60226793797865],
            [12.78533935546875, 79.60226793797865],
            [12.83477783203125, 79.6181184958514],
            [12.94189453125, 79.64481213897947],
            [13.040771484374998, 79.66454143061155],
            [13.0572509765625, 79.6832498172213],
            [13.22479248046875, 79.69160850996614],
            [13.18359375, 79.67537668804668],
            [13.2000732421875, 79.66306302431639],
            [13.282470703124998, 79.65172162469322],
            [13.403320312499998, 79.64826745203557],
            [13.46923828125, 79.65911958481979],
            [13.4417724609375, 79.6704529646176],
            [13.4747314453125, 79.68423354071122],
            [13.55712890625, 79.6699604645506],
            [13.645019531249998, 79.67094544145888],
            [13.74114990234375, 79.68865914833069],
            [13.76312255859375, 79.70339761390184],
            [13.86749267578125, 79.71860546904045],
            [13.94439697265625, 79.75872565672302],
            [14.01031494140625, 79.798690951326],
            [13.96087646484375, 79.83026108701324],
            [13.91143798828125, 79.86898395457625],
            [13.70819091796875, 79.89214760452481],
            [13.5186767578125, 79.86270143133373],
            [13.3648681640625, 79.86560153635602],
            [13.18634033203125, 79.83850193147936],
            [12.9583740234375, 79.82395480310161],
            [12.77435302734375, 79.78603576356588],
            [12.689208984375, 79.78993132098263],
            [12.579345703125, 79.77677792010641],
            [12.4859619140625, 79.82152827912398],
            [12.469482421875, 79.84673616824665],
            [12.29095458984375, 79.84915674581585],
            [12.19757080078125, 79.85689875738393],
            [12.14263916015625, 79.82395480310161],
            [12.117919921874998, 79.79382540840636],
            [12.11517333984375, 79.75823732027148],
            [12.17010498046875, 79.73868496075676],
            [12.2662353515625, 79.71811524006962],
            [12.23602294921875, 79.69946939522399],
            [12.18109130859375, 79.71419257577827],
            [12.1014404296875, 79.69848710894225],
            [12.087707519531248, 79.72595612953259],
            [12.0025634765625, 79.74846575209726],
            [12.02178955078125, 79.76507193471512],
            [11.97784423828125, 79.78554871550318],
            [11.90093994140625, 79.79479870063878],
            [11.91741943359375, 79.81084477038952],
            [11.9146728515625, 79.83704814509925],
            [11.815795898437498, 79.8501248169489],
            [11.7169189453125, 79.84818858331859],
            [11.65924072265625, 79.86608480736737],
            [11.55487060546875, 79.8723652556793],
            [11.406555175781248, 79.86415158648416],
            [11.3653564453125, 79.87815917387738],
            [11.2664794921875, 79.8709162634021],
            [11.241760253906248, 79.84286205607592],
            [11.3543701171875, 79.81667351349442],
            [11.255493164062498, 79.80890112255545],
            [11.3214111328125, 79.78749676989605],
            [11.17584228515625, 79.78457455044246],
            [11.120910644531248, 79.75579529243953],
            [11.151123046874998, 79.72056615371254],
            [11.18682861328125, 79.68816750691119],
            [11.2664794921875, 79.66749761579231],
            [11.2994384765625, 79.64283716222033],
            [11.14288330078125, 79.64826745203557],
            [11.1236572265625, 79.66946794125677],
            [11.07696533203125, 79.69357428743743],
            [11.02203369140625, 79.71909567488909],
            [11.0687255859375, 79.73770637402444],
            [11.01104736328125, 79.75481832001917],
            [10.9368896484375, 79.77775282076777],
            [10.81878662109375, 79.78798372605694],
            [10.68695068359375, 79.78165150370252],
            [10.5963134765625, 79.76360775444122],
            [10.651245117187498, 79.74602141926275],
            [10.76934814453125, 79.73036403011366],
            [10.70068359375, 79.71811524006962],
            [10.7281494140625, 79.69701350577613],
            [10.69793701171875, 79.6827579206795],
            [10.74462890625, 79.64678674324482],
            [10.87371826171875, 79.63740405490675],
            [10.75286865234375, 79.60821469998169],
            [10.5963134765625, 79.56552179826356],
            [10.634765625, 79.52714961026538],
            [10.73089599609375, 79.50165748162445],
            [10.862731933593748, 79.47309366766706],
            [10.82977294921875, 79.38997509071015],
            [10.7611083984375, 79.33674399926629],
            [10.83526611328125, 79.30212978427511],
            [11.05224609375, 79.23667208693278],
            [11.0577392578125, 79.20225142138912],
            [11.074218749999998, 79.12531358849391],
            [11.19781494140625, 79.0946987961068],
            [11.3653564453125, 79.0915806617124],
            [11.55487060546875, 79.12427719064002],
            [11.689453125, 79.17185063654797],
            [11.70318603515625, 79.20430943611333],
            [11.744384765625, 79.2027659614114],
            [11.865234375, 79.21870469035932],
            [11.953125, 79.23410712877187],
            [11.73614501953125, 79.12220410217347],
            [11.618041992187498, 79.07961965182861],
            [11.6729736328125, 79.04835600037643],
            [11.9036865234375, 79.00234265688229],
            [11.89544677734375, 78.98293916463774],
            [11.9915771484375, 78.95192849509922],
            [12.0684814453125, 78.94877004271677],
            [12.17559814453125, 78.95561222950204],
            [12.183837890625, 78.94087000996285],
            [12.1673583984375, 78.92452559836727],
            [12.2662353515625, 78.91977598021373],
            [12.3651123046875, 78.91185547985198],
            [12.42828369140625, 78.90445796867209],
            [12.43927001953125, 78.88753106661802],
            [12.35687255859375, 78.89917104858247],
            [12.041015625, 78.92558079600201],
            [11.95037841796875, 78.94929651336554],
            [11.6729736328125, 78.9750632552901],
            [11.480712890625, 78.99133400979767],
            [11.2884521484375, 78.98976045771468],
            [11.2939453125, 78.95719060146428],
            [11.3543701171875, 78.904986523909],
            [11.59881591796875, 78.85466260162197],
            [11.755371093749998, 78.83446998718654],
            [11.66748046875, 78.81424125380988],
            [11.56036376953125, 78.75922861640385],
            [11.45050048828125, 78.73725614078741],
            [11.480712890625, 78.72168904036175],
            [11.6729736328125, 78.70341090948182],
            [11.84051513671875, 78.69426088300975],
            [11.7608642578125, 78.67863512894228],
            [11.8048095703125, 78.64082992043338],
            [12.04925537109375, 78.58443202658671],
            [12.37060546875, 78.57790682347503],
            [12.299194335937498, 78.55558459923263],
            [12.33489990234375, 78.53540320756757],
            [12.65899658203125, 78.52775785234195],
            [12.87872314453125, 78.51901414405965],
            [13.010559082031248, 78.53048891361776],
            [13.114929199218748, 78.5474071820425],
            [13.205566406249998, 78.55830912453442],
            [13.1781005859375, 78.54304353589549],
            [12.85675048828125, 78.51081094654211],
            [12.513427734375, 78.51518670455499],
            [12.31292724609375, 78.50150699888857],
            [12.31842041015625, 78.4812315293125],
            [12.48870849609375, 78.39148914946489],
            [12.68646240234375, 78.35495591183748],
            [12.812805175781248, 78.35495591183748],
            [12.80731201171875, 78.33387021211452],
            [12.821044921875, 78.30940815087652],
            [12.889709472656248, 78.2960438968259],
            [12.84027099609375, 78.25697871945945],
            [12.903442382812498, 78.2161023779328],
            [12.96112060546875, 78.19252094561156],
            [13.05999755859375, 78.19982492727081],
            [13.11767578125, 78.2161023779328],
            [13.39508056640625, 78.19757802282507],
            [13.69171142578125, 78.18971053533215],
            [13.86474609375, 78.19982492727081],
            [13.86749267578125, 78.21946736162727],
            [13.903198242187498, 78.2161023779328],
            [13.9581298828125, 78.2256340381784],
            [13.9581298828125, 78.25138749506708],
            [14.01031494140625, 78.26368472575217],
            [14.029541015625, 78.2497096159816],
            [14.0789794921875, 78.23851771619418],
            [14.139404296875, 78.25586068450242],
            [14.161376953125, 78.27429484767518],
            [14.3206787109375, 78.29437230620164],
            [14.353637695312498, 78.31942146530486],
            [14.293212890625, 78.33387021211452],
            [14.26849365234375, 78.34996532947844],
            [14.18060302734375, 78.35606464379242],
            [14.235534667968748, 78.36437681572474],
            [14.21356201171875, 78.37932398078839],
            [14.1943359375, 78.39922407983074],
            [14.30419921875, 78.38485516080925],
            [14.37286376953125, 78.36382285303543],
            [14.460754394531248, 78.36216080890229],
            [14.490966796875, 78.38153676445982],
            [14.600830078125, 78.37046868897448],
            [14.696960449218748, 78.37268313564675],
            [14.724426269531252, 78.36770004571378],
            [14.804077148437498, 78.36770004571378],
            [14.78759765625, 78.39314706263704],
            [14.68597412109375, 78.4301129999949],
            [14.6337890625, 78.46147019458371],
            [14.529418945312498, 78.4779402935697],
            [14.26849365234375, 78.47574562104337],
            [14.315185546875, 78.49493503162991],
            [14.47998046875, 78.510263861155],
            [14.4580078125, 78.52229380487461],
            [14.30419921875, 78.5326733007904],
            [14.30419921875, 78.54249796479854],
            [14.422302246093748, 78.54195236806919],
            [14.58709716796875, 78.53212724247996],
            [14.628295898437498, 78.54086109770857],
            [14.61181640625, 78.55994353251887],
            [14.567871093749998, 78.59258335812311],
            [14.5733642578125, 78.6305470270536],
            [14.62554931640625, 78.63379524856055],
            [14.631042480468752, 78.65164410346165],
            [14.578857421875, 78.66190816604582],
            [14.543151855468752, 78.67162356332383],
            [14.617309570312502, 78.68564241142728],
            [14.650268554687498, 78.66568735284014],
            [14.716186523437498, 78.64677897900748],
            [14.749145507812498, 78.62567297711507],
            [14.86175537109375, 78.59204011474826],
            [14.938659667968748, 78.58225736767923],
            [15.029296875, 78.60018608562585],
            [15.03753662109375, 78.62188062387717],
            [15.1556396484375, 78.63974794295079],
            [15.281982421875002, 78.64731965002213],
            [15.2490234375, 78.67054448060111],
            [15.25177001953125, 78.69210687275711],
            [15.238037109375, 78.72007743900963],
            [15.213317871093748, 78.75173058148194],
            [15.27099609375, 78.7827617628182],
            [15.303955078125, 78.81264270840497],
            [15.40557861328125, 78.81424125380988],
            [15.361633300781248, 78.77634845965972],
            [15.3973388671875, 78.75440901768461],
            [15.427551269531248, 78.72652248089834],
            [15.4193115234375, 78.67108403464245],
            [15.3863525390625, 78.64299357031803],
            [15.31219482421875, 78.6186290422947],
            [15.10894775390625, 78.60398557417541],
            [15.10345458984375, 78.59258335812311],
            [15.16937255859375, 78.57083370028856],
            [15.16937255859375, 78.52065409002107],
            [15.27099609375, 78.4812315293125],
            [15.413818359374998, 78.44607715012019],
            [15.62530517578125, 78.44772737329717],
            [15.831298828124998, 78.46366754889625],
            [16.0015869140625, 78.47903747521427],
            [16.0784912109375, 78.510263861155],
            [16.182861328125, 78.52611890762232],
            [16.3531494140625, 78.55394957680417],
            [16.43829345703125, 78.60072894622596],
            [16.4190673828125, 78.63541901587135],
            [16.48223876953125, 78.65326535434987],
            [16.53167724609375, 78.69479932229297],
            [16.59759521484375, 78.66784632995427],
            [16.70745849609375, 78.66244812600279],
            [16.820068359375, 78.66082816999239],
            [16.72393798828125, 78.65434606128456],
            [16.5948486328125, 78.62133875728088],
            [16.49871826171875, 78.58551920277401],
            [16.424560546875, 78.54958839015038],
            [16.435546875, 78.52120068731602],
            [16.32293701171875, 78.47190395179248],
            [16.226806640625, 78.46476607125284],
            [16.13067626953125, 78.46201957186467],
            [16.1444091796875, 78.44552702405667],
            [16.24603271484375, 78.43892349563538],
            [16.4080810546875, 78.42294959599306],
            [16.534423828125, 78.43121467403355],
            [16.55364990234375, 78.4141270931157],
            [16.6717529296875, 78.39314706263704],
            [16.81732177734375, 78.37600402593652],
            [16.88323974609375, 78.37877071993],
            [16.9683837890625, 78.40364175752345],
            [17.05078125, 78.41192043142786],
            [17.20184326171875, 78.42901122247586],
            [17.281494140624996, 78.4284602949083],
            [17.039794921874996, 78.39480474227551],
            [16.86676025390625, 78.35883601823689],
            [16.65802001953125, 78.3471918724007],
            [16.4959716796875, 78.35107453002588],
            [16.25701904296875, 78.37102233963373],
            [16.072998046875, 78.37046868897448],
            [15.809326171875, 78.36216080890229],
            [15.5401611328125, 78.30829503801115],
            [15.529174804687498, 78.28489551177034],
            [15.57861328125, 78.25977334775196],
            [15.5126953125, 78.2620085782465],
            [15.3369140625, 78.25753769758416],
            [15.086975097656252, 78.19870152776443],
            [14.944152832031248, 78.14408991261553],
            [14.919433593750002, 78.11753909616459],
            [14.859008789062502, 78.13166915851163],
            [14.69146728515625, 78.1344931829381],
            [14.447021484374998, 78.11527674798776],
            [14.188842773437498, 78.11923557873816],
            [14.12567138671875, 78.1067891606375],
            [14.16412353515625, 78.06028464810741],
            [14.21630859375, 78.01246007386713],
            [13.993835449218748, 78.10339245316887],
            [13.8702392578125, 78.10961902001362],
            [13.62579345703125, 78.0784540338083],
            [13.52142333984375, 78.04777736806022],
            [13.55987548828125, 78.00390013000053],
            [13.55987548828125, 77.9208428959737],
            [13.59832763671875, 77.85572298602627],
            [13.5955810546875, 77.82621944616582],
            [13.7603759765625, 77.8082514368471],
            [13.705444335937498, 77.78851455494909],
            [13.65325927734375, 77.76292599260653],
            [13.6669921875, 77.72911492528318],
            [13.796081542968748, 77.71977155707317],
            [13.9691162109375, 77.71159036383126],
            [14.0460205078125, 77.72619587489778],
            [14.12567138671875, 77.75011191788302],
            [14.2327880859375, 77.74486598885208],
            [14.46624755859375, 77.73903458427814],
            [14.595336914062498, 77.71100578757442],
            [14.7052001953125, 77.67881189234271],
            [14.669494628906248, 77.64242102587818],
            [14.762878417968748, 77.63007111418722],
            [14.8150634765625, 77.59530868771755],
            [14.91668701171875, 77.58350300490368],
            [14.979858398437502, 77.59117795607723],
            [15.092468261718748, 77.59412861681025],
            [15.292968749999998, 77.58232182854005],
            [15.55389404296875, 77.56873034851738],
            [15.858764648437498, 77.54920395081058],
            [15.9136962890625, 77.521341504243],
            [15.73516845703125, 77.53913311966782],
            [15.589599609374998, 77.54091090753052],
            [15.391845703125, 77.54209596075547],
            [15.106201171874998, 77.56991279737393],
            [14.96063232421875, 77.57641428752758],
            [14.845275878906252, 77.56222490054382],
            [14.73541259765625, 77.54624277349254],
            [14.65850830078125, 77.50827843300371],
            [14.622802734374998, 77.54801956304901],
            [14.581604003906248, 77.57700516608874],
            [14.4580078125, 77.59117795607723],
            [14.27398681640625, 77.59943806583738],
            [13.919677734375, 77.55394039319563],
            [13.8482666015625, 77.52490182620839],
            [13.86474609375, 77.488063575796],
            [13.9306640625, 77.45469832108796],
            [13.8372802734375, 77.40388727325812],
            [13.95263671875, 77.37150219688968],
            [14.0789794921875, 77.2998456728252],
            [14.2822265625, 77.17119655671203],
            [14.622802734374998, 77.14861267795314],
            [15.01007080078125, 77.13516590007856],
            [14.930419921874998, 77.10700519647958],
            [15.040283203125002, 77.07878389624943],
            [15.09521484375, 77.03880242313147],
            [15.172119140624998, 77.00178853692064],
            [15.18310546875, 76.99869936128846],
            [15.49072265625, 76.987572353592],
            [15.765380859375002, 76.99128239562147],
            [16.029052734375, 77.00611217093758],
            [16.051025390625, 76.98633544185543],
            [15.787353515625, 76.9615729286458],
            [15.5072021484375, 76.92682760674583],
            [15.4248046875, 76.8683004699443],
            [15.589599609374998, 76.83331096075095],
            [15.7489013671875, 76.76682990244767],
            [16.204833984375, 76.6936992188836],
            [16.221313476562496, 76.64937678661775],
            [16.171875, 76.59599850152047],
            [16.221313476562496, 76.55263410642529],
            [16.3421630859375, 76.5052869685929],
            [16.5069580078125, 76.45263011205448],
            [16.6278076171875, 76.44619476618422],
            [16.6552734375, 76.4796258377915],
            [16.5728759765625, 76.53857498786215],
            [16.787109375, 76.54752336803074],
            [16.973876953125, 76.58325853729983],
            [17.11669921875, 76.63033711371293],
            [17.2265625, 76.70886219472291],
            [17.1771240234375, 76.77437257861983],
            [16.9464111328125, 76.77688586554326],
            [16.885986328125, 76.81076935998476],
            [17.0672607421875, 76.83205964303173],
            [17.149658203125, 76.87329150864338],
            [17.369384765625, 76.93428068373241],
            [17.3583984375, 76.98138663933625],
            [17.24853515625, 77.02832568582058],
            [17.4298095703125, 77.05296359555017],
            [17.4078369140625, 77.15227758214057],
            [17.51220703125, 77.23143197537199],
            [17.457275390625, 77.27022393917454],
            [17.655029296875, 77.3703011778519],
            [17.6715087890625, 77.42423594425841],
            [17.7703857421875, 77.46722053896585],
            [17.7264404296875, 77.53498397703103],
            [17.841796875, 77.50530767686138],
            [18.116455078125, 77.47556185051579],
            [18.30322265625, 77.49817502515819],
            [18.4515380859375, 77.62712886867638],
            [18.380126953125, 77.67998403298438],
            [18.5284423828125, 77.7396178476677],
            [18.4844970703125, 77.85456731235998],
            [18.429565429687496, 77.9064655335818],
            [18.533935546875, 77.96730998426993],
            [18.5174560546875, 78.02899223583766],
            [18.7811279296875, 78.00960742819078],
            [19.072265625, 78.05630645870075],
            [19.127197265625, 78.11414541470135],
            [19.0008544921875, 78.17058224978182],
            [19.072265625, 78.1852125072108],
            [19.171142578125, 78.22787567688711],
            [19.0228271484375, 78.29938637197219],
            [19.105224609375, 78.31942146530486],
            [19.0887451171875, 78.43341771170695],
            [19.017333984375, 78.49603061678283],
            [19.2535400390625, 78.5474071820425],
            [19.5391845703125, 78.5561295554978],
            [19.731445312499996, 78.58932351493806],
            [20.033569335937496, 78.58497562745161],
            [20.1873779296875, 78.6278394760451],
            [20.302734375, 78.65542666663728],
            [20.470275878906246, 78.6640678536084],
            [20.832824707031246, 78.68725886862576],
            [20.865783691406246, 78.67324199721936],
            [21.005859375, 78.65164410346165],
            [21.10198974609375, 78.63974794295079],
            [21.038818359375, 78.61266542765814],
            [21.0223388671875, 78.58116988494068],
            [20.93719482421875, 78.56702330558686],
            [20.78887939453125, 78.57409875555015],
            [20.67626953125, 78.56321165744065],
            [20.52520751953125, 78.54468009540336],
            [20.159912109375, 78.51956081839404],
            [20.0885009765625, 78.47958602738595],
            [20.2423095703125, 78.45322643826017],
            [20.49774169921875, 78.4339684064646],
            [20.60211181640625, 78.39701461857327],
            [20.61309814453125, 78.34552748562092],
            [20.687255859375, 78.33164847573174],
            [20.54443359375, 78.32498076279465],
            [20.5938720703125, 78.29771525207218],
            [20.711975097656246, 78.29130711195623],
            [20.687255859375, 78.26927017989635],
            [20.65704345703125, 78.24131667606136],
            [20.617218017578125, 78.21638282942934],
            [20.515594482421875, 78.20319449328694],
            [20.501861572265625, 78.22311169161088],
            [20.441436767578125, 78.22535380375216],
            [20.3741455078125, 78.19645441244333],
            [20.401611328125, 78.18127534732685],
            [20.49362182617187, 78.19027267013766],
            [20.591125488281246, 78.19308294854895],
            [20.696868896484375, 78.18830508291575],
            [20.718841552734375, 78.1669218958964],
            [20.702362060546875, 78.15311513975685],
            [20.792999267578125, 78.14324345018578],
            [20.820465087890625, 78.14973147346579],
            [20.798492431640625, 78.16297872773029],
            [20.79574584960937, 78.17367860051183],
            [20.78201293945312, 78.1857748530668],
            [20.72845458984375, 78.19673532492452],
            [20.7916259765625, 78.21554145519839],
            [20.9124755859375, 78.21778498821122],
            [20.9674072265625, 78.1953306966097],
            [21.0882568359375, 78.19083477856715],
            [21.242065429687496, 78.21890659680464],
            [21.6815185546875, 78.2469126258077],
            [21.8682861328125, 78.24915027045311],
            [22.137451171875, 78.24915027045311],
            [22.203369140625, 78.30272790586028],
            [22.225341796875, 78.36160674216556],
            [22.208862304687496, 78.43562033557052],
            [22.0989990234375, 78.54304353589549],
            [22.005615234375, 78.5909535514148],
            [21.785888671874996, 78.59855735075935],
            [21.6375732421875, 78.58225736767923],
            [21.5716552734375, 78.55830912453442],
            [21.3958740234375, 78.6104960760832],
            [21.362915039062496, 78.64948207998829],
            [21.3519287109375, 78.65056314253196],
            [21.3848876953125, 78.66514754513587],
            [21.42059326171875, 78.69426088300975],
            [21.390380859375, 78.71309120612437],
            [21.42608642578125, 78.74315535320092],
            [21.4947509765625, 78.77420988766578],
            [21.55242919921875, 78.82010065787367],
            [21.478271484375, 78.84669614356417],
            [21.32171630859375, 78.8525387623079],
            [21.21185302734375, 78.86156232056146],
            [21.074523925781246, 78.86368446240185],
            [21.0992431640625, 78.88700169026615],
            [21.2200927734375, 78.90022863156855],
            [21.195373535156246, 78.91185547985198],
            [21.09649658203125, 78.91449626766514],
            [20.95916748046875, 78.90710049623647],
            [20.7806396484375, 78.92188716988336],
            [20.66802978515625, 78.92505320959488],
            [20.6103515625, 78.9081573332438],
            [20.5389404296875, 78.896526655776],
            [20.47576904296875, 78.92030381487585],
            [20.5499267578125, 78.94666391241097],
            [20.489501953125, 78.95403363474128],
            [20.33843994140625, 78.9761136977356],
            [20.357666015625, 78.99605333348474],
            [20.42633056640625, 78.99762599729365],
            [20.52520751953125, 79.01648065615825],
            [20.58563232421875, 79.01019931854502],
            [20.7037353515625, 79.01805043640742],
            [20.79986572265625, 79.04470282018023],
            [20.8245849609375, 79.067124784841],
            [20.76690673828125, 79.08326133006533],
            [20.698242187499996, 79.08638181368917],
            [20.591125488281246, 79.09989373016691],
            [20.50048828125, 79.12116741154314],
            [20.39886474609375, 79.1258317508299],
            [20.38238525390625, 79.11442654281016],
            [20.289001464843746, 79.11390784355915],
            [20.25054931640625, 79.12531358849391],
            [20.115966796875, 79.13256564188187],
            [20.08575439453125, 79.1185752579048],
            [20.1324462890625, 79.10456708049746],
            [20.05279541015625, 79.08638181368917],
            [20.072021484375, 79.05409428103987],
            [20.0390625, 79.04209266880953],
            [20.0775146484375, 79.02014313203155],
            [20.03082275390625, 78.99552906287806],
            [19.94842529296875, 79.00443830893225],
            [19.94293212890625, 79.02014313203155],
            [19.95391845703125, 79.04992128064299],
            [19.93743896484375, 79.0676456861466],
            [19.879760742187496, 79.07857895192905],
            [19.830322265625, 79.07753815405617],
            [19.81658935546875, 79.09417916819488],
            [19.808349609375, 79.12790415625976],
            [19.86328125, 79.1501578849913],
            [19.77813720703125, 79.1615261046032],
            [19.5721435546875, 79.1496408679084],
            [19.50897216796875, 79.17185063654797],
            [19.261779785156246, 79.20173685713756],
            [19.18212890625, 79.17391437653055],
            [19.09149169921875, 79.13152992686338],
            [19.0118408203125, 79.12790415625976],
            [18.9239501953125, 79.13929541302905],
            [18.951416015625, 79.22846209443622],
            [18.87176513671875, 79.24743831828498],
            [18.6932373046875, 79.25409779418953],
            [18.8140869140625, 79.28018409166839],
            [18.885498046875, 79.30111003970381],
            [18.90472412109375, 79.34080897496264],
            [18.91571044921875, 79.36972762383652],
            [18.9239501953125, 79.43337813591477],
            [18.8580322265625, 79.46204849716617],
            [18.797607421875, 79.48512989042322],
            [18.78662109375, 79.52814805337702],
            [18.80584716796875, 79.55158438854946],
            [18.7591552734375, 79.56303432422045],
            [18.64654541015625, 79.5764597208422],
            [18.43505859375, 79.61217733910934],
            [18.31146240234375, 79.63740405490675],
            [18.174133300781246, 79.63789809021614],
            [18.020324707031246, 79.59681378292655],
            [17.97637939453125, 79.54560557885473],
            [17.95166015625, 79.4841273917873],
            [17.8692626953125, 79.43589616249568],
            [17.73193359375, 79.39907400859646],
            [17.778625488281246, 79.41674427250786],
            [17.82257080078125, 79.44797443466707],
            [17.81707763671875, 79.49414812690668],
            [17.7923583984375, 79.54710059837473],
            [17.74017333984375, 79.56154155832456],
            [17.7813720703125, 79.57944082222981],
            [17.778625488281246, 79.60425056584538],
            [17.89398193359375, 79.63246242031433],
            [17.946166992187496, 79.66109149053503],
            [17.98736572265625, 79.67980605420696],
            [18.050537109375, 79.69062548215324],
            [18.08349609375, 79.71517338062],
            [18.03955078125, 79.75139819059902],
            [17.93243408203125, 79.79187854841007],
            [17.8802490234375, 79.81861569462016],
            [17.778625488281246, 79.82055750923621],
            [17.7813720703125, 79.83801735888473],
            [17.72369384765625, 79.85351234654208],
            [17.6220703125, 79.89407554127064],
            [17.47100830078125, 79.9003388228039],
            [17.42156982421875, 79.91622067473054],
            [17.42706298828125, 79.93447825267488],
            [17.3199462890625, 79.93447825267488],
            [17.1661376953125, 79.95605676110063],
            [17.02056884765625, 79.96228202410644],
            [16.89697265625, 79.95126549716151],
            [16.8475341796875, 79.93207781022026],
            [16.78436279296875, 79.92295096079931],
            [16.8255615234375, 79.90804220371632],
            [16.85577392578125, 79.88008975094162],
            [16.78436279296875, 79.9003388228039],
            [16.71295166015625, 79.92535355685585],
            [16.776123046875, 79.94503347207069],
            [16.7431640625, 79.95366141182691],
            [16.67449951171875, 79.96754657143889],
            [16.59210205078125, 79.99859951942864],
            [16.61407470703125, 80.03336095597147],
            [16.57562255859375, 80.05520396941563],
            [16.3970947265625, 80.05425525934193],
            [16.268005371093746, 80.07273895540946],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.4425048828125, 78.92399796231813],
            [10.382080078125, 78.91238368710717],
            [10.4864501953125, 78.85413167925155],
            [10.426025390625, 78.79664484485679],
            [10.5633544921875, 78.7098653506575],
            [10.799560546875, 78.62242246500917],
            [10.9259033203125, 78.49274355251805],
            [11.0797119140625, 78.4246030774096],
            [11.2225341796875, 78.43231624460115],
            [11.4752197265625, 78.3959097323091],
            [11.634521484374998, 78.32720375114681],
            [11.6839599609375, 78.28824112627706],
            [11.744384765625, 78.25026893525931],
            [11.9586181640625, 78.21329750102116],
            [12.19482421875, 78.18633717253729],
            [12.205810546875, 78.2469126258077],
            [12.095947265625, 78.31274686251191],
            [11.9256591796875, 78.41467869377503],
            [11.964111328125, 78.45432594136061],
            [11.678466796874998, 78.47190395179248],
            [11.49169921875, 78.55503961736274],
            [11.3873291015625, 78.56375625536087],
            [11.376342773437498, 78.60072894622596],
            [11.271972656249998, 78.65164410346165],
            [11.1785888671875, 78.69802942652852],
            [11.239013671875, 78.72383748863287],
            [11.1785888671875, 78.778486629468],
            [11.041259765625, 78.80517984727395],
            [11.09619140625, 78.84138204837069],
            [10.986328125, 78.87746865669968],
            [10.689697265625, 78.90710049623647],
            [10.4425048828125, 78.92399796231813],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.149848937988281, 78.56253087405628],
            [12.147789001464844, 78.56116918733211],
            [12.153282165527344, 78.5573556134832],
            [12.168045043945312, 78.55279129647461],
            [12.171478271484373, 78.54747535100081],
            [12.191734313964844, 78.54420278937413],
            [12.208900451660156, 78.53874646956817],
            [12.2222900390625, 78.53465254712818],
            [12.230186462402344, 78.53465254712818],
            [12.228813171386719, 78.53895112782652],
            [12.233619689941406, 78.54086109770857],
            [12.236366271972656, 78.54379365430299],
            [12.220573425292969, 78.54549828866153],
            [12.22126007080078, 78.55136031992946],
            [12.213363647460936, 78.55306384342664],
            [12.205467224121094, 78.5571512794863],
            [12.207183837890625, 78.55994353251887],
            [12.201690673828123, 78.5608968307932],
            [12.185211181640623, 78.56110109879724],
            [12.168045043945312, 78.5608968307932],
            [12.149848937988281, 78.56253087405628],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.0225830078125, 80.54696890970762],
            [19.92095947265625, 80.54381071525627],
            [19.82208251953125, 80.51895992329882],
            [19.758911132812496, 80.49767252311632],
            [19.69573974609375, 80.51171845895405],
            [19.62982177734375, 80.51307665083597],
            [19.52545166015625, 80.4808810480218],
            [19.41009521484375, 80.47860967608835],
            [19.3304443359375, 80.4444745908024],
            [19.39361572265625, 80.38318448926586],
            [19.5611572265625, 80.37629946673017],
            [19.7479248046875, 80.36803099414996],
            [19.6600341796875, 80.34825003639118],
            [19.671020507812496, 80.3108788799031],
            [19.71771240234375, 80.28356773265746],
            [19.6490478515625, 80.25339090652375],
            [19.64630126953125, 80.22591928341522],
            [19.43756103515625, 80.26965148212638],
            [19.3743896484375, 80.3251983206514],
            [19.18212890625, 80.34871051535902],
            [19.02557373046875, 80.35699541661764],
            [18.94866943359375, 80.3399576927017],
            [18.99810791015625, 80.29005523537177],
            [19.02557373046875, 80.25989836358443],
            [19.1217041015625, 80.19275853777059],
            [19.215087890624996, 80.17402739120708],
            [19.21234130859375, 80.16136384613061],
            [19.24530029296875, 80.13881096399338],
            [19.28924560546875, 80.11479244077701],
            [19.27825927734375, 80.09638626233591],
            [19.080505371093746, 80.15385189901069],
            [19.03106689453125, 80.1796504516485],
            [18.91021728515625, 80.19837098393474],
            [18.709716796875, 80.21378881673729],
            [18.6492919921875, 80.20631650536714],
            [18.643798828125, 80.1796504516485],
            [18.51470947265625, 80.19696817063307],
            [18.36090087890625, 80.20211084766329],
            [18.20709228515625, 80.20491481812816],
            [18.01483154296875, 80.19930608236709],
            [17.9022216796875, 80.19883854419378],
            [17.88299560546875, 80.17402739120708],
            [17.74566650390625, 80.15526082154044],
            [17.63580322265625, 80.13128196776606],
            [17.704467773437496, 80.11243456832592],
            [17.8582763671875, 80.12327617312708],
            [17.88848876953125, 80.08882519784039],
            [17.99560546875, 80.09213386650764],
            [18.04779052734375, 80.06373832971286],
            [18.00933837890625, 80.05425525934193],
            [18.03131103515625, 80.02717932764911],
            [18.09722900390625, 80.00384521314905],
            [18.017578125, 80.00289165326058],
            [17.95440673828125, 79.99191924102057],
            [18.0010986328125, 79.97615539101676],
            [17.940673828125, 79.96419672124117],
            [17.97088623046875, 79.95174472533215],
            [18.08074951171875, 79.93831778248897],
            [18.116455078125, 79.92343152537035],
            [18.02581787109375, 79.90948594015765],
            [18.06976318359375, 79.88829063904491],
            [18.1494140625, 79.88105490282338],
            [18.187866210937496, 79.85254459511796],
            [18.25103759765625, 79.81764464987518],
            [18.314208984375, 79.79625846680386],
            [18.369140624999996, 79.77385266616162],
            [18.4844970703125, 79.75530681775093],
            [18.6273193359375, 79.73574892357854],
            [18.657531738281246, 79.70830580337244],
            [18.83331298828125, 79.7029066676203],
            [18.95416259765625, 79.71419257577827],
            [18.96514892578125, 79.74846575209726],
            [19.1107177734375, 79.73183291452573],
            [19.22607421875, 79.74064185728223],
            [19.4183349609375, 79.74602141926275],
            [19.4183349609375, 79.71419257577827],
            [19.489746093749996, 79.69996050361931],
            [19.57489013671875, 79.68374169056482],
            [19.65728759765625, 79.66207730391322],
            [19.88800048828125, 79.66799023200393],
            [19.8907470703125, 79.6802980899603],
            [19.8028564453125, 79.695048377189],
            [19.6435546875, 79.69897826366532],
            [19.599609375, 79.71713471275723],
            [19.624328613281246, 79.73672769496785],
            [19.81109619140625, 79.73966345517321],
            [20.06927490234375, 79.74455454281238],
            [20.247802734375, 79.76507193471512],
            [20.4345703125, 79.77043882322829],
            [20.54443359375, 79.76507193471512],
            [20.69000244140625, 79.78603576356588],
            [20.82183837890625, 79.78603576356588],
            [21.1981201171875, 79.78554871550318],
            [21.36566162109375, 79.8025817332276],
            [21.566162109375, 79.81230226556366],
            [21.497497558593746, 79.78213873561926],
            [21.5606689453125, 79.76067877218138],
            [21.618347167968746, 79.74455454281238],
            [21.74468994140625, 79.74064185728223],
            [21.8408203125, 79.7337911037681],
            [21.772155761718746, 79.70045158885236],
            [21.57989501953125, 79.70241569818283],
            [21.38763427734375, 79.71468298976447],
            [21.014099121093746, 79.70781508859228],
            [20.70648193359375, 79.69455703711273],
            [20.478515625, 79.695048377189],
            [20.36865234375, 79.6699604645506],
            [20.4840087890625, 79.64530582496445],
            [20.56915283203125, 79.61663352180382],
            [20.6707763671875, 79.60177222258392],
            [20.85479736328125, 79.5878826980586],
            [21.016845703125, 79.5699977742049],
            [21.0443115234375, 79.55208247003785],
            [20.90423583984375, 79.56850599323724],
            [20.69000244140625, 79.57397482543338],
            [20.4180908203125, 79.58490398504688],
            [20.3466796875, 79.61118681945469],
            [20.2532958984375, 79.62949632087125],
            [20.06378173828125, 79.63789809021614],
            [19.73419189453125, 79.62999072905669],
            [19.5831298828125, 79.61069152459821],
            [19.588623046875, 79.57745351500424],
            [19.775390625, 79.52015787224302],
            [19.88250732421875, 79.50265832784035],
            [20.0006103515625, 79.45551642058146],
            [20.154418945312496, 79.44294348135637],
            [20.5224609375, 79.4192662178081],
            [20.711975097656246, 79.39957927779837],
            [20.6488037109375, 79.37985612714793],
            [20.69000244140625, 79.3529947140132],
            [20.841064453125, 79.34487241894814],
            [21.0443115234375, 79.34690356672326],
            [21.33819580078125, 79.36364593916083],
            [21.57989501953125, 79.35451696285723],
            [21.73095703125, 79.34080897496264],
            [21.87652587890625, 79.34284088838446],
            [22.181396484374996, 79.36161794673743],
            [22.543945312499996, 79.37226064449025],
            [22.813110351562496, 79.36567354943885],
            [22.664794921874996, 79.32810083761001],
            [22.71697998046875, 79.27762938108187],
            [22.82684326171875, 79.24538842837468],
            [23.0657958984375, 79.23513318451225],
            [23.2635498046875, 79.20945277733365],
            [23.752441406249996, 79.20019301899593],
            [24.007873535156246, 79.20688140946625],
            [24.0875244140625, 79.26177674011768],
            [24.18365478515625, 79.27916227962956],
            [24.4500732421875, 79.30773666252436],
            [24.8785400390625, 79.34080897496264],
            [25.103759765625, 79.33115215566411],
            [25.51025390625, 79.3788437062486],
            [25.806884765624996, 79.43388178869604],
            [25.78765869140625, 79.46907856700767],
            [25.8837890625, 79.48663346120176],
            [25.894775390625, 79.51765970454443],
            [25.697021484375, 79.54859540647792],
            [25.78765869140625, 79.58142775471434],
            [26.216125488281246, 79.62306689251965],
            [26.4385986328125, 79.67242273263885],
            [26.58966064453125, 79.70192470558835],
            [26.60888671875, 79.68915076656356],
            [26.69677734375, 79.69750472999873],
            [26.72149658203125, 79.71223068850637],
            [26.65008544921875, 79.72497634192727],
            [26.6802978515625, 79.74944332381828],
            [26.7791748046875, 79.76019052786685],
            [26.941223144531246, 79.77141432196085],
            [27.0318603515625, 79.78506164446237],
            [27.1197509765625, 79.80938706890305],
            [27.1966552734375, 79.86366822424414],
            [27.12799072265625, 79.93639819880528],
            [27.19390869140625, 79.96419672124117],
            [27.17193603515625, 79.98857744581159],
            [27.1856689453125, 80.01480468308004],
            [27.279052734375, 80.04001385239243],
            [27.25982666015625, 80.07084475783196],
            [27.17742919921875, 80.08929793175587],
            [26.99615478515625, 80.08882519784039],
            [26.8780517578125, 80.135517728182],
            [26.72149658203125, 80.1585475311892],
            [26.42486572265625, 80.17402739120708],
            [26.202392578124996, 80.18339738832013],
            [26.202392578124996, 80.19790340158892],
            [26.136474609375, 80.20164344198312],
            [26.021118164062496, 80.20398024960765],
            [25.916748046875, 80.19837098393474],
            [25.85906982421875, 80.20911928399478],
            [25.65582275390625, 80.21985591247558],
            [25.58441162109375, 80.21658947775661],
            [25.50750732421875, 80.23058088167474],
            [25.444335937499996, 80.23803485977297],
            [25.38665771484375, 80.23058088167474],
            [25.32623291015625, 80.23337678374968],
            [25.3289794921875, 80.25013556354762],
            [25.39764404296875, 80.2650083468157],
            [25.3509521484375, 80.27290037180792],
            [25.2850341796875, 80.28124972719019],
            [25.095520019531246, 80.2677944912469],
            [25.0323486328125, 80.24967042666049],
            [24.85931396484375, 80.25153084236514],
            [24.89501953125, 80.26640151775867],
            [24.88128662109375, 80.29237116079634],
            [24.933471679687496, 80.32842884069753],
            [24.8785400390625, 80.34134023987535],
            [24.80712890625, 80.36481357693512],
            [24.686279296875, 80.36159509514302],
            [24.63134765625, 80.35377434799929],
            [24.71099853515625, 80.3376530117995],
            [24.697265625, 80.29468653914243],
            [24.63134765625, 80.29237116079634],
            [24.5709228515625, 80.29607550360463],
            [24.62860107421875, 80.306255229086],
            [24.61761474609375, 80.32427511863115],
            [24.56817626953125, 80.34640790288996],
            [24.532470703125, 80.36251477001962],
            [24.463806152343746, 80.36619260036366],
            [24.40887451171875, 80.37675862010343],
            [24.43634033203125, 80.39144007579924],
            [24.3402099609375, 80.41159085463012],
            [24.33197021484375, 80.43078663379187],
            [24.26055908203125, 80.44629818553715],
            [24.158935546875, 80.45495555310335],
            [24.2303466796875, 80.46315010000394],
            [24.224853515625, 80.47315619235009],
            [24.093017578125, 80.48769194319816],
            [23.96942138671875, 80.49268353079003],
            [23.9447021484375, 80.4808810480218],
            [24.01885986328125, 80.46269503030888],
            [24.1094970703125, 80.44401863823823],
            [24.0765380859375, 80.40930310337673],
            [24.027099609375, 80.38501967106059],
            [24.1424560546875, 80.3675714282845],
            [24.0655517578125, 80.3597554845853],
            [24.0380859375, 80.3399576927017],
            [24.15618896484375, 80.31873407385497],
            [24.1314697265625, 80.30810495159326],
            [24.11224365234375, 80.28820210105133],
            [23.98590087890625, 80.306255229086],
            [23.8787841796875, 80.30949201405532],
            [23.8018798828125, 80.30718013404172],
            [23.8018798828125, 80.29190801948172],
            [23.71673583984375, 80.29607550360463],
            [23.67279052734375, 80.2677944912469],
            [23.62060546875, 80.23524027823389],
            [23.68927001953125, 80.19135492907283],
            [23.631591796874996, 80.17777645229079],
            [23.57940673828125, 80.15056363665231],
            [23.53271484375, 80.1641793630659],
            [23.55194091796875, 80.1843339012762],
            [23.4722900390625, 80.20725085317706],
            [23.35968017578125, 80.20538206927905],
            [23.30474853515625, 80.21752285504051],
            [23.214111328125, 80.21518924647346],
            [23.214111328125, 80.19275853777059],
            [23.170166015625, 80.17449610131989],
            [23.0712890625, 80.16746312452526],
            [23.0712890625, 80.19041907943301],
            [23.11798095703125, 80.21472245861308],
            [23.104248046875, 80.23524027823389],
            [23.203125, 80.23803485977297],
            [23.30474853515625, 80.25199589135971],
            [23.3734130859375, 80.31919594760542],
            [23.3404541015625, 80.34410474627124],
            [23.302001953125, 80.36527327313604],
            [23.29376220703125, 80.38823040447387],
            [23.376159667968746, 80.41525013220961],
            [23.3514404296875, 80.43945792695374],
            [23.16741943359375, 80.46087453638266],
            [22.928466796875, 80.48633015053716],
            [22.884521484375, 80.50809567195031],
            [22.74169921875, 80.5166975541433],
            [22.664794921874996, 80.50175249648234],
            [22.7032470703125, 80.48496816471564],
            [22.7362060546875, 80.44538643127218],
            [22.7581787109375, 80.40930310337673],
            [22.70599365234375, 80.39281532418005],
            [22.664794921874996, 80.42027938004773],
            [22.56317138671875, 80.43124321182913],
            [22.42034912109375, 80.42667646010705],
            [22.27203369140625, 80.39831436809342],
            [22.225341796875, 80.36573294761187],
            [22.2637939453125, 80.33304201599711],
            [22.45880126953125, 80.29746427119082],
            [22.3681640625, 80.27800359940379],
            [22.39288330078125, 80.25106577139894],
            [22.3956298828125, 80.1955651585234],
            [22.27752685546875, 80.18152409697],
            [22.2802734375, 80.20117601422328],
            [22.1868896484375, 80.20117601422328],
            [22.203369140625, 80.1843339012762],
            [22.29400634765625, 80.1726211280279],
            [22.21710205078125, 80.15713907439763],
            [22.22259521484375, 80.13269408795529],
            [22.27752685546875, 80.11432091082604],
            [22.27752685546875, 80.09118864420088],
            [22.3516845703125, 80.06421224819486],
            [22.2802734375, 80.07368592004315],
            [22.225341796875, 80.04191387236658],
            [22.1868896484375, 80.06184243199519],
            [22.1868896484375, 80.08031216901536],
            [22.11273193359375, 80.10913261141346],
            [22.093505859375, 80.13975168855498],
            [22.05230712890625, 80.15291250641043],
            [21.95892333984375, 80.13692924827447],
            [21.939697265625, 80.14915404852314],
            [21.72821044921875, 80.14633427301946],
            [21.8792724609375, 80.1819924529898],
            [21.8902587890625, 80.21938934507308],
            [21.86553955078125, 80.24827488414111],
            [21.785888671874996, 80.27336441119591],
            [21.62109375, 80.28264059618424],
            [21.5771484375, 80.25850427082689],
            [21.4727783203125, 80.27243631049461],
            [21.2310791015625, 80.2492052677977],
            [21.280517578125, 80.2789311740419],
            [21.20086669921875, 80.30440515693004],
            [21.121215820312496, 80.29653844800713],
            [21.074523925781246, 80.26825877186884],
            [21.09100341796875, 80.23989747410172],
            [21.03057861328125, 80.24501784896171],
            [20.9014892578125, 80.21938934507308],
            [20.88775634765625, 80.24269073571021],
            [20.885009765625, 80.28495827310319],
            [20.805358886718746, 80.31919594760542],
            [20.60760498046875, 80.30301737331749],
            [20.5938720703125, 80.33442554353952],
            [20.50872802734375, 80.36711184069895],
            [20.4510498046875, 80.40747251304839],
            [20.302734375, 80.41936516583996],
            [20.126953125, 80.41890802632504],
            [20.07476806640625, 80.45040001315736],
            [20.06927490234375, 80.47815533726845],
            [20.1654052734375, 80.46315010000394],
            [20.2313232421875, 80.47679219192462],
            [20.32470703125, 80.47633776717865],
            [20.36865234375, 80.49449801002746],
            [20.3192138671875, 80.51579245671392],
            [20.2532958984375, 80.52122175766985],
            [20.15716552734375, 80.51171845895405],
            [20.0225830078125, 80.54696890970762],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.30047607421875, 80.37400337432685],
            [18.292236328125, 80.37170673927024],
            [18.22357177734375, 80.36619260036366],
            [18.13018798828125, 80.33719201026814],
            [18.04779052734375, 80.29422350723345],
            [18.04779052734375, 80.27336441119591],
            [18.1658935546875, 80.27197222725493],
            [18.32794189453125, 80.25153084236514],
            [18.32794189453125, 80.23896621093503],
            [18.3856201171875, 80.23430857500963],
            [18.46527099609375, 80.23663766802092],
            [18.55316162109375, 80.2408286492811],
            [18.63006591796875, 80.25571549245859],
            [18.65478515625, 80.27939492849207],
            [18.72344970703125, 80.28403126804025],
            [18.75640869140625, 80.29977844640587],
            [18.78662109375, 80.32289015199338],
            [18.80859375, 80.33949680008523],
            [18.7261962890625, 80.3399576927017],
            [18.6602783203125, 80.32796740325675],
            [18.59161376953125, 80.3427225910562],
            [18.45703125, 80.35377434799929],
            [18.30047607421875, 80.37400337432685],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.80584716796875, 80.25153084236514],
            [18.76190185546875, 80.2473444125698],
            [18.778381347656246, 80.2315129370903],
            [18.83331298828125, 80.22778418698768],
            [18.86627197265625, 80.23570609683513],
            [18.80584716796875, 80.25153084236514],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.655670166015625, 80.76348070448549],
            [20.55404663085937, 80.76281939880303],
            [20.49362182617187, 80.75708611642554],
            [20.441436767578125, 80.76193758488029],
            [20.4345703125, 80.76171711836082],
            [20.389251708984375, 80.7559831577703],
            [20.40435791015625, 80.74693397402747],
            [20.482635498046875, 80.74693397402747],
            [20.5224609375, 80.74052803018284],
            [20.599365234375, 80.7292517392959],
            [20.611724853515625, 80.70776709727438],
            [20.69000244140625, 80.69800522262281],
            [20.76416015625, 80.70444032679774],
            [20.776519775390625, 80.68378798383117],
            [20.7861328125, 80.66865856806773],
            [20.724334716796875, 80.66665432139756],
            [20.63232421875, 80.6744462180864],
            [20.501861572265625, 80.66464964805743],
            [20.45379638671875, 80.6559577976858],
            [20.50048828125, 80.64524912417113],
            [20.526580810546875, 80.63341083356687],
            [20.44830322265625, 80.63095196991739],
            [20.456542968749996, 80.62334780067395],
            [20.511474609375, 80.62446644434647],
            [20.599365234375, 80.62759794304343],
            [20.67901611328125, 80.61148204233942],
            [20.705108642578125, 80.6002742898274],
            [20.801239013671875, 80.6164092568851],
            [20.78887939453125, 80.63184617616244],
            [20.861663818359375, 80.65194346964367],
            [20.87127685546875, 80.66732245102435],
            [20.89599609375, 80.6871220803921],
            [21.00860595703125, 80.68334334824247],
            [21.06903076171875, 80.65662668622983],
            [21.11572265625, 80.6465883732616],
            [21.188507080078125, 80.65439687317684],
            [21.259918212890625, 80.66665432139756],
            [21.358795166015625, 80.66130757742846],
            [21.409606933593746, 80.6733334848558],
            [21.361541748046875, 80.68489948078638],
            [21.39312744140625, 80.69733927021893],
            [21.314849853515625, 80.71109268759263],
            [21.258544921875, 80.71286585334938],
            [21.21734619140625, 80.69800522262281],
            [21.114349365234375, 80.70621475127423],
            [21.02371215820312, 80.71330909236244],
            [20.996246337890625, 80.72394054033934],
            [20.93719482421875, 80.72969420311631],
            [20.82733154296875, 80.72061950946765],
            [20.72296142578125, 80.74141186994325],
            [20.655670166015625, 80.76348070448549],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.34770965576172, 80.83123499358399],
            [20.324878692626953, 80.83019551954384],
            [20.324363708496094, 80.8193561504517],
            [20.345993041992188, 80.81582240151137],
            [20.38633346557617, 80.81705525776158],
            [20.396976470947266, 80.821464799969],
            [20.34770965576172, 80.83123499358399],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.765090942382812, 80.63631593879033],
            [19.728012084960938, 80.63050483507362],
            [19.733505249023434, 80.61663316028184],
            [19.791183471679688, 80.61125801712987],
            [19.845428466796875, 80.61204208218673],
            [19.846115112304688, 80.62032680198973],
            [19.793930053710934, 80.62401900274976],
            [19.793930053710934, 80.63486349782062],
            [19.765090942382812, 80.63631593879033],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.430206298828125, 80.37101764291724],
            [21.403427124023438, 80.36849053829633],
            [21.380767822265625, 80.35941051888857],
            [21.298370361328125, 80.34709874376072],
            [21.278457641601562, 80.33926634560916],
            [21.226959228515625, 80.3271598352641],
            [21.24000549316406, 80.32046598788705],
            [21.29150390625, 80.32173586313799],
            [21.337509155273434, 80.3258906649097],
            [21.416473388671875, 80.33822923307869],
            [21.456298828125, 80.34560185997682],
            [21.459732055664062, 80.3572254521753],
            [21.461105346679688, 80.36492850302214],
            [21.444625854492188, 80.36688203876086],
            [21.430206298828125, 80.37101764291724],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.322204589843746, 79.58738630440415],
            [19.22332763671875, 79.58391089344435],
            [19.11895751953125, 79.57944082222981],
            [19.1107177734375, 79.5734777760567],
            [19.19586181640625, 79.5699977742049],
            [19.324951171875, 79.56601922271429],
            [19.34967041015625, 79.5764597208422],
            [19.322204589843746, 79.58738630440415],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.724822998046875, 79.60499395489863],
            [18.69598388671875, 79.60350712421405],
            [18.691864013671875, 79.59879743933787],
            [18.700103759765625, 79.59557380767879],
            [18.735809326171875, 79.59631781036639],
            [18.724822998046875, 79.60499395489863],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.886871337890625, 79.55457252530029],
            [18.88275146484375, 79.54959182777388],
            [18.93218994140625, 79.54560557885473],
            [18.94866943359375, 79.54809716059918],
            [18.93218994140625, 79.55258052804618],
            [18.886871337890625, 79.55457252530029],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.51995849609375, 79.4484773996116],
            [19.4622802734375, 79.43337813591477],
            [19.4073486328125, 79.41623981214109],
            [19.49249267578125, 79.397052693696],
            [19.4512939453125, 79.37479306850301],
            [19.35516357421875, 79.36618039230626],
            [19.2205810546875, 79.36516668269165],
            [19.26727294921875, 79.34944196325183],
            [19.346923828125, 79.34741129386451],
            [19.5281982421875, 79.3529947140132],
            [19.59136962890625, 79.34030093677747],
            [19.46502685546875, 79.32810083761001],
            [19.390869140625, 79.31232194796037],
            [19.489746093749996, 79.29805022952435],
            [19.684753417968746, 79.28427037740278],
            [19.84954833984375, 79.28682352419727],
            [19.88525390625, 79.26024138488762],
            [19.83856201171875, 79.23872361865145],
            [19.91546630859375, 79.21921845562382],
            [19.973144531249996, 79.22024591357477],
            [20.08026123046875, 79.2253817520536],
            [20.15716552734375, 79.23667208693278],
            [20.269775390625, 79.25102469655995],
            [20.269775390625, 79.2755851793921],
            [20.20660400390625, 79.28682352419727],
            [20.11322021484375, 79.29703010061358],
            [19.962158203125, 79.30773666252436],
            [19.82757568359375, 79.30365922101662],
            [19.6435546875, 79.31232194796037],
            [19.70947265625, 79.32555741366983],
            [19.97589111328125, 79.3397928746582],
            [20.033569335937496, 79.32046876823125],
            [20.18463134765625, 79.31741443016492],
            [20.1983642578125, 79.33776038681873],
            [20.15167236328125, 79.36313897688383],
            [19.87152099609375, 79.40160011654557],
            [19.71771240234375, 79.40816521262417],
            [19.58038330078125, 79.41018443325977],
            [19.6820068359375, 79.42279594313126],
            [19.6600341796875, 79.43438541774366],
            [19.599609375, 79.43992377115784],
            [19.51995849609375, 79.4484773996116],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.215087890624996, 79.33420261138669],
            [19.1217041015625, 79.33420261138669],
            [19.09698486328125, 79.32199561356671],
            [19.127197265625, 79.31384994450865],
            [19.1986083984375, 79.30875578284183],
            [19.2315673828125, 79.3235222431429],
            [19.215087890624996, 79.33420261138669],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.53369140625, 79.23513318451225],
            [19.37713623046875, 79.2320547273475],
            [19.322204589843746, 79.22692203203958],
            [19.324951171875, 79.21048115497545],
            [19.3743896484375, 79.21305167539396],
            [19.5391845703125, 79.21921845562382],
            [19.5556640625, 79.22743541034691],
            [19.53369140625, 79.23513318451225],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.7259521484375, 79.23256786394928],
            [19.66278076171875, 79.22794876447877],
            [19.65179443359375, 79.2212732747623],
            [19.78363037109375, 79.21870469035932],
            [19.77813720703125, 79.22692203203958],
            [19.7259521484375, 79.23256786394928],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.533447265625, 79.36465979206366],
            [20.54168701171875, 79.34741129386451],
            [20.63232421875, 79.33013514547228],
            [20.709228515625, 79.33369426197375],
            [20.6597900390625, 79.34538024177562],
            [20.533447265625, 79.36465979206366],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.03057861328125, 79.31741443016492],
            [20.9893798828125, 79.31639612562331],
            [20.98388671875, 79.30263962053658],
            [21.08001708984375, 79.27865133751332],
            [21.195373535156246, 79.2766073283777],
            [21.15966796875, 79.30416898523734],
            [21.03057861328125, 79.31741443016492],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.758666992187496, 79.32962660444319],
            [20.72845458984375, 79.32301339058894],
            [20.775146484375, 79.31741443016492],
            [20.78887939453125, 79.32606614638476],
            [20.758666992187496, 79.32962660444319],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.14593505859375, 79.0504429916215],
            [21.0552978515625, 79.04418083904169],
            [21.00311279296875, 79.03791514885954],
            [20.89599609375, 79.0410484362711],
            [20.8685302734375, 79.02694167289923],
            [20.8465576171875, 79.0091520840473],
            [20.83831787109375, 78.99500476760103],
            [20.90423583984375, 78.98976045771468],
            [20.98388671875, 78.99867431650155],
            [21.00860595703125, 79.01700394086555],
            [21.170654296875, 79.02432734169093],
            [21.3409423828125, 79.01019931854502],
            [21.376647949218746, 78.98976045771468],
            [21.470031738281246, 78.9750632552901],
            [21.500244140625, 78.96455339228565],
            [21.57440185546875, 78.96665615616313],
            [21.632080078125, 78.95403363474128],
            [21.6375732421875, 78.9440306920954],
            [21.56890869140625, 78.93665437901818],
            [21.42059326171875, 78.94034314286027],
            [21.362915039062496, 78.93243716114554],
            [21.47552490234375, 78.92241490523001],
            [21.651306152343746, 78.92188716988336],
            [21.7529296875, 78.93770843550641],
            [21.763916015625, 78.95350738697168],
            [21.725463867187496, 78.96980956004563],
            [21.8133544921875, 78.97033504081482],
            [21.84906005859375, 78.98293916463774],
            [21.74468994140625, 78.99290733976689],
            [21.68426513671875, 78.98871129958124],
            [21.61285400390625, 78.99815016923],
            [21.530456542968746, 78.99867431650155],
            [21.43157958984375, 78.9997225370548],
            [21.42608642578125, 79.02066624438999],
            [21.29150390625, 79.03634817333828],
            [21.14593505859375, 79.0504429916215],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.49920654296875, 80.04595022355974],
            [14.42573547363281, 80.03942002255775],
            [14.43328857421875, 80.01218502276714],
            [14.459381103515627, 80.00777769614156],
            [14.504013061523436, 80.0164713855143],
            [14.556884765625002, 80.0238491867108],
            [14.580230712890627, 80.02979509498297],
            [14.585723876953123, 80.03965755869723],
            [14.49920654296875, 80.04595022355974],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.954711914062496, 80.15385189901069],
            [27.84210205078125, 80.1303404431487],
            [27.83935546875, 80.10441363623885],
            [27.89703369140625, 80.06421224819486],
            [27.9986572265625, 80.0385886019087],
            [28.125, 80.04001385239243],
            [28.270568847656246, 80.06800279056768],
            [28.28704833984375, 80.09449653175248],
            [28.270568847656246, 80.11573543387729],
            [28.0865478515625, 80.12845712709841],
            [28.06732177734375, 80.14210311123891],
            [27.954711914062496, 80.15385189901069],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.95794677734375, 80.46951881735832],
            [25.914001464843746, 80.44720985360524],
            [26.015625, 80.43535144309752],
            [26.16119384765625, 80.43169976828449],
            [26.22161865234375, 80.44857719410551],
            [26.23260498046875, 80.46406017485712],
            [26.12274169921875, 80.45905369817534],
            [26.0540771484375, 80.44219461242993],
            [26.03759765625, 80.45723251562364],
            [25.95794677734375, 80.46951881735832],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.85906982421875, 80.4083878514756],
            [25.7958984375, 80.40701481138503],
            [25.784912109375, 80.40106272066014],
            [25.86181640625, 80.40106272066014],
            [25.85906982421875, 80.4083878514756],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.9993896484375, 80.67689376804553],
            [24.98428344726562, 80.67155283795807],
            [24.967803955078125, 80.65640372865498],
            [25.006256103515625, 80.64614197801322],
            [25.050201416015625, 80.64815059036614],
            [25.039215087890625, 80.6639813287799],
            [25.05844116210937, 80.67689376804553],
            [24.9993896484375, 80.67689376804553],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.510009765625, 78.82542475921761],
            [26.400146484375, 78.80837881795745],
            [26.35894775390625, 78.76565157637978],
            [26.42486572265625, 78.70717644264114],
            [26.531982421874996, 78.67593887995208],
            [26.5484619140625, 78.65380572051554],
            [26.65557861328125, 78.64028894440473],
            [26.71875, 78.61917103622973],
            [26.79840087890625, 78.63596022076887],
            [26.95220947265625, 78.64353441923745],
            [26.993408203125, 78.68079167193582],
            [27.04010009765625, 78.70448690253879],
            [26.86431884765625, 78.71846561035292],
            [26.652832031249996, 78.78222745909179],
            [26.64459228515625, 78.81157688617274],
            [26.510009765625, 78.82542475921761],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.7677001953125, 78.9766388818902],
            [28.399658203125, 78.97558848886928],
            [28.3612060546875, 78.93770843550641],
            [28.0810546875, 78.92716340631202],
            [27.9931640625, 78.88911904627656],
            [27.8009033203125, 78.86156232056146],
            [27.833862304687496, 78.83819239140279],
            [28.0096435546875, 78.82329541913144],
            [27.9766845703125, 78.79237493496319],
            [28.0975341796875, 78.78810341850557],
            [28.1854248046875, 78.80944494095804],
            [28.4051513671875, 78.85731683894387],
            [28.58642578125, 78.86580620502623],
            [28.7841796875, 78.85094562059197],
            [28.8885498046875, 78.88488393583317],
            [29.146728515625, 78.8488210819389],
            [29.3719482421875, 78.8307463573278],
            [29.459838867187496, 78.8689880705907],
            [29.77294921875, 78.89017757489586],
            [29.767456054687496, 78.92188716988336],
            [29.619140624999996, 78.93665437901818],
            [29.3719482421875, 78.91555240888218],
            [29.1082763671875, 78.92399796231813],
            [29.0478515625, 78.96297605964982],
            [28.7677001953125, 78.9766388818902],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.61114501953125, 78.778486629468],
            [28.58917236328125, 78.77688303981385],
            [28.5809326171875, 78.76618665948178],
            [28.69903564453125, 78.7474437736768],
            [28.740234375, 78.75387338081502],
            [28.61114501953125, 78.778486629468],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.044860839843746, 79.01595734682523],
            [30.05859375, 78.98398886594948],
            [30.10528564453125, 78.96455339228565],
            [30.220642089843746, 78.96034667711432],
            [30.239868164062496, 78.9787393714137],
            [30.377197265625, 78.98923589098985],
            [30.3387451171875, 78.99919843910945],
            [30.305786132812496, 79.01072289883466],
            [30.176696777343746, 79.00862842983699],
            [30.044860839843746, 79.01595734682523],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.93701171875, 80.32750594400994],
            [32.14599609375, 80.2877387627433],
            [31.92626953125, 80.23756915119115],
            [31.607666015624996, 80.19369416640629],
            [31.585693359375, 80.1908870153044],
            [31.322021484375, 80.09118864420088],
            [31.4923095703125, 80.04381353353904],
            [31.7120361328125, 80.03050836819759],
            [32.1954345703125, 80.078892627262],
            [32.4261474609375, 80.08929793175587],
            [33.167724609375, 80.12421836496156],
            [33.4478759765625, 80.12892798946548],
            [33.519287109375, 80.19650052202098],
            [33.5797119140625, 80.23663766802092],
            [33.2720947265625, 80.2756842792911],
            [32.93701171875, 80.32750594400994],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.3573532104492188, -54.382956707841295],
            [3.3491134643554688, -54.38355652073028],
            [3.3487701416015625, -54.3883547082951],
            [3.3360671997070312, -54.39255266225887],
            [3.3044815063476562, -54.39515118044875],
            [3.286285400390625, -54.3917530850907],
            [3.2842254638671875, -54.396950058174404],
            [3.2770156860351562, -54.40814130293444],
            [3.2842254638671875, -54.431712859468426],
            [3.2924652099609375, -54.439300814458164],
            [3.3123779296875, -54.45387373222012],
            [3.329887390136662, -54.456069201735104],
            [3.3546066284179688, -54.450879720396834],
            [3.4091949462890625, -54.450280891757465],
            [3.4270477294921875, -54.44489103986841],
            [3.4390640258788494, -54.42052804806503],
            [3.4301376342773438, -54.40374510665786],
            [3.394432067871037, -54.39015388401024],
            [3.3638763427734375, -54.38795488075697],
            [3.3573532104492188, -54.382956707841295],
          ],
        ],
      },
    },
  ],
};

export default norwayMap;
