import * as echarts from "echarts/core";
import type {
  CustomSeriesRenderItemAPI,
  CustomSeriesRenderItemParams,
  CustomSeriesRenderItemReturn,
} from "echarts";
import type { Theme } from "@mui/joy";

//echarts type definition is wrong for this type
type Cartesian2DCoordSys = {
  type: "cartesian2d";
  x: number;
  y: number;
  width: number;
  height: number;
};

export const renderItem = (
  params: CustomSeriesRenderItemParams,
  api: CustomSeriesRenderItemAPI,
  theme: Theme
): CustomSeriesRenderItemReturn => {
  const categoryIndex = api.value(0);
  const start = api.coord([api.value(1), categoryIndex]);
  const end = api.coord([api.value(2), categoryIndex]);
  const height = (api.size?.([0, 1]) as [number, number])[1] * 0.6;
  const radiusWithoutPx = theme.radius.sm.replace("px", "");

  const { coordSys } = params as { coordSys: unknown } as {
    coordSys: Cartesian2DCoordSys;
  };

  const orientationObject = {
    x: coordSys.x,
    y: coordSys.y,
    width: coordSys.width,
    height: coordSys.height,
  };

  const rectShape = echarts.graphic.clipRectByRect(
    {
      x: start[0],
      y: start[1] - height / 2 - 1.5, // 3 is like the padding
      width: end[0] - start[0],
      height: 30,
    },
    orientationObject
  );

  return (
    rectShape && {
      type: "group",
      children: [
        {
          type: "rect",
          shape: {
            ...rectShape,
            r: [
              api.value(7) ? 0 : Number(radiusWithoutPx), // left radius
              api.value(8) ? 0 : Number(radiusWithoutPx), // right radius
              api.value(8) ? 0 : Number(radiusWithoutPx), // bottom right radius
              api.value(7) ? 0 : Number(radiusWithoutPx), // bottom left radius
            ],
          },
          style: {
            fill: String(api.value(5)),
          },
        },
        {
          type: "text",
          style: {
            text: api.value(4).toString(),
            x: 10, // 10 is the padding left
            y: rectShape.y + rectShape.height / 2 - 4, // vertically center
            fill: "#ACB8C1",
            font: `${theme.fontSize.sm} Inter`,
          },
        },
        {
          type: "text",
          style: {
            text: (() => {
              const originalText = api.value(4).toString();
              const maxTextLength = Math.floor(rectShape.width / 7) - 3;
              if (originalText.length > maxTextLength) {
                const truncatedText =
                  originalText.substring(0, maxTextLength) + "...";
                if (truncatedText.length < 4) {
                  return "";
                }
                return truncatedText;
              } else {
                return originalText;
              }
            })(),
            x: rectShape.x + 10, // 10 is the padding left
            y: rectShape.y + rectShape.height / 2 - 4, // vertically center
            fill: "white",
            font: `${theme.fontSize.xs} Inter`,
          },
        },
      ],
    }
  );
};
