import { forwardRef, useEffect, useRef, type MutableRefObject } from "react";
import * as echarts from "echarts";
import type { NptMapData, RenderersMutablesArgument } from "./types";
import ReactEChartsCore from "echarts-for-react/lib/core";
import EChartsWrapper from "../../helpers/ECharts/EChartsWrapper";
import { handleZoom } from "./handlers/handleZoom";
import { handleClick } from "./handlers/handleClick";
import type {
  GeoJSON,
  GeoJSONSourceInput,
} from "echarts/types/src/coord/geo/geoTypes.js";
import { renderItem } from "./renderers/item";
import { tooltipFormatter } from "./renderers/tooltip";
import norwayMap from "./maps/norway";
import { centroid, isRef, isValidRef } from "./helpers";

type MapProp = {
  data: GeoJSONSourceInput;
  name: string;
};

export interface NPTGeoMapProps {
  centerType?: "centroid" | [lat: number, lng: number];
  data: NptMapData[];
  groupedCircleColor?: string;
  height: string;
  map: MapProp;
  mapColor?: string;
  onClick?: (e: MouseEvent, data: NptMapData) => void;
  onClickMany?: (e: MouseEvent, data: NptMapData[]) => void;
  ref?: React.ComponentPropsWithRef<typeof ReactEChartsCore>["ref"];
  zoomLevel?: number;
}

/// maps have to be registered before using them, otherwise echarts will crash because it
/// does not check if it exists before using it.
echarts.registerMap("norway", norwayMap);

/// Remember to use the forwardRef to pass the ref to the ReactEChartsCore component
export default forwardRef(function NPTGeoMap(
  {
    centerType = "centroid",
    data = [],
    groupedCircleColor = "#96549B",
    mapColor = "#CCDBDA",
    onClick = () => {},
    onClickMany = () => {},
    zoomLevel = 30,
    map,
    height,
  }: NPTGeoMapProps,
  ref: React.ComponentPropsWithRef<typeof ReactEChartsCore>["ref"]
) {
  /// Need to use a ref to access the echarts instance, then in the useEffect we are going to set the "current" property of the ref to the echarts instance.
  const echartsComponentRef = useRef<ReactEChartsCore | null>(null);
  const rendererMutableArgument = useRef<RenderersMutablesArgument>({
    childelementsbyindex: {},
    skippedindexes: [],
  });

  useEffect(() => {
    if (!isValidRef(echartsComponentRef)) {
      return;
    }

    if (isRef(ref)) {
      (ref as MutableRefObject<unknown>).current = echartsComponentRef.current;
    }

    // Need to do the 'use' for echarts code splitting. This is on the frontend-components repo.
    const instance = echartsComponentRef.current.getEchartsInstance();

    // note, this should be registered outside of the useEffect since for echarts it is global.
    echarts.registerAction(
      {
        type: "geoMapZoom",
        update: "updateLayout",
        event: "geoMapZoom",
      },
      handleZoom(instance)
    );

    return () => {
      instance.off("geoMapZoom");
    };
  }, [map.data, map.name, ref]);

  const option = {
    geo: {
      backgroundColor: "transparent",
      boundingCoords: [
        [-180, 90],
        [180, -90],
      ],
      itemStyle: {
        areaColor: mapColor,
        borderColor: mapColor,
      },
      center:
        centerType === "centroid" ? centroid(map.data as GeoJSON) : centerType,
      map: map.name,
      roam: true,
      mainType: "geo",
      silent: true,
      zoom: zoomLevel,
    },
    renderer: "canvas",
    series: [
      {
        coordinateSystem: "geo",
        data: data.map((d) => ({
          name: d.field_name,
          value: [d.wlbewdesdeg, d.wlbnsdecdeg],
        })),
        renderItem: renderItem(
          rendererMutableArgument.current,
          data,
          groupedCircleColor
        ),
        tooltip: {
          formatter: tooltipFormatter(rendererMutableArgument.current, data),
        },
        type: "custom",
      },
    ],
    tooltip: { trigger: "item" },
    width: "auto",
    height: "auto",
  };

  return (
    <EChartsWrapper
      notMerge={true}
      lazyUpdate={true}
      ref={echartsComponentRef}
      echarts={echarts}
      option={option}
      onEvents={{
        click: handleClick(
          echartsComponentRef,
          data,
          rendererMutableArgument.current,
          onClick,
          onClickMany
        ),
      }}
      style={{ height, width: "100%" }}
    />
  );
});
