import { calcNPTDaysPercentage, nptDaysPercentageColor } from "../helpers";
import type { NptMapData, RenderersMutablesArgument } from "../types";

/**
 * Make the CustomSeriesRenderItem function. This function is used to render the circles on the map.
 *
 * @param data - The NPT map data.
 * @param skippedindexes - The skipped indexes.
 * @param childelementsbyindex - The child elements by index.
 *
 * @returns The CustomSeriesRenderItem function.
 */
export function renderItem(
  arg: RenderersMutablesArgument,
  data: NptMapData[],
  groupedCircleColor: string
): echarts.CustomSeriesRenderItem {
  const datalength = data.length;

  return function customSeriesRenderItem(params, api) {
    const { childelementsbyindex, skippedindexes } = arg;
    const coords = api.coord([api.value(0), api.value(1)]);
    const record = data[params.dataIndex];
    const radiusincrement = 0.1;
    const baseradius = 5;
    let radius = baseradius;

    if (params.dataIndex === 0) {
      arg.skippedindexes = [];
      arg.childelementsbyindex = {};

      Object.keys(childelementsbyindex).forEach((key) => {
        delete childelementsbyindex[
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          key as unknown as keyof typeof childelementsbyindex
        ];
      });
    }

    childelementsbyindex[params.dataIndex] =
      childelementsbyindex[params.dataIndex] ?? [];

    if (skippedindexes.includes(params.dataIndex)) {
      return;
    }

    // gets all sequent elements which could overlap
    // add their index to the skipped indexes so they are not drawn and increment the radius
    for (let i = params.dataIndex + 1; i < datalength; i++) {
      if (skippedindexes.includes(i)) {
        continue;
      }

      const nextCoords = api.coord([data[i].wlbewdesdeg, data[i].wlbnsdecdeg]);
      const distance =
        Math.sqrt(
          Math.pow(coords[0] - nextCoords[0], 2) +
            Math.pow(coords[1] - nextCoords[1], 2)
        ) -
        (radius + baseradius);

      if (distance <= radius + radiusincrement) {
        skippedindexes.push(i);
        radius = Math.min(Math.max(radius + radiusincrement, 20), 40);
        childelementsbyindex[params.dataIndex].push(data[i]);
      }
    }

    const fillcolor =
      childelementsbyindex[params.dataIndex].length > 0
        ? groupedCircleColor
        : nptDaysPercentageColor(calcNPTDaysPercentage(record));

    return {
      /// adds a label to the circle
      children: [
        {
          shape: {
            cx: coords[0],
            cy: coords[1],
            r: radius,
          },
          style: {
            fill: fillcolor,
            stroke: "rgba(0, 0, 0, 0)",
          },
          type: "circle",
        },
        {
          name: "label",
          style: {
            fill: `rgba(0, 0, 0, ${childelementsbyindex[params.dataIndex].length > 0 ? "1" : "0"})`,
            fontSize: 8,
            text: String(childelementsbyindex[params.dataIndex].length + 1),
            textAlign: "center",
            textVerticalAlign: "middle",
          },
          type: "text",
          x: coords[0],
          y: coords[1],
        },
      ],
      type: "group",
    };
  };
}
