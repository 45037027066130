import { Box } from "@mui/joy";

interface IconProps {
  name: string;
  url: string;
}

export default function AppIcon({ name, url }: IconProps) {
  return (
    <Box
      width={40}
      height={40}
      borderRadius="sm"
      bgcolor="#DEF6F6"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src={url} height={25} style={{ maxWidth: 25 }} alt={name} />
    </Box>
  );
}
