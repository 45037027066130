import type GeoModel from "echarts/types/src/coord/geo/GeoModel.js";
import type { GeoMapZoomPayload } from "../types";
import type { ECharts, registerAction } from "echarts";

export const handleZoom =
  (echartsInstance: ECharts): Parameters<typeof registerAction>[1] =>
  (payload, ecmodel) => {
    const longestside = Math.max(
      echartsInstance.getHeight(),
      echartsInstance.getWidth()
    );

    ecmodel.eachComponent("geo", (model) => {
      const geomodel = model as unknown as GeoModel;
      const geoMapZoomPayload = payload as GeoMapZoomPayload;
      const boundingrect = geoMapZoomPayload.target.getBoundingRect();
      const currentzoom = geomodel.coordinateSystem.getZoom();
      const boundingrectlongest = Math.max(
        boundingrect.height,
        boundingrect.width
      );
      const coords = geoMapZoomPayload.items.map((item) => [
        item.wlbewdesdeg,
        item.wlbnsdecdeg,
      ]);

      if (coords.length === 1) {
        return coords[0];
      }

      let x = 0.0;
      let y = 0.0;
      let z = 0.0;

      for (const coord of coords) {
        const latitude = (coord[0] * Math.PI) / 180;
        const longitude = (coord[1] * Math.PI) / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      }

      const total = coords.length;

      x = x / total;
      y = y / total;
      z = z / total;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      geomodel.setCenter([
        (centralLatitude * 180) / Math.PI,
        (centralLongitude * 180) / Math.PI,
      ]);
      geomodel.setZoom(
        (Math.max(longestside, boundingrectlongest) /
          Math.min(longestside, boundingrectlongest)) *
          currentzoom
      );
    });
  };
