import type { DeployConfigFrontend } from "library-frontend-utils/infra";

const config: DeployConfigFrontend = {
  local: {
    authenticationApp: "digitalSolutionsPlatformDev",
    backendUrl: "http://localhost:3000",
  },
  dev: {
    authenticationApp: "digitalSolutionsPlatformDev",
    backendUrl:
      "https://poa-fun.calmriver-90220ec8.westeurope.azurecontainerapps.io",
  },
  prod: false,
  varenergidev: {
    authenticationApp: "odpDev",
    backendUrl:
      "https://poa-fun.wittystone-d90a5db2.westeurope.azurecontainerapps.io",
  },
  varenergitest: {
    authenticationApp: "odpDev",
    backendUrl:
      "https://poa-fun.salmondune-6b31fd4b.westeurope.azurecontainerapps.io",
  },
  varenergiprod: {
    authenticationApp: "odpProd",
    backendUrl:
      "https://poa-fun.thankfulwater-3423c6d8.westeurope.azurecontainerapps.io",
  },
};

export default config;
