import drillingIcon from "../icons/SVGs/varenergi/Drilling.svg";
import documentationIcon from "../icons/SVGs/varenergi/Documentation.svg";
import commercialIcon from "../icons/SVGs/varenergi/Commercial.svg";
import visionIcon from "../icons/SVGs/varenergi/Vision.svg";
import explorationSubsurfaceIcon from "../icons/SVGs/varenergi/Exploration & subsurface.svg";
import financeIcon from "../icons/SVGs/varenergi/Finance.svg";
import researchDevelopmentIcon from "../icons/SVGs/varenergi/Research & development.svg";
import partnershipIcon from "../icons/SVGs/varenergi/Partnership.svg";
import alignmentIcon from "../icons/SVGs/varenergi/Alignment.svg";
import growthIcon from "../icons/SVGs/varenergi/Growth.svg";
import investmentsIcon from "../icons/SVGs/varenergi/Investments.svg";
import productivityIcon from "../icons/SVGs/varenergi/Productivity.svg";

const iconURLs: Record<string, string> = {
  "Bottom Hole Assembly": drillingIcon,
  Cementing: documentationIcon,
  "Duty Handover": commercialIcon,
  "Executive Outlook": visionIcon,
  Geomechanics: explorationSubsurfaceIcon,
  "Mud Tracking": financeIcon,
  "Mud Tracking Upload": financeIcon,
  "Operations Outlook": researchDevelopmentIcon,
  "POA Outlook": partnershipIcon,
  "POA Outlook (Old)": partnershipIcon,
  "Rig Schedule": alignmentIcon,
  "Appsmith Apps Usage": growthIcon,
  "Upload iQx Data": investmentsIcon,
  "Upload AFE Data": productivityIcon,
};

export default function appIconURL(name: string) {
  return iconURLs[name] ?? drillingIcon;
}
