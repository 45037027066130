import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import usePCA from "./hooks/usePCA";
import { InteractionType } from "@azure/msal-browser";
import { type DeployConfigFrontend } from "library-infra";
import getApplicationInfo from "./getApplicationInfo";
import IdentifyUser from "../sentry/helpers/IdentifyUser";

interface AuthWrapperProps {
  children: React.ReactNode;
  config: DeployConfigFrontend;
}

export default function BrowserAuthWrapper({
  children,
  config,
}: AuthWrapperProps) {
  const { clientId, authority } = getApplicationInfo(config);
  const pca = usePCA(clientId, authority);
  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          scopes: [`api://${clientId}/user_impersonation`],
          redirectUri: window.location.origin,
          authority: authority,
        }}
      >
        <IdentifyUser>{children}</IdentifyUser>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}
