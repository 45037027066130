import ReactEChartsCore from "echarts-for-react/lib/core";
import { type ComponentPropsWithRef, forwardRef } from "react";
import * as echarts from "echarts";
import type { EChartsReactProps } from "echarts-for-react";
import theme from "./EChartsTheme.json";
import { SVGRenderer } from "echarts/renderers";

function EChartsRenderer(
  props: EChartsReactProps,
  ref: ComponentPropsWithRef<typeof ReactEChartsCore>["ref"]
) {
  echarts.registerTheme("default", theme);
  // @ts-expect-error type is being incorrectly reported here
  if (props.opts?.renderer === "svg") echarts.use([SVGRenderer]);

  return <ReactEChartsCore theme="default" {...props} ref={ref} />;
}

export const EChartsWrapper = forwardRef(EChartsRenderer);

export default EChartsWrapper;
