import { experimental_extendTheme as muiExtendTheme } from "@mui/material/styles";
import createTheme from "../JoyUI/theme";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";
import { DayCalendarSkeleton } from "@mui/x-date-pickers-pro";

const joyTheme = createTheme();

const materialTheme = muiExtendTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "primary",
        size: "medium",
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "--Button-gap": theme.spacing(2),
          fontWeight: joyTheme.fontWeight.md,
          borderRadius: joyTheme.radius.xl,
          ...(ownerState.size === "medium" && {
            padding: joyTheme.spacing(2, 3),
          }),
          ...(ownerState.size === "small" && {
            padding: joyTheme.spacing(1, 3),
          }),
          ...(ownerState.size === "large" && {
            padding: joyTheme.spacing(2, 4),
            ...joyTheme.typography["body-md"],
          }),
          ...(ownerState.disabled && {
            color: joyTheme.palette.neutral[50],
          }),
        }),
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        today: {
          border: "none !important",
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: 0,
            width: 4,
            height: 4,
            backgroundColor: "black",
            borderRadius: "50%",
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        sx: {
          "& .MuiOutlinedInput-root": {
            "&:hover > fieldset": {
              borderColor: joyTheme.palette.neutral[500],
            },
            height: "40px",
          },
        },
        slotProps: {
          day: {
            sx: {
              "&.MuiPickersDay-today": {
                backgroundColor: joyTheme.palette.common.white,
              },
              "&.MuiPickersDay-root": {
                "&:hover": {
                  backgroundColor: joyTheme.palette.neutral[800],
                  color: "white",
                },
                "&.Mui-selected": {
                  backgroundColor: joyTheme.palette.neutral[800],
                  color: "white",
                },
              },
            },
          },
          desktopPaper: {
            sx: {
              boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.07);",
            },
          },
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          borderRadius: "21px",
          boxShadow: "0px 1px 2px 0px rgba(24, 37, 51, 0.10)",
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          paddingTop: `${joyTheme.spacing(4)} !important`,
        },
      },
    },
    MuiDateRangePicker: {
      defaultProps: {
        format: "dd/MM/yyyy",
        renderLoading: () => <DayCalendarSkeleton />,
        slotProps: {
          fieldSeparator: {
            sx: {
              color: joyTheme.palette.neutral[500],
              margin: 0,
              padding: 0,
            },
          },
          desktopPaper: {
            sx: {
              position: "relative",
              right: "13px",
              boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.07);",
              "& .MuiPickersLayout-actionBar": {
                width: "fit-content",
                position: "relative",
                top: "38px",
                left: "calc(100% - 68px)",
                height: 0,
                padding: 0,
                margin: 0,
                paddingBottom: joyTheme.spacing(4),
              },
              "& .MuiPickersLayout-actionBar > .MuiButtonBase-root": {
                backgroundColor: joyTheme.palette.neutral[100],
                color: joyTheme.palette.text.primary,
                fontWeight: joyTheme.fontWeight.xs,
                fontSize: joyTheme.fontSize.xs,
                boxShadow: "none",
                padding: "0 12px",
                height: "40px",
                borderRadius: "44px",
                textTransform: "none",
              },
            },
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          width: "40px",
          height: "40px",
        },
        rangeIntervalDayHighlight: {
          backgroundColor: joyTheme.palette.neutral[200],
          borderRadius: 0,
        },
        rangeIntervalDayHighlightStart: {
          borderTopLeftRadius: "50%",
          borderBottomLeftRadius: "50%",
        },
        rangeIntervalDayHighlightEnd: {
          borderTopRightRadius: "50%",
          borderBottomRightRadius: "50%",
        },
        day: {
          "&.Mui-selected": {
            backgroundColor: joyTheme.palette.neutral[800],
            color: "white",
            "&:hover": {
              backgroundColor: joyTheme.palette.neutral[800],
            },
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          padding: joyTheme.spacing(4),
        },
        header: {
          width: "280px",
        },
        weekContainer: {
          margin: 0,
          width: "280px",
        },
        slideTransition: {
          height: "240px !important",
          minHeight: "240px !important",
          width: "280px !important",
          minWidth: "280px !important",
        },
      },
    },
    MuiDateRangeCalendar: {
      styleOverrides: {
        root: {
          order: 1,
          "& .MuiTypography-root": {
            fontSize: "14px",
          },
          "& .MuiTypography-subtitle1": {
            fontWeight: 700,
          },
          "& .MuiDayCalendar-weekDayLabel": {
            fontWeight: 700,
            fontSize: "12px",
            width: "40px",
            height: "40px",
            color: joyTheme.palette.neutral[600],
          },
        },
        monthContainer: {
          borderRight: "none !important",
        },
      },
    },
    MuiMultiInputDateRangeField: {
      styleOverrides: {
        root: {
          height: 36,
          position: "relative",
          top: "9px",
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiOutlinedInput-root": {
            fontSize: joyTheme.fontSize.xs,
          },
          "& .MuiOutlinedInput-input": {
            padding: 0,
          },
          "& .MuiInputLabel-root": {
            top: "-20px",
            left: "-15px",
            maxWidth: "fit-content",
            textOverflow: "inherit",
            fontSize: joyTheme.fontSize.md,
            fontWeight: 400,
            color: joyTheme.palette.neutral[500],
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          marginTop: joyTheme.spacing(5),
          padding: joyTheme.spacing(4, 4, 2, 4),
          display: "flex",
          flexDirection: "column",
          "& .MuiList-root": {
            order: 2,
            width: "100%",
            marginBottom: joyTheme.spacing(4),
            display: "flex",
            minWidth: "fit-content",
            padding: 0,
            justifyContent: "flex-start",
            gap: joyTheme.spacing(2),
          },
          "& .MuiListItem-root": {
            padding: "0px",
            margin: "0px",
            position: "relative",
            "& .MuiButtonBase-root": {
              paddingX: "0px",
              margin: "0px",
              border: `1px solid ${joyTheme.palette.neutral[500]}`,
              backgroundColor: joyTheme.palette.common.white,
              fontSize: "12px",
              height: "40px",
              borderRadius: "44px",
              "& .MuiChip-label": {
                paddingLeft: "15px",
                paddingRight: "15px",
              },
            },
          },
        },
      },
    },
  },
});

export default materialTheme;
