import type { SeriesTooltipOption } from "echarts/types/src/util/types.js";
import type { NptMapData, RenderersMutablesArgument } from "../types";

/**
 * Make the tooltip formatter. This function is used to format the tooltip.
 *
 * @param data - The NPT map data.
 * @param childelementsbyindex - The child elements by index.
 *
 * @returns The tooltip formatter function.
 */
export function tooltipFormatter(
  arg: RenderersMutablesArgument,
  data: NptMapData[]
): SeriesTooltipOption["formatter"] {
  return function formatter(params) {
    const entry = arg.childelementsbyindex[params.dataIndex];

    if (Array.isArray(entry)) {
      const master = data[params.dataIndex];
      let mastername = "";
      let npthours = master.npthours;
      let totalhours = master.totalhours;

      if (entry.length > 0) {
        mastername += ` <small><strong>${(entry.length + 1).toString()}</strong> wellbores</small>`;
      } else {
        mastername = `<strong>${master.wellbore}</strong>`;
      }

      mastername += "<br>";

      for (let i = 0; i < entry.length; i++) {
        npthours += entry[i].npthours;
        totalhours += entry[i].totalhours;
      }

      const averagepercentage = (npthours / totalhours) * 100;

      return `${mastername}NPT hours(%) ${averagepercentage.toFixed(2)}`;
    }

    return "";
  };
}
