import type ReactEChartsCore from "echarts-for-react/lib/core";
import type { ECElementEvent } from "echarts";
import type {
  GeoMapZoomPayload,
  NptMapData,
  RenderersMutablesArgument,
} from "../types";
import type { RefObject } from "react";

export const handleClick =
  (
    ref: RefObject<ReactEChartsCore> | undefined,
    data: NptMapData[],
    rendererMutableArgument: RenderersMutablesArgument,
    onClick: (e: MouseEvent, data: NptMapData) => void,
    onClickMany: (e: MouseEvent, data: NptMapData[]) => void
  ) =>
  (event: ECElementEvent) => {
    const relatedData = data[event.dataIndex];
    const childelements =
      rendererMutableArgument.childelementsbyindex[event.dataIndex];

    if (childelements.length === 0) {
      onClick(new MouseEvent("click"), relatedData);
    } else if (childelements.length > 0) {
      const items = [relatedData, ...childelements];
      const instance = ref?.current?.getEchartsInstance();

      onClickMany(new MouseEvent("clickMany"), items);

      if (instance) {
        const target = event.event
          ?.target as unknown as GeoMapZoomPayload["target"];
        const clickCoordinates =
          event.value as unknown as GeoMapZoomPayload["clickCoordinates"];
        const action: GeoMapZoomPayload = {
          clickCoordinates,
          items,
          target,
          type: "geoMapZoom",
        };

        instance.dispatchAction(action);
      }
    }
  };
