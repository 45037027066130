import {
  type NonEmptyDateRange,
  type PickersShortcutsItem,
} from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { DateRangePicker as MUIDateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { startOfYear, subMonths } from "date-fns";
import {
  type TranslationsType,
  defaultTranslations,
} from "library-translations";
import { setLicenseKey } from "../../helpers/muiLicense";
import type { ComponentProps } from "react";

setLicenseKey();

const translationStrings = [
  "Last 3 months",
  "Last 6 months",
  "Last year",
  "Year to date",
  "Confirm",
] as const;

type DateRangePickerProps = Omit<
  ComponentProps<typeof MUIDateRangePicker>,
  "value"
> & {
  startLabel: string;
  endLabel: string;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  fullWidth?: boolean;
  value: [Date | null, Date | null];
};

export default function DateRangePicker({
  startLabel,
  endLabel,
  disabled,
  isLoading,
  isError,
  translations,
  fullWidth,
  sx,
  ...rest
}: DateRangePickerProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };

  const customLocaleText = {
    okButtonLabel: t["Confirm"],
  };

  const shortcutsItems: PickersShortcutsItem<NonEmptyDateRange<Date>>[] = [
    {
      label: t["Last 3 months"],
      getValue: () => {
        return [subMonths(new Date(), 3), new Date()];
      },
    },
    {
      label: t["Last 6 months"],
      getValue: () => {
        return [subMonths(new Date(), 6), new Date()];
      },
    },
    {
      label: t["Last year"],
      getValue: () => {
        return [subMonths(new Date(), 12), new Date()];
      },
    },
    {
      label: t["Year to date"],
      getValue: () => {
        return [startOfYear(new Date()), new Date()];
      },
    },
  ];

  const slotProps: React.ComponentProps<
    typeof MUIDateRangePicker
  >["slotProps"] = {
    actionBar: {
      actions: ["accept"],
    },
    shortcuts: {
      items: shortcutsItems,
      changeImportance: "set",
    },
    popper: {
      disablePortal: true,
    },
  };

  const defaultStyle = {
    background: "white",
    width: fullWidth ? "100%" : "200px",
    borderRadius: "var(--joy-radius-xl)",
    boxShadow: "var(--joy-shadow-xs)",
    padding: "0px 15px",
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={customLocaleText}
    >
      <MUIDateRangePicker
        sx={{ ...defaultStyle, ...sx }}
        slotProps={slotProps}
        localeText={{ start: startLabel, end: endLabel }}
        calendars={2}
        disabled={disabled || isError}
        loading={isLoading}
        closeOnSelect={false}
        {...rest}
      />
    </LocalizationProvider>
  );
}
