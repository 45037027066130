import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { BarChart, CustomChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { useMemo, type ComponentProps } from "react";
import type { EchartsEventParams } from "../../components/shared/types/EchartsEventParams";
import type { EChartsOption } from "echarts";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import theme from "../../helpers/ECharts/EChartsTheme.json";
import { makeSeries, type OperationTypes } from "./helpers/makeSeries";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import { Box, Grid, Typography, useTheme } from "@mui/joy";
import type { TopLevelFormatterParams } from "echarts/types/dist/shared.js";
import DateFnsAdapter from "@date-io/date-fns";

const translationStrings = [
  "Loading...",
  "Completion",
  "Plug Abandon",
  "Drilling",
  "Workover",
  "Interruption",
  "Formation Evaluation",
  "Operation not available",
  "Moving",
  "No Data Available",
  "Error on loading data",
] as const;

type AssetActivityChartProps = {
  operations?: {
    type: OperationTypes;
    activities: {
      wellboreId: string;
      startDate: Date;
      endDate: Date;
    }[];
  }[];
  wellbores?: { id: string; name: string }[];
  isLoading: boolean;
  onClick: (
    event: Event,
    data: [startDate: Date, endDate: Date, wellboreId: string]
  ) => void;
  colors?: {
    splitLine: string;
    axisLabel: string;
    operations: Record<OperationTypes, string>;
    background: string;
    rowBackground: string;
  };
  startDate: Date;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
};

const dateFns = new DateFnsAdapter();

export default function AssetActivityChart({
  isLoading,
  onClick,
  colors = {
    splitLine: "rgba(93, 120, 149, 0.25)",
    axisLabel: "#293746",
    background: "#FFF",
    rowBackground: "rgba(132, 156, 182, 0.10)",
    operations: {
      Completion: "#F9C633",
      PlugAbandon: "#6357AC",
      Drilling: "#0EBD74",
      Workover: "#5796E4",
      Interruption: "#E96FB8",
      FormationEvaluation: "#E04360",
      Moving: "#049859",
      NotAvailable: theme.color[2],
    },
  },
  wellbores,
  operations,
  startDate,
  translations,
  isError = false,
  renderer,
}: AssetActivityChartProps) {
  const theme = useTheme();
  const t = useMemo(
    () => ({
      ...defaultTranslations(translationStrings),
      ...translations,
    }),
    [translations]
  );

  const options: EChartsOption = useMemo(() => {
    if (!wellbores || !operations) return {};

    const operationTypeLegendNames = {
      Completion: t["Completion"],
      PlugAbandon: t["Plug Abandon"],
      Drilling: t["Drilling"],
      Workover: t["Workover"],
      Interruption: t["Interruption"],
      FormationEvaluation: t["Formation Evaluation"],
      NotAvailable: t["Operation not available"],
      Moving: t["Moving"],
    };

    return {
      backgroundColor: colors.background,
      legend: {
        left: "right",
        top: "top",
        icon: "path://M0,0 L0,10 L10,10 L10,0 Z", // This is a 10x10 square
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        formatter: (params: TopLevelFormatterParams) => {
          const data = Array.isArray(params) ? params[0].data : params.data;
          return (Array.isArray(data) && data[4]?.toString()) || "";
        },
      },
      color: operations.map((op) => colors.operations[op.type]),
      grid: {
        top: 50,
        bottom: 20,
        left: 0,
        right: 0,
        width: "100%",
      },
      xAxis: {
        min: dateFns.addDays(startDate, -5).getTime(),
        type: "time",
        axisLabel: {
          color: colors.axisLabel,
        },
        minInterval: 3600 * 24 * 1000 * 30, // 30 days
        splitLine: {
          show: true,
          lineStyle: {
            color: colors.splitLine,
            type: "dashed",
          },
        },
        z: 5,
      },
      yAxis: {
        data: wellbores.map((_, index) => index),
        type: "category",
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 2.5,
            color: [colors.background],
          },
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: [colors.rowBackground],
          },
        },
      },
      series: makeSeries(
        wellbores,
        operations,
        colors.operations,
        operationTypeLegendNames,
        theme
      ),
    };
  }, [colors, operations, startDate, wellbores, t, theme]);

  if (!wellbores || !operations || wellbores.length === 0 || isError) {
    return (
      <Grid height={428} container justifyContent="center" alignItems="center">
        <Box>
          <Typography level="body-md" fontSize="lg" fontWeight={300}>
            {isError ? t["Error on loading data"] : t["No Data Available"]}
          </Typography>
        </Box>
      </Grid>
    );
  }
  const heightOfTheChart = wellbores.length * 36 + 180; // 180 is the height of the x-axis, 36 is the row height

  echarts.use([
    TooltipComponent,
    LegendComponent,
    CustomChart,
    BarChart,
    CanvasRenderer,
    GridComponent,
  ]);

  const onEvents = {
    click: (
      e: EchartsEventParams<
        [number, Date, Date, OperationTypes, string, string, string]
      >
    ) => {
      onClick(new Event("click"), [e.data[1], e.data[2], e.data[6]]);
    },
  };

  return (
    <EChartsRenderer
      notMerge={true}
      lazyUpdate={true}
      echarts={echarts}
      option={options}
      onEvents={onEvents}
      style={{
        height: heightOfTheChart,
        width: "100%",
      }}
      loadingOption={{
        text: t["Loading..."],
        color: "gray",
        textColor: theme.palette.common.black,
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: true,
        spinnerRadius: 10,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "Inter",
      }}
      showLoading={isLoading}
      opts={{ renderer }}
    />
  );
}
