const standardDPI = {
  sm: 375 + 1,
  md: 768 + 1,
  lg: 1280 + 1,
  xl: 1440 + 1,
} as const;

const highDPI = {
  sm: 768 + 1,
  md: 1280 + 1,
  lg: 1440 + 1,
  xl: 1920 + 1,
} as const;

const breakpoints = {
  standardDPI,
  highDPI,
} as const;

export default breakpoints;
