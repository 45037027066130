import { lazy, Suspense } from "react";
import { CircularProgress } from "@mui/joy";

const ErrorPage = lazy(() => import("./ErrorPage.tsx"));

function LazyErrorPage() {
  return (
    <Suspense fallback={<CircularProgress color="neutral" />}>
      <ErrorPage />
    </Suspense>
  );
}

export default LazyErrorPage;
