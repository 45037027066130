import { createBrowserRouter, redirect } from "react-router-dom";
import { LazyErrorPage } from "library-ui-components/pages";
import { wrapCreateBrowserRouter } from "@sentry/react";

export const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: "/",
    loader: () => redirect("/poa-outlook"),
  },
  {
    path: "/poa-outlook",
    async lazy() {
      const c = await import("./root");
      return { Component: c.default };
    },
    errorElement: <LazyErrorPage />,
    children: [
      {
        index: true,
        async lazy() {
          const c = await import("./Home/Home");
          return { Component: c.default };
        },
      },
      {
        path: "asset",
        async lazy() {
          const c = await import("./Licence/Licence");
          return { Component: c.default };
        },
      },
      {
        path: "wellbore",
        async lazy() {
          const c = await import("./Wellbore/Wellbore");
          return { Component: c.default };
        },
      },
      {
        path: "activity-monitor",
        async lazy() {
          const c = await import("./ActivityMonitor/ActivityMonitor");
          return { Component: c.default };
        },
      },
    ],
  },
  {
    path: "*",
    element: <LazyErrorPage />,
  },
]);
