type ValidURL =
  | `https://tooljet.t60.io/tools/applications/${string}`
  | `https://${"tds" | "tds-dev" | "tds-test"}.varenergi.no/app/${string}`
  | `https://app.powerbi.com/${string}`
  | `/${string}`;

type FrontendApp = {
  name: string;
  description: string;
  roles: string[];
  url: {
    local: ValidURL;
    dev?: ValidURL;
    prod?: ValidURL;
    varenergidev?: ValidURL;
    varenergitest?: ValidURL;
    varenergiprod?: ValidURL;
  };
};

const frontendApps: FrontendApp[] = [
  {
    name: "Bottom Hole Assembly",
    description:
      "Browse, filter, and review BHA components to plan better wells and discuss with service providers.",
    roles: ["basic"],
    url: {
      local: "https://tooljet.t60.io/tools/applications/bottom-hole-assembly",
      varenergidev:
        "https://tooljet.t60.io/tools/applications/bottom-hole-assembly",
      varenergitest:
        "https://tooljet.t60.io/tools/applications/bottom-hole-assembly",
      varenergiprod:
        "https://tooljet.t60.io/tools/applications/bottom-hole-assembly",
    },
  },
  {
    name: "Cementing",
    description:
      "Quickly access reports to review historical cement jobs and optimize upcoming work.",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/var-cementing/discharge-66cf0162fe2d9c733c8c4330?branch=main",
      varenergidev:
        "https://tds-dev.varenergi.no/app/var-cementing/discharge-66cf0162fe2d9c733c8c4330?branch=main",
      varenergitest:
        "https://tds-test.varenergi.no/app/cementing/discharge-66bc6c0f61d07b33df0de0b1?branch=main",
      varenergiprod:
        "https://tds.varenergi.no/app/cementing/discharge-66cf1862001a7b727edf9f54?branch=main",
    },
  },
  {
    name: "Drilling Performance",
    description:
      "Reporting of key well delivery and execution KPIs for the Drilling & Wells department.",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/drilling-performance/tabular-data-66acbff3fe2d9c733c8b3f3f?branch=main",
      varenergidev:
        "https://tds-dev.varenergi.no/app/drilling-performance/tabular-data-66acbff3fe2d9c733c8b3f3f?branch=main",
      varenergitest:
        "https://tds-test.varenergi.no/app/drilling-performance/tabular-data-66c7341061d07b33df0dfdb1?branch=main",
      varenergiprod:
        "https://tds.varenergi.no/app/drilling-performance/tabular-data-66c734ca001a7b727edf86a1?branch=main",
    },
  },
  {
    name: "Duty Handover",
    description:
      "Manage when personnel are going on/off duty, review well status updates and other critical information.",
    roles: ["basic"],
    url: {
      local: "/duty-handover",
      dev: "/duty-handover",
      varenergidev: "/duty-handover",
      varenergitest: "/duty-handover",
    },
  },
  {
    name: "Executive Outlook",
    description:
      "Monitor rigs and wellbores operated by Vår Energi based on corporate KPIs and performance indicators.",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/executive-outlook/home-667292add452c40c7c810723",
      varenergidev:
        "https://tds-dev.varenergi.no/app/executive-outlook/home-667292add452c40c7c810723",
      varenergitest:
        "https://tds-test.varenergi.no/app/executive-outlook/home-667c194ea0de7a081f61258f",
      varenergiprod:
        "https://tds.varenergi.no/app/executive-outlook/home-657823764f1c44511a0623ec",
    },
  },
  {
    name: "Geomechanics",
    description:
      "Drill more efficient wells by leveraging geomechanical data to perform offset well analysis and geomechanical modeling.",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/geomechanics/dashboard-66bc8278fe2d9c733c8bb6bf",
      varenergidev:
        "https://tds-dev.varenergi.no/app/geomechanics/dashboard-66bc8278fe2d9c733c8bb6bf",
      varenergitest:
        "https://tds-test.varenergi.no/app/geomechanics/dashboard-66bcb8a961d07b33df0dec07",
      varenergiprod:
        "https://tds.varenergi.no/app/geomechanics/dashboard-66d5c281001a7b727edfc6d6?branch=main",
    },
  },
  {
    name: "Mud Tracking",
    description:
      "Review reports and track the quality of the mud used during drilling operations for any wellbore.",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/mud-tracking-report/offset-66729195d452c40c7c81054f",
      varenergidev:
        "https://tds-dev.varenergi.no/app/mud-tracking-report/offset-66729195d452c40c7c81054f",
      varenergitest:
        "https://tds-test.varenergi.no/app/mud-tracking-report/offset-667c17b1a0de7a081f612404",
      varenergiprod:
        "https://tds.varenergi.no/app/mud-tracking-report/offset-650179b675e59933bb64312f",
    },
  },
  {
    name: "Mud Tracking Upload",
    description: "Upload reports and configure the Mud Tracking application",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/mud-tracking-upload/upload-xml-66729638d452c40c7c8108b8",
      varenergidev:
        "https://tds-dev.varenergi.no/app/mud-tracking-upload/upload-xml-66729638d452c40c7c8108b8",
      varenergitest:
        "https://tds-test.varenergi.no/app/mud-tracking-upload/upload-xml-667c1b45a0de7a081f6126f8",
      varenergiprod:
        "https://tds.varenergi.no/app/mud-tracking-upload/upload-xml-6544bcd6bed22c49e2792525",
    },
  },
  {
    name: "Source Mapper",
    description:
      "Map source data from Kabal, Wellview, and iQx to a given wellbore",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/source-mapper/kabal-wellbores-667298a0d452c40c7c81095c",
      varenergidev:
        "https://tds-dev.varenergi.no/app/source-mapper/kabal-wellbores-667298a0d452c40c7c81095c",
      varenergitest:
        "https://tds-test.varenergi.no/app/source-mapper/kabal-wellbores-667c1bf1a0de7a081f612732",
      varenergiprod:
        "https://tds.varenergi.no/app/source-mapper/kabal-wellbores-65017df875e59933bb64329a",
    },
  },
  {
    name: "Survey Benchmarking",
    description:
      "Verify the planned wellbore wellpath by uploading candidate survey plans and checking DDI and MTI against offset wellbores to minimize non-productive time (NPT).",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/survey-benchmarking/page1-669f874c9896b5384800ce2d?branch=main&environment=production",
      varenergidev:
        "https://tds-dev.varenergi.no/app/survey-benchmarking/page1-669f874c9896b5384800ce2d?branch=main&environment=production",
      varenergitest:
        "https://tds-test.varenergi.no/app/survey-benchmarking/page1-669fc6cf675dfc61a9c33b64?branch=main&environment=production",
      varenergiprod:
        "https://tds.varenergi.no/app/survey-benchmarking/page1-669fc6fd95915f17ae50eae3?branch=main",
    },
  },
  {
    name: "Upload AFE Data",
    description: "Upload the planned time depth curve data for each wellbore",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/upload-afe-data/upload-afe-data-6672997cd452c40c7c8109ce",
      varenergidev:
        "https://tds-dev.varenergi.no/app/upload-afe-data/upload-afe-data-6672997cd452c40c7c8109ce",
      varenergitest:
        "https://tds-test.varenergi.no/app/upload-afe-data/upload-afe-data-667c24e1a0de7a081f6127a8",
      varenergiprod:
        "https://tds.varenergi.no/app/upload-afe-data/upload-afe-data-650408a921a0ce5b827b681f",
    },
  },
  {
    name: "Upload iQx Data",
    description: "Upload, inspect, and export iQx data for each each wellbore",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/upload-iqx-data/upload-iqx-file-667298eed452c40c7c81099f",
      varenergidev:
        "https://tds-dev.varenergi.no/app/upload-iqx-data/upload-iqx-file-667298eed452c40c7c81099f",
      varenergitest:
        "https://tds-test.varenergi.no/app/upload-iqx-data/upload-iqx-file-667c248ea0de7a081f61277c",
      varenergiprod:
        "https://tds.varenergi.no/app/upload-iqx-data/upload-iqx-file-6501811175e59933bb643314",
    },
  },
  {
    name: "Appsmith Apps Usage",
    description: "See the usage of appsmith-based apps",
    roles: ["admin"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/app-usage/page-views-66729577d452c40c7c81087b",
      varenergidev:
        "https://tds-dev.varenergi.no/app/app-usage/page-views-66729577d452c40c7c81087b",
      varenergitest:
        "https://tds-test.varenergi.no/app/app-usage/page-views-667c2691a0de7a081f61286a",
      varenergiprod:
        "https://tds.varenergi.no/app/apps-usage/page-views-6537a783bf2c4d00fce1cfb5",
    },
  },
  {
    name: "Operations Outlook",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by Vår Energi.",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/operations-outlook/home-66728f34d452c40c7c810358",
      varenergidev:
        "https://tds-dev.varenergi.no/app/operations-outlook/home-66728f34d452c40c7c810358",
      varenergitest:
        "https://tds-test.varenergi.no/app/operations-outlook/home-667c16f0a0de7a081f6122df",
      varenergiprod:
        "https://tds.varenergi.no/app/operations-outlook/home-65f01e745a6bcc27cf33a056",
    },
  },
  {
    name: "POA Outlook",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by partners.",
    roles: ["basic"],
    url: {
      local: "/poa-outlook",
      dev: "/poa-outlook",
      varenergidev: "/poa-outlook",
      varenergitest: "/poa-outlook",
      varenergiprod: "/poa-outlook",
    },
  },
  {
    name: "POA Outlook (Classic)",
    description:
      "Continuous monitoring and performance benchmarking of drilling activities operated by partners.",
    roles: ["basic"],
    url: {
      local:
        "https://tds-dev.varenergi.no/app/poa-outlook/home-66729218d452c40c7c810621",
      varenergidev:
        "https://tds-dev.varenergi.no/app/poa-outlook/home-66729218d452c40c7c810621",
      varenergitest:
        "https://tds-test.varenergi.no/app/poa-outlook/home-667c1571a0de7a081f6121c8",
      varenergiprod:
        "https://tds.varenergi.no/app/poa-outlook/home-650178d675e59933bb643080",
    },
  },
  {
    name: "Rig Schedule",
    description:
      "Investigate activities, schedules, and approval statuses for wells across project areas and rigs.",
    roles: ["basic"],
    url: {
      local:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
      varenergidev:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
      varenergitest:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
      varenergiprod:
        "https://app.powerbi.com/groups/me/apps/3bd981d1-59f0-4906-aae4-91b2abe932b4/reports/24590cd7-2775-447e-8dc2-f4d9dc939d5b/ReportSection9d759b88d886d233d121?ctid=77da4c42-ba77-462b-bb54-7f7ea57bd0a8&experience=power-bi",
    },
  },
];

export default frontendApps;
