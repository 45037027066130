import {
  Button,
  CircularProgress,
  Dropdown,
  Grid,
  Input,
  Menu,
  MenuButton,
} from "@mui/joy";
import {
  TreeItem as TreeItemMUI,
  treeItemClasses,
  RichTreeView,
} from "@mui/x-tree-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import React from "react";

const translationStrings = [
  "Save filter setting",
  "Clear",
  "Done",
  "Error",
  "Loading",
  "Filters",
  "Areas",
  "Operators",
  "Assets",
] as const;

type TreeItem = {
  id: string;
  label: string;
  children?: (typeof TreeItemMUI)[];
};

type FilterDropdownProps = {
  isLoading?: boolean;
  isError?: boolean;
  selectedFilters: string[];
  disabledFilters?: string[];
  areas: TreeItem[];
  operators: TreeItem[];
  assets: TreeItem[];
  onSelect: React.ComponentProps<typeof RichTreeView>["onItemSelectionToggle"];
  onClear: React.ComponentProps<typeof Button>["onClick"];
  translations?: TranslationsType<typeof translationStrings>;
  searchValue: string;
  onSearchValueChange: (newSearchValue: string) => void;
};

const StyledTreeItem = styled(TreeItemMUI)(() => ({
  [`& .${treeItemClasses.content}.${treeItemClasses.selected}`]: {
    backgroundColor: "transparent",
  },

  // Target the checkbox within the TreeItem
  [`& .${treeItemClasses.content} .MuiCheckbox-root`]: {
    borderRadius: "3px",
    width: "18px",
    height: "18px",
  },
  [`& .${treeItemClasses.content} .MuiCheckbox-root.Mui-checked`]: {
    background: "#000",
    border: "2px solid",
    borderColor: "#a8b4c0",
  },
  // Target unchecked state
  [`& .${treeItemClasses.content} .MuiCheckbox-root .MuiSvgIcon-root`]: {
    fill: "rgba(82, 105, 129, 0.50)",
  },
  // Target checked state
  [`& .${treeItemClasses.content} .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root path`]:
    {
      fill: "#fff",
      padding: "2px",
      transform: "scale(0.8)",
      transformOrigin: "50% 50%",
    },
}));

export default function FilterDropdown({
  assets,
  areas,
  operators,
  disabledFilters = [],
  selectedFilters,
  onSelect,
  onClear,
  translations,
  isLoading = false,
  isError = false,
  searchValue = "",
  onSearchValueChange,
}: FilterDropdownProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  return (
    <Dropdown>
      <MenuButton
        variant="soft"
        sx={{ gap: 4 }}
        disabled={isLoading || isError}
        startDecorator={<FontAwesomeIcon icon={faSearch} />}
        endDecorator={
          isLoading ? (
            <CircularProgress />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )
        }
      >
        {isError ? t.Error : t.Filters}
      </MenuButton>
      <Menu placement="bottom-start" disablePortal variant="soft" sx={{ p: 3 }}>
        <Input
          placeholder="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e) => {
            onSearchValueChange(e.target.value);
          }}
          sx={{ mb: 2 }}
        />
        <RichTreeView
          selectedItems={selectedFilters}
          onItemSelectionToggle={onSelect}
          checkboxSelection
          multiSelect
          items={[
            {
              id: "areas",
              label: t.Areas,
              children: areas.filter((area) =>
                area.label
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              ),
            },
            {
              id: "operators",
              label: t.Operators,
              children: operators.filter((operator) =>
                operator.label
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              ),
            },
            {
              id: "assets",
              label: t.Assets,
              children: assets.filter((asset) =>
                asset.label
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              ),
            },
          ]}
          slots={{
            item: StyledTreeItem,
          }}
          isItemDisabled={(item) => disabledFilters.includes(item.id)}
          defaultExpandedItems={["areas", "operators", "assets"]}
        />
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
          mt={2}
        >
          <Button color="neutral" onClick={onClear}>
            {t["Clear"]}
          </Button>
        </Grid>
      </Menu>
    </Dropdown>
  );
}
