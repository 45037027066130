import AppSwitcher from "../../components/AppSwitcher/AppSwitcher";
import frontendApps from "library-infra/frontend/apps";
import appIconURL from "../../components/AppIcon/appIconURL";
import getCurrentStackName from "library-frontend-utils/infra/getCurrentStackName";
import { useUserInfo } from "library-frontend-utils/auth/hooks";

const currentStack = getCurrentStackName();

export default function ConnectedAppSwitcher() {
  const user = useUserInfo();

  return (
    <AppSwitcher
      apps={frontendApps
        .filter((app) => Boolean(app.url[currentStack])) // Filter out apps that don't have a URL for the current stack
        .filter((app) =>
          app.roles.some((acceptedRole) => user.roles.includes(acceptedRole))
        ) // Filter out apps that the user doesn't have access to
        .map((app) => ({
          name: app.name,
          icon: appIconURL(app.name),
          // The filter above ensures that the current stack is in the keys of app.url
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          url: app.url[currentStack]!,
        }))}
    />
  );
}
