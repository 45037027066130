import type { NptMapData } from "./types";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import type { GeoJSON } from "echarts/types/src/coord/geo/geoTypes.js";

/**
 * Calculate the NPT days percentage.
 * @param nptMapData - The NPT map data.
 * @returns The NPT days percentage.
 * @example
 *
 * ```ts
 * const nptMapData = {
 *   npthours: 1,
 *   totalhours: 2,
 * };
 *
 * const result = calcNPTDaysPercentage(nptMapData);
 *
 * console.log(result); // 0.5
 * ```
 */
export function calcNPTDaysPercentage(nptMapData: NptMapData) {
  return nptMapData.npthours / nptMapData.totalhours;
}

/**
 * Get the centroid of the given GeoJSON.
 *
 * @param input - The GeoJSON.
 * @returns The centroid.
 */
export function centroid(input: GeoJSON): [lat: number, lng: number] {
  const output: [number, number] = [0, 0];
  let count = 1;

  for (let i = 0; i < input.features.length; i++) {
    const feature = input.features[i];

    count += feature.geometry.coordinates.length;

    for (let j = 0; j < feature.geometry.coordinates.length; j++) {
      const coord = feature.geometry.coordinates[j] as [
        lat: number,
        lng: number,
      ][];

      let totallng = 0;
      let totallat = 0;

      if (Array.isArray(coord) && Array.isArray(coord[0])) {
        for (let k = 0; k < coord.length; k++) {
          totallat += coord[k][0];
          totallng += coord[k][1];
        }

        output[0] += totallat / coord.length;
        output[1] += totallng / coord.length;
      }
    }
  }

  return [output[0] / count, output[1] / count];
}

/**
 * Check if the given value is a valid ref.
 *
 * @param x - The value to check.
 * @returns True if the value is a valid ref, false otherwise.
 *
 * @example
 *
 * ```ts
 * const ref = React.createRef();
 *
 * const result0 = isValidRef(ref);
 * const result1 = isValidRef(null);
 * const result2 = isValidRef({});
 * const result3 = isValidRef({ current: null });
 *
 * console.log(result0)    // true
 * console.log(result1)   // false
 * console.log(result2)   // false
 * console.log(result3)   // true
 * ```
 */
export function isRef(x: unknown): x is React.RefObject<unknown> {
  return x !== null && typeof x === "object" && "current" in x;
}

/**
 * Check if the given value is a valid ref.
 *
 * @param x - The value to check.
 * @returns True if the value is a valid ref, false otherwise.
 *
 * @example
 *
 * ```ts
 * const ref = React.createRef();
 *
 * const result0 = isValidRef(ref);
 * const result1 = isValidRef(null);
 * const result2 = isValidRef({});
 * const result3 = isValidRef({ current: null });
 * const result4 = isValidRef({ current: {} });
 *
 * console.log(result0)    // true
 * console.log(result1)   // false
 * console.log(result2)   // false
 * console.log(result3)   // false
 * console.log(result4)   // true
 */
export function isValidRef(
  x: unknown
): x is React.RefObject<ReactEChartsCore> & { current: ReactEChartsCore } {
  if (isRef(x)) {
    return x.current !== null;
  }

  return false;
}

/**
 * Get the NPT color.
 *
 * The higher the NPT days percentage ratio, the reddish is the color.
 *
 * @param nptMapData - The NPT map data.
 *
 * @returns The NPT color.
 *
 * @example
 *
 * ```ts
 * const nptMapData = {
 *   ...
 *   npthours: 1,
 *   totalhours: 2,
 * };
 *
 * const result = nptDaysPercentageColor(nptMapData);
 *
 * console.log(result); // "rgb(255, 0, 0)"
 * ```
 */
export function nptDaysPercentageColor(nptMapData: NptMapData | number) {
  const percentage =
    typeof nptMapData === "number"
      ? nptMapData
      : calcNPTDaysPercentage(nptMapData);
  const r = Math.round(255 * percentage).toString();
  const g = Math.round(255 * (1 - percentage)).toString();
  return `rgb(${r}, ${g}, 0)`;
}
